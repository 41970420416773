import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import { SaleOrderFlash } from 'src/app/models/sale-order.model';
import { Lead } from 'src/app/models/crm.lead.model';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { StockPicking } from 'src/app/models/stock-picking';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { MailActivity } from '../models/mail.message';
import { User } from '../models/user.model';
import { Partner } from '../models/partner';
import { AccountMove } from '../models/account-move.model';
import { Project, Task } from '../models/project.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  modelTypes = [
    { key: 'sale.order', label: 'Vendite', selected: true },
    { key: 'res.partner', label: 'Contatti', selected: true },
    { key: 'crm.lead', label: 'Commesse', selected: true },
    { key: 'purchase.order', label: 'Acquisti', selected: true },
    { key: 'stock.picking', label: 'Trasferimenti', selected: true },
    { key: 'product.template', label: 'Prodotti', selected: true },
    { key: 'account.move', label: 'Fatture', selected: true },
    { key: 'project.project', label: 'Progetti', selected: true },
    { key: 'project.task', label: 'Task', selected: true }
  ];

  weekDays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
  months = [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
    'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
  ];
  calendarDays: Array<{ date: Date; otherMonth: boolean }> = [];
  currentDate = new Date();
  currentMonth: string = '';
  currentYear: number = 0;
  activities: MailActivity[] = [];
  filteredActivities: MailActivity[] = [];
  user: User;
  selectedActivity: MailActivity | null = null;
  loading = false;
  availableYears: number[] = [];
  
  // Model data for resolving references
  saleOrders: SaleOrderFlash[] = [];
  partners: Partner[] = [];
  leads: Lead[] = [];
  purchases: PurchaseOrder[] = [];
  pickings: StockPicking[] = [];
  productTemplates: ProductTemplate[] = [];
  allUsers: User[] = [];
  invoices: AccountMove [] = [];
  projects: Project[] = [];
  tasks: Task[] = [];

  activityTypes = [
    { id: 1, name: 'Email', selected: true },
    { id: 2, name: 'Chiamata', selected: true },
    { id: 3, name: 'Riunione', selected: true },
    { id: 4, name: 'Todo', selected: true }
  ];

  filters = {
    showOverdue: true,
    showToday: true,
    showPlanned: true
  };

  //list properties
  showList = true;
  searchTerm = '';
  sortField = 'date_deadline';
  sortDesc = false;


  constructor(private odooEm: OdooEntityManager) {// Close dropdown when clicking outside
    document.addEventListener('click', () => {
      if (this.selectedActivity) {
        this.selectedActivity = null;
      }
    });
  }

  async ngOnInit() {
    this.generateYearRange();
    await this.loadUserInfo();
    await this.loadUsers();
    await this.loadActivities();
    await this.fetchModelsData();
    this.generateCalendarDays();
    this.updateMonthDisplay();
    this.applyFilters();
  }

  // User and Data Loading Methods
  async loadUserInfo() {
    const result: any = await this.odooEm.odoorpcService.getSessionInfo();
    const userId = result.result.user_id[0];
    const users = await firstValueFrom(this.odooEm.search<User>(
      new User(), 
      [['id', '=', userId]]
    ));
    this.user = users[0];
  }

  async loadUsers() {
    if (this.allUsers.length === 0) {
      this.allUsers = await firstValueFrom(this.odooEm.search<User>(new User(), [], 60));
    }
  }

  findUser(name: string) {
    return this.allUsers.find(u => u.name === name);
  }

  async loadActivities() {
    try {
      this.loading = true;
      this.activities = await firstValueFrom(this.odooEm.search<MailActivity>(
        new MailActivity(),
        [['user_id', '=', this.user?.id], ['activity_type_id', '!=', 6]],
        100
      ));
      
      // Sort activities by deadline
      this.activities.sort((a, b) => 
        new Date(a.date_deadline).getTime() - new Date(b.date_deadline).getTime()
      );
    } catch (error) {
      console.error('Error loading activities:', error);
    } finally {
      this.loading = false;
    }
  }
  generateYearRange() {
    const currentYear = new Date().getFullYear();
    this.availableYears = Array.from(
      { length: 10 }, 
      (_, i) => currentYear - 2 + i
    );
  }
  
  previousYear() {
    this.currentYear--;
    this.currentDate.setFullYear(this.currentYear);
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }
  
  nextYear() {
    this.currentYear++;
    this.currentDate.setFullYear(this.currentYear);
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }
  
  selectYear() {
    this.currentDate.setFullYear(this.currentYear);
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }

  // Calendar Navigation and Generation
  generateCalendarDays() {
    this.calendarDays = [];
    const firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    
    // Add days from previous month
    const firstDayOfWeek = firstDay.getDay();
    for (let i = firstDayOfWeek - 1; i >= 0; i--) {
      const date = new Date(firstDay);
      date.setDate(date.getDate() - i - 1);
      this.calendarDays.push({ date, otherMonth: true });
    }

    // Add days of current month
    for (let date = new Date(firstDay); date <= lastDay; date.setDate(date.getDate() + 1)) {
      this.calendarDays.push({ date: new Date(date), otherMonth: false });
    }

    // Add days from next month
    const remainingDays = 42 - this.calendarDays.length; // 6 rows × 7 days
    const nextMonthStart = new Date(lastDay);
    nextMonthStart.setDate(lastDay.getDate() + 1);
    for (let i = 0; i < remainingDays; i++) {
      const date = new Date(nextMonthStart);
      date.setDate(date.getDate() + i);
      this.calendarDays.push({ date, otherMonth: true });
    }
  }

  updateMonthDisplay() {
    this.currentMonth = this.months[this.currentDate.getMonth()];
    this.currentYear = this.currentDate.getFullYear();
  }

  previousMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }

  selectMonth(monthIndex: number) {
    this.currentDate.setMonth(monthIndex);
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }

  goToToday() {
    this.currentDate = new Date();
    this.generateCalendarDays();
    this.updateMonthDisplay();
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  }

  // Activity Management
  getFilteredActivitiesForDay(date: Date): MailActivity[] {
    return this.filteredActivities.filter(activity => {
      const activityDate = new Date(activity.date_deadline);
      return activityDate.getDate() === date.getDate() &&
             activityDate.getMonth() === date.getMonth() &&
             activityDate.getFullYear() === date.getFullYear();
    });
  }


    applyFilters() {
    this.filteredActivities = this.activities.filter(activity => {
      // Filter by activity type
      const typeMatch = this.activityTypes.find(t => t.id === activity.activity_type_id.id)?.selected ?? true;

      // Filter by model type
      const modelMatch = this.modelTypes.find(m => m.key === activity.res_model)?.selected ?? true;

      // Filter by status
      const statusMatch = (
        (this.filters.showOverdue && activity.state === 'overdue') ||
        (this.filters.showToday && activity.state === 'today') ||
        (this.filters.showPlanned && activity.state === 'planned')
      );

      return typeMatch && modelMatch && statusMatch;
    });
  }

  showActivityDetails(activity: MailActivity) {
    this.selectedActivity = activity;
  }

  closeActivityDetails() {
    this.selectedActivity = null;
  }

  async openActivity(activity: MailActivity) {
    if (activity._ga_linkToObject) {
      window.open(activity._ga_linkToObject, '_blank');
    }
  }

  async completeActivity(activity: MailActivity) {
    if(confirm("Sei sicuro di voler completare l'attività?")) {
      this.loading = true;
      try {
        await this.odooEm.call2('mail.activity', 'action_feedback', [activity.id]);
        this.activities = this.activities.filter(a => a.id !== activity.id);
        this.applyFilters();
        this.closeActivityDetails();
      } catch (error) {
        console.error('Error completing activity:', error);
      } finally {
        this.loading = false;
      }
    }
  }

  // Activity Data Resolution
  async fetchModelsData() {
    // Only get IDs for selected models
    let saleOrderIds = this.modelTypes.find(m => m.key === 'sale.order')?.selected ? 
      this.activities.filter(a => a.res_model === 'sale.order').map(a => a.res_id) : [];
    
    let partnerIds = this.modelTypes.find(m => m.key === 'res.partner')?.selected ?
      this.activities.filter(a => a.res_model === 'res.partner').map(a => a.res_id) : [];
    
    let leadIds = this.modelTypes.find(m => m.key === 'crm.lead')?.selected ?
      this.activities.filter(a => a.res_model === 'crm.lead').map(a => a.res_id) : [];
    
    let purchaseIds = this.modelTypes.find(m => m.key === 'purchase.order')?.selected ?
      this.activities.filter(a => a.res_model === 'purchase.order').map(a => a.res_id) : [];
    
    let pickingIds = this.modelTypes.find(m => m.key === 'stock.picking')?.selected ?
      this.activities.filter(a => a.res_model === 'stock.picking').map(a => a.res_id) : [];
    
    let productIds = this.modelTypes.find(m => m.key === 'product.template')?.selected ?
      this.activities.filter(a => a.res_model === 'product.template').map(a => a.res_id) : [];

    let invoiceIds = this.modelTypes.find(m => m.key === 'account.move')?.selected ?
      this.activities.filter(a => a.res_model === 'account.move').map(a => a.res_id) : [];

    let projectIds = this.modelTypes.find(m => m.key === 'project.project')?.selected ?
      this.activities.filter(a => a.res_model === 'project.project').map(a => a.res_id) : [];

    let taskIds = this.modelTypes.find(m => m.key === 'project.task')?.selected ?
      this.activities.filter(a => a.res_model === 'project.task').map(a => a.res_id) : [];


   //remove duplicates
   saleOrderIds = [...new Set(saleOrderIds)];
   partnerIds = [...new Set(partnerIds)];
   leadIds = [...new Set(leadIds)];
   purchaseIds = [...new Set(purchaseIds)];
   pickingIds = [...new Set(pickingIds)];
   productIds = [...new Set(productIds)];
   invoiceIds = [...new Set(invoiceIds)];
   projectIds = [...new Set(projectIds)];
   taskIds = [...new Set(taskIds)];

   //from each array, remove already existing ids in the models arrays, this allows not to load new data if already present
   saleOrderIds = saleOrderIds.filter(id => !this.saleOrders.some(so => so.id === id));
   partnerIds = partnerIds.filter(id => !this.partners.some(p => p.id === id));    
   invoiceIds = invoiceIds.filter(id => !this.invoices.some(i => i.id === id));
   leadIds = leadIds.filter(id => !this.leads.some(l => l.id === id));
   purchaseIds = purchaseIds.filter(id => !this.purchases.some(p => p.id === id));
   pickingIds = pickingIds.filter(id => !this.pickings.some(p => p.id === id));
   productIds = productIds.filter(id => !this.productTemplates.some(p => p.id === id));
   projectIds = projectIds.filter(id => !this.productTemplates.some(p => p.id === id));
   taskIds = taskIds.filter(id => !this.productTemplates.some(p => p.id === id));

   //run the searches only if length is > 0
   let limit = 300;
   if (saleOrderIds.length > 0) {
   this.saleOrders = await firstValueFrom(this.odooEm.search<SaleOrderFlash>(new SaleOrderFlash(), [['id', 'in', saleOrderIds]], limit));    }
   if (partnerIds.length > 0) {
   this.partners = await firstValueFrom(this.odooEm.search<Partner>(new Partner(), [['id', 'in', partnerIds]], limit));    }
    let leadNames =[]
   if (invoiceIds.length > 0) {   
   this.invoices = await firstValueFrom(this.odooEm.search<AccountMove>(new AccountMove(), [['id', 'in', invoiceIds]], limit));    
   // store leadnames from invoices origins. this is needed to fetch lead data
   leadNames = this.invoices.map(i => i.invoice_origin) }
   //  search for leads with name in leadNames or id in leadIds
   if (leadIds.length > 0) { 
   this.leads = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['id', 'in', leadIds]], limit));  }
   if (leadNames.length > 0) {
   let ll = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['tracking_code', 'in', leadNames]], limit));
   this.leads.push(...ll);
   }  
   if (purchaseIds.length > 0) {
   this.purchases = await firstValueFrom(this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [['id', 'in', purchaseIds]], limit));    }
   if (pickingIds.length > 0) {
   this.pickings = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['id', 'in', pickingIds]], limit));    }
   if (productIds.length > 0) {
   this.productTemplates = await firstValueFrom(this.odooEm.search<ProductTemplate>(new ProductTemplate(), [['id', 'in', productIds]], limit));    }
   if (projectIds.length > 0) {
   this.projects = await firstValueFrom(this.odooEm.search<Project>(new Project(), [['id', 'in', projectIds]], limit));    }
   if (taskIds.length > 0) {
   this.tasks = await firstValueFrom(this.odooEm.search<Task>(new Task(), [['id', 'in', taskIds]], limit));    }
  
  
    this.loadActivitiesDescription();
  }


  async loadActivitiesDescription() {
    this.activities.forEach(a => {
      if (a.res_model === 'sale.order') {
        let saleOrder = this.saleOrders.find(so => so.id === a.res_id);
        a._ga_description = saleOrder.name + ' - ' + saleOrder.partner_id.name;
        if (saleOrder.opportunity_id.id) {
          a._ga_linkToObject = 'leads/'+saleOrder.opportunity_id.id+'/sale/'+saleOrder.id;
        }
        else {
          a._ga_linkToObject = '/immediate-sale/s/'+saleOrder.id;
        }
      } else if (a.res_model === 'res.partner') {
        let partner = this.partners.find(p => p.id === a.res_id);
        a._ga_description = partner.name;
        a._ga_linkToObject = '/contact/'+partner.id;
      } else if (a.res_model === 'crm.lead') {
        let lead = this.leads.find(l => l.id === a.res_id);
        // sometimes there's no tracking code......
        a._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
        a._ga_linkToObject = '/leads/'+lead.id;
      }
      else if (a.res_model === 'purchase.order') {
        let purchase = this.purchases.find(p => p.id === a.res_id);
        a._ga_description = purchase.name + ' - ' + purchase.partner_id.name;
        a._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+purchase.id+'&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form';
      }
      else if (a.res_model === 'stock.picking') {
        let picking = this.pickings.find(p => p.id === a.res_id);
        a._ga_description = picking.name + ' - ' + picking.partner_id.name;
        a._ga_linkToObject = '/pickings/internal/'+picking.id;
      }
      else if (a.res_model === 'product.template') {
        let product = this.productTemplates.find(p => p.id === a.res_id);
        a._ga_description = product.display_name;
        a._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+product.id+'&cids=1&menu_id=223&action=392&model=product.template&view_type=form'
      }
      else if (a.res_model === 'account.move') {
        let invoice = this.invoices.find(i => i.id === a.res_id);
        let lead = this.leads.find(l => l.tracking_code === invoice.invoice_origin);
        a._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
        a._ga_linkToObject = '/leads/'+lead.id;
      }
      else if (a.res_model === 'project.project') {
        let project = this.projects.find(p => p.id === a.res_id);
        a._ga_description = project.display_name;
        a._ga_linkToObject = 'https://o3.galimberti.eu/web#action=635&active_id='+project.id+'&model=project.task&view_type=kanban&cids=1&menu_id=422'
      }
      else if (a.res_model === 'project.task') {
        let task = this.tasks.find(t => t.id === a.res_id);
        a._ga_description = task.project_id.name + ': ' + task.stage_id.name;
        a._ga_linkToObject = 'https://o3.galimberti.eu/web#action=635&active_id='+task.project_id.id+'&model=project.task&view_type=kanban&cids=1&menu_id=422'
      }
      
    });
}

  toggleActivityDetails(event: MouseEvent, activity: MailActivity) {
    event.stopPropagation();
    if (this.selectedActivity?.id === activity.id) {
      this.selectedActivity = null;
    } else {
      this.selectedActivity = activity;
    }
  }

  getOutput(activity: MailActivity): string {
    let output = '';
    
    const modelNames = {
      'res.partner': 'Contatto |',
      'sale.order': 'Vendita |',
      'crm.lead': 'Commessa |',
      'purchase.order': 'Acquisto |',
      'stock.picking': 'Trasferimento |',
      'product.template': 'Prodotto |',
      'account.move': 'Fattura |',
      'project.project': 'Progetto |',
      'project.task': 'Task |'
    };

    output = modelNames[activity.res_model] || 'Contatta ML!';
    
    if (output !== 'Contatta ML!' && activity._ga_description) {
      output += ' ' + (activity._ga_description.length > 30 
        ? activity._ga_description.substring(0, 30) + '...'
        : activity._ga_description);
    }
    
    return output;
  }


  // List management methods
toggleList() {
  this.showList = !this.showList;
}

filterActivities() {
  if (!this.searchTerm) {
    this.applyFilters();
    return;
  }

  const searchLower = this.searchTerm.toLowerCase();
  this.filteredActivities = this.activities.filter(activity => {
    const matchesFilters = this.activityTypes.find(t => t.id === activity.activity_type_id.id)?.selected &&
                          this.modelTypes.find(m => m.key === activity.res_model)?.selected &&
                          ((this.filters.showOverdue && activity.state === 'overdue') ||
                           (this.filters.showToday && activity.state === 'today') ||
                           (this.filters.showPlanned && activity.state === 'planned'));

    const matchesSearch = activity.display_name.toLowerCase().includes(searchLower) ||
                         activity.create_uid.name.toLowerCase().includes(searchLower) ||
                         this.getActivityTypeLabel(activity.activity_type_id.id).toLowerCase().includes(searchLower);

    return matchesFilters && matchesSearch;
  });

  this.sortActivities(this.sortField);
}

sortActivities(field: string) {
  if (this.sortField === field) {
    this.sortDesc = !this.sortDesc;
  } else {
    this.sortField = field;
    this.sortDesc = false;
  }

  this.filteredActivities.sort((a, b) => {
    let valueA, valueB;

    switch (field) {
      case 'date_deadline':
        valueA = new Date(a.date_deadline).getTime();
        valueB = new Date(b.date_deadline).getTime();
        break;
      case 'create_uid.name':
        valueA = a.create_uid.name.toLowerCase();
        valueB = b.create_uid.name.toLowerCase();
        break;
      default:
        return 0;
    }

    if (valueA < valueB) return this.sortDesc ? 1 : -1;
    if (valueA > valueB) return this.sortDesc ? -1 : 1;
    return 0;
  });
}

navigateToActivityDate(activity: MailActivity) {
  const activityDate = new Date(activity.date_deadline);
  this.currentDate = new Date(activityDate);
  this.generateCalendarDays();
  this.updateMonthDisplay();
  
  // Scroll the calendar to ensure the date is visible
  setTimeout(() => {
    const dayElement = document.querySelector(`[data-date="${activity.date_deadline}"]`);
    if (dayElement) {
      dayElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });
}

getStatusLabel(state: string): string {
  switch (state) {
    case 'overdue': return 'In Ritardo';
    case 'today': return 'Oggi';
    case 'planned': return 'Pianificata';
    default: return state;
  }
}

getActivityTypeLabel(typeId: number): string {
  return this.activityTypes.find(t => t.id === typeId)?.name || 'Sconosciuto';
}
}