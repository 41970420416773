import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StockQuantPackage } from '../models/stock-quant-package';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { StockQuant } from '../models/stock-quant';
import { firstValueFrom } from 'rxjs';
import { Product } from '../models/product.model';
import { ODOO_IDS } from '../models/deal';
import { ProductPackaging } from '../models/product.packaging.model';
import Decimal from 'decimal.js';
import { StockMove } from '../models/stock-move';
import { StockMoveLine } from '../models/stock-move-line';
import { StockPicking } from '../models/stock-picking';
import { StockLocation } from '../models/stock-location';

// Extend StockQuant with selection properties
interface SelectableQuant extends StockQuant {
  _selected_qty: number;
  _parent_package_id: number;
}

@Component({
  selector: 'app-packs-editor',
  templateUrl: './packs-editor.component.html'
})
export class PacksEditorComponent implements OnInit {
  loading = false;
  scanning = false;
  scanningDestination = false;
  
  selectedPackages: StockQuantPackage[] = [];
  enrichedQuants: SelectableQuant[] = [];
  editingQuant: SelectableQuant | null = null;
  destinationPackage: StockQuantPackage | null = null;

  constructor(private odooEM: OdooEntityManager) {}

  ngOnInit() {}

  // Scanning Functions
  startScanning() {
    this.scanning = true;
  }

  startDestinationScanning() {
    this.scanningDestination = true;
  }

  async onScanPackage(barcode: string) {
    this.loading  = true;
    if (!barcode) return;
    this.loading = true;

    try {
      // Search for package
      const packages = await firstValueFrom(
        this.odooEM.search<StockQuantPackage>(
          new StockQuantPackage(),
          [['name', '=like', barcode + '%']]
        )
      );

      if (packages.length === 0) {
        alert('Pacco non trovato');
        return;
      }

      const pkg = packages[0];
      
      // Check if already scanned
      if (this.selectedPackages.find(p => p.id === pkg.id)) {
        alert('Pacco già scansionato');
        return;
      }

      // Load package quants and resolve product info
      await firstValueFrom(this.odooEM.resolve(pkg.quant_ids));
      await firstValueFrom(this.odooEM.resolveArrayOfSingle(
        new Product(),
        pkg.quant_ids.values,
        'product_id'
      ));

      // Load product packaging info for descriptive quantities
      const products = pkg.quant_ids.values.map(q => q.product_id.value);
      await firstValueFrom(
        this.odooEM.resolveArray(new ProductPackaging(), products, 'packaging_ids')
      );

      // Add selectable quants to our array
      const newQuants: SelectableQuant[] = pkg.quant_ids.values
        .filter(q => q.quantity > 0)
        .map(q => Object.assign(Object.create(q), {
          _selected_qty: 0,
          _parent_package_id: pkg.id
        }));
      
      this.enrichedQuants = [...this.enrichedQuants, ...newQuants];
      this.selectedPackages.push(pkg);
      this.logSelections();
      this.loading = false;

    } catch (error) {
      console.error('Error scanning package:', error);
      alert('Errore durante la scansione');
    } finally {
      this.loading = false;
      this.scanning = false;
    }
  }

  async onScanDestination(barcode: string) {
    if (!barcode) return;
    this.loading = true;

    try {
      let pkg = await this.searchOrCreatePackage(barcode);
      if (!pkg) return;

      this.destinationPackage = pkg;
      await this.moveSelectedItems();

    } catch (error) {
      console.error('Error setting destination:', error);
      alert('Errore durante l\'impostazione della destinazione');
    } finally {
      this.loading = false;
      this.scanningDestination = false;
    }
  }

  selectWholePackage(pkg: StockQuantPackage, selected: boolean) {
    const packageQuants = this.getQuantsForPackage(pkg);
    packageQuants.forEach(quant => {
      quant._selected_qty = selected ? quant.available_quantity : 0;
    });
    this.logSelections();
  }

// And this helper to check if whole package is selected
isWholePackageSelected(pkg: StockQuantPackage): boolean {
  const packageQuants = this.getQuantsForPackage(pkg);
  return packageQuants.length > 0 && packageQuants.every(q => q._selected_qty === q.quantity);
}

  // Quantity Management
  editQuantity(quant: SelectableQuant) {
    this.editingQuant = quant;
  }

  onQuantitySelected(quantity: number) {
    if (this.editingQuant) {
      if (quantity > this.editingQuant.available_quantity) {
        alert('Quantità selezionata maggiore della disponibilità');
        this.editingQuant._selected_qty = 0;
      } else {
        this.editingQuant._selected_qty = quantity;
      }
      this.editingQuant = null;
      this.logSelections();
    }
  }

onCheckQuant(quant: SelectableQuant) {
  if (quant._selected_qty > 0) {
    quant._selected_qty = 0;
  } else if (quant.available_quantity > 0) {
    quant._selected_qty = quant.available_quantity;
  } else {
    alert('Nessuna quantità disponibile');
  }
  this.logSelections();
}

  // Package Management
  removePackage(pkg: StockQuantPackage) {
    const index = this.selectedPackages.indexOf(pkg);
    if (index > -1) {
      this.selectedPackages.splice(index, 1);
      this.enrichedQuants = this.enrichedQuants.filter(q => q._parent_package_id !== pkg.id);
      this.logSelections();
    }
  }

  getQuantsForPackage(pkg: StockQuantPackage): SelectableQuant[] {
    return this.enrichedQuants.filter(q => q._parent_package_id === pkg.id);
  }

  // Moving Items
  private async searchOrCreatePackage(barcode: string): Promise<StockQuantPackage | null> {
    const packages = await firstValueFrom(
      this.odooEM.search<StockQuantPackage>(
        new StockQuantPackage(),
        [['name', '=like', barcode + '%']]
      )
    );

    if (packages.length > 0) {
      //solve locations 
      await firstValueFrom(this.odooEM.resolveArrayOfSingle(new StockLocation(), packages, 'location_id'));
      console.log(packages);
     
     
      if(confirm(' Trovato Pacco '+ packages[0].name + ' in ' + packages[0].location_id.value?.display_name + '. Vuoi continuare con questo?')) {
        return packages[0];
        } 
        else {
          return null;
        }
    }

    if (confirm('Pacco non trovato. Vuoi crearne uno nuovo?')) {
      return await firstValueFrom(
        this.odooEM.create<StockQuantPackage>(
          new StockQuantPackage(),
          { name: barcode }
        )
      );
    }

    return null;
  }

  isSomeQuantSelected(): boolean {
    if (this.enrichedQuants.length === 0) return false;
    return this.enrichedQuants.some(q => q._selected_qty
      && q._selected_qty > 0);
  }

  private async moveSelectedItems() {
    this.loading = true;

    if (!this.destinationPackage) return;
    
    const itemsToMove = this.enrichedQuants.filter(q => q._selected_qty > 0);
    
    if (itemsToMove.length === 0) {
      alert('Nessun articolo selezionato');
      return;
    }

    this.loading = true;

    try {
      // Create picking
      const firstQuant = itemsToMove[0];
      const pickingData = {
        picking_type_id: ODOO_IDS.picking_type_generic,
        origin: "Operazione sui pacchi",
        location_id: firstQuant.location_id.id,
        location_dest_id: this.destinationPackage.location_id.id,
      };

      const newPick = await firstValueFrom(
        this.odooEM.create<StockPicking>(new StockPicking(), pickingData)
      );

      // Create moves and move lines for each selected quant
      for (const quant of itemsToMove) {
        // Create move
        const moveData = {
          name: quant.product_id.value.display_name,
          location_id: quant.location_id.id,
          location_dest_id: this.destinationPackage.location_id.id,
          product_id: quant.product_id.id,
          product_uom_qty: quant._selected_qty,
          picking_id: newPick.id,
          product_uom: quant.product_id.value.uom_id.id,
        };

        const createdMove = await firstValueFrom(
          this.odooEM.create<StockMove>(new StockMove(), moveData)
        );

        // Create move line
        await firstValueFrom(
          this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
            product_id: quant.product_id.id,
            picking_id: newPick.id,
            qty_done: quant._selected_qty,
            package_id: quant.package_id.id,
            result_package_id: this.destinationPackage.id,
            location_id: quant.location_id.id,
            location_dest_id: this.destinationPackage.location_id.id,
            move_id: createdMove.id,
          })
        );
      }

      // Validate the picking
      await this.odooEM.call2(
        new StockPicking().ODOO_MODEL,
        "button_validate",
        [[newPick.id]]
      );

      // Reset selections
      this.enrichedQuants = [];
      this.selectedPackages = [];
      this.destinationPackage = null;

      this.loading = false;

      alert('Spostamento completato');

    } catch (error) {
      console.error('Error moving items:', error);
      alert('Errore durante lo spostamento');
    } finally {
      this.loading = false;
    }
  }

  // UI Helpers
  getSelectionStyle(quant: SelectableQuant): string {
    if (quant._selected_qty === 0) return 'text-muted'
    else 
    return 'bg-primary text-white';

  }

  getDescriptiveQuantity(quant: StockQuant, quantity: number): string {
    if (quantity === 0) return '';

    
    const product = quant.product_id.value;

    if (!product?.packaging_ids?.values?.length) {
      return '';
    }

    const packagings = product.packaging_ids.values
      .slice()
      .sort((a, b) => b.qty - a.qty)
      .filter(x => !x.name.includes('netti') && x.sales === true);

    let result = '';
    let remaining = new Decimal(quantity);

    packagings.forEach((pkg, index) => {
      if (remaining.toNumber() <= 0) return;

      const quotient = index === packagings.length - 1 
        ? remaining.div(pkg.qty)
        : remaining.divToInt(pkg.qty);

      remaining = remaining.minus(quotient.mul(pkg.qty));

      if (quotient.toNumber() > 0) {
        if (result.length > 0) {
          result += ' + ';
        }
        result += Number.parseFloat(quotient.toFixed(5)).toLocaleString('it-IT') + ' ' + pkg.name;
      }
    });

    return result;
  }






  // Logging
  private logSelections() {
    console.group('Current Selections');
    
    // Group enrichedQuants by package
    const selectedQuants = this.enrichedQuants.filter(q => q._selected_qty > 0);
    const groupedByPackage = this.groupByPackage(selectedQuants);
    
    Object.entries(groupedByPackage).forEach(([packageId, quants]) => {
      const pkg = this.selectedPackages.find(p => p.id === parseInt(packageId));
      if (pkg && quants.length > 0) {
        console.group(`Package: ${pkg.name}`);
        quants.forEach(q => {
          console.log(
            `${q.product_id.value.display_name}: ` +
            `${this.getDescriptiveQuantity(q, q._selected_qty)} ` +
            `(${q._selected_qty}/${q.quantity} ${q.product_id.value.uom_id.name})`
          );
        });
        console.groupEnd();
      }
    });
    
    console.groupEnd();
  }

  private groupByPackage(quants: SelectableQuant[]): { [key: string]: SelectableQuant[] } {
    return quants.reduce((groups, quant) => {
      const key = quant._parent_package_id.toString();
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(quant);
      return groups;
    }, {} as { [key: string]: SelectableQuant[] });
  }
}