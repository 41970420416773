<div class="card shadow-sm mb-4">
  <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
    <h5 class="card-title mb-0">Contratti e varianti</h5>
    <div>
      <button class="btn btn-link" title="Copia dati" (click)="copyContrattiToClipboard()"
        [disabled]="!contrattiInvoices.length">
        <i class="fa-solid fa-copy fa-lg"></i>
      </button>

      <button class="btn btn-link " title="Espandi" (click)="toggleContratti()" [disabled]="!contrattiInvoices.length">
        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
      </button>

      <button class="btn btn-link text-primary " type="button" (click)="createInvoice(O_ids.contratti_id)"
        title="Crea Nuovo Contratto" [disabled]="collapseContract === 'collapsed' && contrattiInvoices.length>0">
        <i class="fa-solid fa-plus fa-lg"></i>
      </button>
    </div>
  </div>

  <!-- show this only if contrattiInvoices.length>0 -->
  @if (contrattiInvoices && contrattiInvoices.length > 0 && collapseContract !="collapsed") {

  <div>
    <!-- Contratti e varianti section -->
    <div class="d-flex flex-column">

      <div class="table-responsive">
        <table class="table  table-hover align-middle mb-0">
          <thead>
            <tr>
              <td class="text-start"></td>
              <td class="text-start">Data conferma</td>
              <td class="text-start" style="width: 40%;">Descrizione</td>
              <td class="text-end">Imponibile</td>
              <td colspan="2" class="text-end">IVA</td>
              <td class="text-end">Totale</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let invoice of contrattiInvoices; let i = index">
              <!-- Invoice Row -->
              <tr class="bg-light">
                <td class="text-center">
                  <i class="fa-solid"
                    [ngClass]="{'fa-caret-down': invoice._expanded, 'fa-caret-right': !invoice._expanded}"
                    (click)="toggleInvoiceDetails(invoice)" style="cursor: pointer;"></i>
                </td>
                <td>
                  <input type="date" class="form-control form-control-sm border-0 bg-transparent"
                    [ngModel]="invoice.invoice_date | date:'yyyy-MM-dd'"
                    (ngModelChange)="updateInvoiceDate(invoice, $event)">
                </td>


                <td>
                  <div class="d-flex align-items-center "
                    [innerHTML]="invoice.narration || 'Aggiungi descrizione contratto...'" contenteditable="true"
                    (blur)="updateNarration(invoice, $event.target.innerHTML)"
                    style="min-height: 31px; max-height: 31px; overflow: hidden; outline: none;"
                    [ngClass]="{'text-muted': !invoice.narration}">
                  </div>
                </td>


                <!-- <td></td>
                  <td></td> -->
                <td class="text-end ">{{invoice.amount_untaxed | currency:'EUR'}}</td>
                <td> </td>
                <td class="text-end text-muted">{{invoice.amount_tax | currency:'EUR'}}</td>
                <td class="text-end text-muted">{{invoice.amount_total | currency:'EUR'}}</td>
                <td class="text-end">

                  <button class="btn btn-link p-0 me-3" (click)="addInvoiceLine(invoice)"
                    [disabled]="invoice.state === 'posted'"
                    [ngClass]="{'text-primary': invoice.state !== 'posted', 'text-muted': invoice.state === 'posted'}"
                    title="Aggiungi Riga">
                    <i class="fa-solid fa-plus"></i>
                  </button>
                  <button class="btn btn-link p-0 me-2" (click)="removeInvoice(invoice)" title="Rimuovi Fattura">
                    <i class="fa-solid fa-trash text-primary"></i>
                  </button>
                </td>
              </tr>
              <!-- Invoice Lines (expanded view) -->
              <ng-container *ngIf="invoice._expanded">
                <tr *ngFor="let line of invoice.invoice_line_ids.values" class="bg-white">
                  <td></td>
                  <td>
                    <select class="form-select form-select-sm bg-white border-0 text-muted"
                      [ngModel]="line.product_id?.id" (ngModelChange)="updateProduct(line, $event)">

                      <option [ngValue]="null">Seleziona Prodotto</option>
                      <option *ngFor="let product of contractProducts" [ngValue]="product.id">
                        {{product.name}}
                      </option>
                    </select>
                  </td>

                  <td>
                    <!-- name (etichetta) (remains editable) -->
                    <div class="d-flex align-items-center  " [innerHTML]="line.name || 'Aggiungi descrizione riga...'"
                      contenteditable="true" (blur)="updateName(line, $event.target.innerHTML)"
                      style="min-height: 31px; max-height: 31px; overflow: hidden; outline: none;">

                    </div>

                  </td>

                  <td class="text-end">
                    <div class="d-flex align-items-center justify-content-end">
                      <input-number [ngModel]="line.price_unit | number : '1.0-2':'en-EN'"
                        (ngModelChange)="updatePrice(line, $event)">
                      </input-number>
                      <span class="ms-1">€</span>
                    </div>
                  </td>
                  <!-- <td class="text-end">
                      <div class="d-flex align-items-center justify-content-end">
                        <input-number [ngModel]="line.discount | number : '1.0-2':'en-EN'"
                          (ngModelChange)="updateDiscount(line, $event)">
                        </input-number>
                        <span class="ms-1">%</span>
                      </div>
                    </td>
                    <td class="text-end text-muted">{{line.price_subtotal | currency:'EUR'}}</td> -->
                  <td colspan="2">
                    <select class="form-select form-select-sm bg-white border-0"
                      [ngModel]="(invoice?.invoice_line_ids.values?.length > 0 &&  line.tax_ids?.values?.length > 0) ? line.tax_ids?.values[0].id : []"
                      (ngModelChange)="updateTax(line, $event)">
                      <option [ngValue]="null">---</option>
                      <option *ngFor="let tax of taxes" [ngValue]="tax.id"> {{tax.name}}</option>
                    </select>
                  </td>
                  <!-- <td class="text-end text-muted">{{line._tax_amount | currency:'EUR'}}</td> -->

                  <td class="text-end text-muted">{{line.price_total | currency:'EUR'}}</td>
                  <td class="text-center">
                    <button class="btn btn-link p-0" (click)="removeLine(invoice, line)" title="Rimuovi Riga">
                      <i class="fa-solid fa-trash text-primary"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>

            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  }
</div>

<div class="card shadow-sm mb-4">
  <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
    <h5 class="card-title mb-0">Fatture e SAL</h5>
    <!-- progress bar with percentage (invoiced / contracts) -->
    <div class="d-flex justify-content-end align-items-center">
      <div class="progress" style="min-width: 100px;">
        <div class="progress-bar bg-success" role="progressbar" [style.width.%]="(totalSALPosted / totalContract) * 100"
          aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
          {{   (1 - (toInvoice / totalContract)) * 100 | number:'1.0-0'}}%
        </div>
      </div>


      <button class="btn btn-link" title="Copia dati" (click)="copySalToClipboard()" [disabled]="!salInvoices.length">
        <i class="fa-solid fa-copy fa-lg"></i>
      </button>

      <button class="btn btn-link " title="Espandi" (click)="toggleSal()" [disabled]="!salInvoices.length">
        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
      </button>

      <button class="btn btn-link text-primary " type="button" (click)="createInvoice(O_ids.sal_id)"
        title="Crea Nuova Fattura SAL" [disabled]="collapseSal === 'collapsed' && salInvoices.length>0">
        <i class="fa-solid fa-plus fa-lg"></i>
      </button>
    </div>

  </div>


  <!-- show this only if salinvoices.length>0 -->
  @if (salInvoices && salInvoices.length > 0 && collapseSal !="collapsed") {
  <div class="d-flex flex-column">
    <div class="table-responsive" id="aaa">
      <table class="table table-hover align-middle mb-0">
        <thead>
          <tr class="text-center">
            <td></td>
            <td class="text-start">Data</td>
            <td class="text-start">Descrizione</td>
            <td class="text-center">Pagamento</td>
            <td class="text-end">Imponibile</td>
            <!-- <th>Sconto</th>
              <th>Imponibile</th> -->
            <td class="text-end" colspan="2">IVA</td>
            <td class="text-end">Totale</td>
            <td class="text-end"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let invoice of salInvoices; let i = index">
            <!-- Invoice Row -->
            <tr class="bg-light">
              <td class="text-center">
                <i class="fa-solid"
                  [ngClass]="{'fa-caret-down': invoice._expanded, 'fa-caret-right': !invoice._expanded}"
                  (click)="toggleInvoiceDetails(invoice)" style="cursor: pointer;"></i>
              </td>
              <td>
                <!-- Date Input (Invoice Date) -->
                <input type="date" class="form-control form-control-sm  border-0 bg-transparent"
                  [ngModel]="invoice.invoice_date | date:'yyyy-MM-dd'"
                  (ngModelChange)="updateInvoiceDate(invoice, $event)" [disabled]="invoice.state === 'posted'">
              </td>
              <td>
                <!-- Narration (remains editable) -->
                <div class="d-flex align-items-center "
                  [innerHTML]="invoice.narration || 'Aggiungi descrizione fattura...'" contenteditable="true"
                  (blur)="updateNarration(invoice, $event.target.innerHTML)"
                  style="min-height: 31px; max-height: 31px; overflow: hidden; outline: none;"
                  [ngClass]="{'text-muted': !invoice.narration}">
                </div>

              </td>
              <td>
                <select class="form-select form-select-sm bg-light border-0"
                  [ngModel]="invoice.invoice_payment_term_id?.id" (ngModelChange)="updatePaymentTerm(invoice, $event)"
                  [disabled]="invoice.state === 'posted'">
                  <option [ngValue]="null">Seleziona Termini di Pagamento</option>
                  <option *ngFor="let term of paymentTerms" [ngValue]="term.id">
                    {{term.name}}
                  </option>
                </select>
              </td>
              <!-- <td></td>
                <td></td> -->
              <td class="text-end">{{invoice.amount_untaxed | currency:'EUR'}}</td>
              <td> </td>
              <td class="text-center text-muted">{{invoice.amount_tax | currency:'EUR'}}</td>
              <td class="text-end text-muted">{{invoice.amount_total | currency:'EUR'}}</td>
              <td class="text-center">
                <!-- Add Invoice Line Button -->
                <button class="btn btn-link p-0 me-2" (click)="addInvoiceLine(invoice)"
                  [disabled]="invoice.state === 'posted'"
                  [ngClass]="{'text-primary': invoice.state !== 'posted', 'text-muted': invoice.state === 'posted'}"
                  title="Aggiungi Riga">
                  <i class="fa-solid fa-plus"></i>
                </button>
                <button class="btn btn-sm me-1" (click)="toggleInvoiceActivity(invoice)"
                  [disabled]="invoice.state === 'posted'" [ngClass]="{
                      'btn-primary': invoice.amount_total !== 0 && !invoice.activity_ids?.ids?.length && invoice.state !== 'posted',
                      'btn-success': invoice.activity_ids?.ids?.length || invoice.state === 'posted',
                      'btn-muted': invoice.amount_total === 0 && !invoice.activity_ids?.ids?.length || invoice.state === 'posted'
              }">
                  <span
                    *ngIf="invoice.amount_total === 0 && !invoice.activity_ids?.ids?.length || invoice.state === 'posted'">
                    Da fatturare
                  </span>
                  <span
                    *ngIf="invoice.amount_total !== 0 && !invoice.activity_ids?.ids?.length && invoice.state !== 'posted'">
                    Da fatturare &nbsp;<i class="fa-solid fa-plus"></i>
                  </span>
                  <span *ngIf="invoice.activity_ids?.ids?.length && invoice.state !== 'posted'">
                    Non fatturare &nbsp;<i class="fa-solid fa-times"></i>
                  </span>

                </button>
                <button class="btn btn-sm me-1" (click)="completeInvoiceActivity(invoice)"
                  [disabled]="!invoice.activity_ids?.ids?.length || invoice.state === 'posted'" [ngClass]="{
                      'btn-muted': !invoice.activity_ids?.ids?.length && invoice.state !== 'posted',
                      'btn-primary': invoice.activity_ids?.ids?.length && invoice.state !== 'posted',
                      'btn-success': invoice.state === 'posted'
                    }">
                  Fatturato
                </button>
                <button class="btn btn-link p-0 me-2" (click)="removeInvoice(invoice)"
                  [disabled]="invoice.activity_ids?.ids?.length || invoice.state === 'posted'" [ngClass]="{
                    'text-primary': !(invoice.activity_ids?.ids?.length || invoice.state === 'posted'),
                    'text-muted': invoice.activity_ids?.ids?.length || invoice.state === 'posted'
                  }" title="Rimuovi Fattura">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>

            <!-- Invoice Lines (expanded view) -->
            <ng-container *ngIf="invoice._expanded">
              <tr *ngFor="let line of invoice.invoice_line_ids.values" class="bg-white">
                <td></td>
                <td>
                  <select class="form-select form-select-sm bg-white border-0 text-muted"
                    [ngModel]="line.product_id?.id" (ngModelChange)="updateProduct(line, $event)"
                    [disabled]="invoice.state === 'posted'">
                    <option [ngValue]="null">Seleziona Prodotto</option>
                    <option *ngFor="let product of invoicesProducts" [ngValue]="product.id">
                      {{product.name}}
                    </option>
                  </select>
                </td>

                <td clolspan="3">
                  <!-- name (etichetta) (remains editable) -->
                  <div class="d-flex align-items-center  " [innerHTML]="line.name || 'Aggiungi descrizione riga...'"
                    contenteditable="true" (blur)="updateName(line, $event.target.innerHTML)"
                    style="min-height: 31px; max-height: 31px; overflow: hidden; outline: none;">

                  </div>

                </td>
                <td> </td>

                <td class="text-end">
                  <!-- Price Input -->
                  <div class="d-flex align-items-center justify-content-end">
                    <input-number [ngModel]="line.price_unit | number : '1.0-2':'en-EN'"
                      (ngModelChange)="updatePrice(line, $event)" [disabled]="invoice.state === 'posted'">
                    </input-number>
                    <span class="ms-1">€</span>
                  </div>

                </td>
                <!-- <td class="text-end">
                    <div class="d-flex align-items-center justify-content-end">
                      <input-number [ngModel]="line.discount | number : '1.0-2':'en-EN'"
                        (ngModelChange)="updateDiscount(line, $event)">
                      </input-number>
                      <span class="ms-1">%</span>
                    </div>
                  </td>
                  <td class="text-end text-muted">{{line.price_subtotal | currency:'EUR'}}</td> -->
                <td colspan="2">
                  <!-- Tax Select -->
                  <select class="form-select form-select-sm bg-white border-0"
                    [ngModel]="(invoice?.invoice_line_ids.values?.length > 0 && line.tax_ids?.values?.length > 0) ? line.tax_ids?.values[0].id : []"
                    (ngModelChange)="updateTax(line, $event)" [disabled]="invoice.state === 'posted'">
                    <option [ngValue]="null">---</option>
                    <option *ngFor="let tax of taxes" [ngValue]="tax.id">{{tax.name}}</option>
                  </select>
                </td>
                <!-- <td class="text-end text-muted">{{line._tax_amount | currency:'EUR'}}</td> -->
                <td class="text-end text-muted">{{line.price_total | currency:'EUR'}}</td>
                <td class="text-end">
                  <!-- Remove Line Button -->
                  <button class="btn btn-link p-0" (click)="removeLine(invoice, line)" title="Rimuovi Riga"
                    [disabled]="invoice.state === 'posted'"
                    [ngClass]="{'text-primary': invoice.state !== 'posted', 'text-muted': invoice.state === 'posted'}">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  }

  <!-- show only if either contratti or sal length >0 -->
  @if (allInvoices && allInvoices.length > 0 && collapseSal !="collapsed"){
  <div class="table-responsive mt-3">
    <table class="table table-hover  mb-0 px-5">
      <tfoot>
        <!-- Contratti e varianti -->
        <tr>
          <th class="ps-3" colspan="3">Contratti e varianti</th>
          <td class="text-end pe-3" colspan="6">{{ totalContract | currency: 'EUR' }}</td>
        </tr>

        <!-- Fatturazione prevista (conclusa) -->
        <tr>
          <th class="ps-3" colspan="3">Fatture pianificate (da pianificare)</th>
          <td class="text-end pe-3" colspan="6">{{ totalSAL | currency: 'EUR' }} ({{ toAdd | currency: 'EUR' }})</td>
        </tr>

        <!-- Da fatturare -->
        <tr>
          <th class="ps-3 " colspan="3">Fatturato (da fatturare)</th>
          <td class="text-end pe-3" colspan="6">{{ totalSALPosted | currency: 'EUR' }} ({{ toInvoice | currency: 'EUR'
            }})</td>
        </tr>
        <tr>
          <th class="ps-3 text-muted" colspan="3"> Ritenute a garanzia arretrate:</th>
          <td class="text-end pe-3" colspan="6">{{ garanziaBehind | currency: 'EUR' }} </td>
        </tr>
      </tfoot>
    </table>
  </div>
  }
</div>