import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { StockMove } from 'src/app/models/stock-move';
import { ODOO_IDS } from 'src/app/models/deal';

@Component({
  selector: 'app-transfers-table',
  templateUrl: './transfers-table.component.html'
})
export class TransfersTableComponent {
  @Input() saleOrder: SaleOrder;
  @Input() orderLine: SaleOrderLine;
  @Output() loading = new EventEmitter<boolean>();

  // Location priority mapping
  private readonly LOCATION_PRIORITY = {
    4: 1,   // vendor
    8: 2,   // giacenza
    43: 3,  // H3
    48: 4,  // cap case
    41: 5,  // spedizioni
    301: 6, // carico trasporto
    5: 7    // customer
  };

    // State priority mapping
    private readonly STATE_PRIORITY = {
      'done': 1,
      'assigned': 2,
      'confirmed': 3,
      'partially_available': 4,
      'waiting': 5
    };

    // Get location priority (lower number = higher priority)
  private getLocationPriority(locationId: number): number {
    return this.LOCATION_PRIORITY[locationId] || 999; // Unknown locations go last
  }

  // Get state priority (lower number = higher priority)
  private getStatePriority(state: string): number {
    return this.STATE_PRIORITY[state] || 999; // Unknown states go last
  }

  // Get filtered moves for the current line
  getMoves(): StockMove[] {
    if (!this.saleOrder.procurement_group_id?.value?.stock_move_ids?.values) {
      return [];
    }

    console.log('table transfers with data ', this.saleOrder, this.orderLine);

    const filteredMoves = this.saleOrder.procurement_group_id.value.stock_move_ids.values.filter(move => 
      move.product_id.id === this.orderLine.product_id.id &&
      move.state !== 'cancel' &&
      move.state !== 'draft' &&
      (move.product_uom_qty > 0 || move.quantity_done > 0)
    );
  
     // Sort by location priority first, then by state priority
     return filteredMoves.sort((a, b) => {
      // Compare location priorities
      const locationComparison = this.getLocationPriority(a.location_id.id) - 
                                this.getLocationPriority(b.location_id.id);
      
      // If locations are different, return location comparison
      if (locationComparison !== 0) {
        return locationComparison;
      }
      
      // If locations are the same, sort by state priority
      return this.getStatePriority(a.state) - this.getStatePriority(b.state);
    });
  
  }

  // Helper to get state display text
  getStateDisplay(state: string): string {
    const states = {
      'waiting': 'In attesa',
      'partially_available': 'Da fare',
      'confirmed': 'Da fare',
      'assigned': 'Da fare',
      'done': 'Completato',
    };
    return states[state] || state;
  }

  // Helper to get state text color class
  getStateClass(state: string): string {
    const classes = {
      'waiting': 'text-warning',
      'partially_available': 'text-primary',
      'confirmed': 'text-primary',
      'assigned': 'text-primary',
      'done': 'text-success',
    };
    return classes[state] || '';
  }

  // Format location name by removing 'LOM/' prefix
  formatLocation(location: string): string {
    return location?.replace('LOM/', '') || '';
  }

  // Handle button click to prevent dropdown close
  onButtonClick(event: Event): void {
    event.stopPropagation();
  }

  onMoveClick(move: StockMove) {
    console.log('move clicked', move);
    let id = move.picking_id.id;
    window.open(`https://o3.galimberti.eu/web#id=${id}&cids=1&menu_id=178&action=375&active_id=4206&model=stock.picking&view_type=form`, '_blank');

  }

  getQuantity(move: StockMove): number {
    if (move.state === 'done') {
      return move.quantity_done;
    }
    else {
      return move.product_uom_qty;
    }
  }
}