
@if (QuantsToShow.length > 0) {

<div class="d-flex justify-content-center">
    @if (from === 'product'){

    <div class="dropdown w-100">

        <button type="button" class="btn btn-link text-dark dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{getNumberOfPacks()}} {{getNumberOfPacks() === 1 ? 'Collo' : 'Colli'}}
        </button>

        <div class="dropdown-menu p-2" [ngClass]="{'expanded': attachments}">
            <!-- Package List with Toggleable Content -->
            @for (quant of QuantsToShow; track quant.id) {
            <div class="mb-1">
                <!-- Package Header -->
                <button class="btn btn-link text-decoration-none w-100 text-start " (click)="onClickPack(quant.package_id.value, $event)"
                    data-bs-toggle="collapse" [attr.data-bs-target]="'#pack-content-' + quant.package_id.id">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>{{quant.package_id.name}} - {{quant._productQuantity | number : '1.0-3'}} {{productWithQuants.uom_id.name}} </span>
                        <div>
                            @if (quant.package_id.value?.message_attachment_count > 0) {
                            <i class="fa fa-camera mx-1"></i>{{quant.package_id.value?.message_attachment_count}}
                            }
                            @if (quant.package_id.value?.message_ids?.ids?.length > 0) {
                            <i class="fa fa-message mx-1"></i>{{quant.package_id.value?.message_ids.ids.length}}
                            }
                        </div>
                    </div>
                </button>

                <!-- Collapsible Content -->
                <div class="collapse" [id]="'pack-content-' + quant.package_id.id">
                    <!-- Photos Section -->
                    @if (quant.package_id.value?._attachments?.length > 0) {
                    <div class="row g-2 mb-3">
                        @for (photo of quant.package_id.value?._attachments; track photo.id) {
                        <div class="col-6">
                            <div class="position-relative">
                                <img [src]="'https://o3.galimberti.eu/web/image/' + photo.id"
                                    class="img-thumbnail w-100" style="height: 200px; object-fit: cover;"
                                    [alt]="photo.name"
                                  >
                                <a [href]="'https://o3.galimberti.eu/web/image/' + photo.id" target="_blank"
                                    class="btn btn-sm btn-outline-primary position-absolute bottom-0 start-50 translate-middle-x mb-2">
                                    <i class="fa fa-external-link me-1"></i>
                                    Apri
                                </a>
                            </div>
                        </div>
                        }
                    </div>
                    }

                    <!-- Messages Section -->
                    @if (quant.package_id.value?.message_ids?.values?.length > 0) {
                    <div class="messages-section">
                        @for (message of quant.package_id.value?.message_ids.values; track message.id) {
                        <div class="message-card mb-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <div class="d-flex justify-content-between align-items-start mb-1">
                                        <div>
                                            <h6 class="mb-0 text-primary">{{message.author_id.name}}</h6>
                                            <small class="text-muted">{{message.date | date:'dd/MM/yyyy HH:mm'}}</small>
                                        </div>
                                    </div>
                                    <div class="message-body small" [innerHTML]="message.body"></div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
            }
        </div>

    </div>
    }
  <!-- if coming from package, packages is an array of a single value so i use package[0] -->
    @if (singleQuant && from === 'package') {
        <div class="dropdown"
             (click)="$event.stopPropagation()" 
             (mousedown)="$event.stopPropagation()">
            <!-- Cdropdown toggle -->
            <button class="btn btn-link text-decoration-none w-100 text-start" 
                    (click)="onClickPack(QuantsToShow[0].package_id.value, $event)"
                    data-bs-toggle="dropdown" 
                    aria-expanded="false">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        @if (QuantsToShow[0].package_id.value?.message_attachment_count > 0) {
                            <i class="fa fa-camera mx-1"></i>{{QuantsToShow[0].message_attachment_count}}
                        }
                        @if (QuantsToShow[0].package_id.value?.message_ids?.ids?.length > 0) {
                            <i class="fa fa-message mx-1"></i>{{QuantsToShow[0].package_id.value?.message_ids.ids.length}}
                        }
                    </div>
                </div>
            </button>
    
            <!-- photos -->
            <div class="dropdown-menu p-2" 
                 [ngClass]="{'expanded': attachments, 'show': attachments}"
                 (click)="$event.stopPropagation()">
            @if (QuantsToShow[0].package_id.value?._attachments?.length > 0) {
            <div class="row g-2">
                @for (photo of QuantsToShow[0].package_id.value?._attachments; track photo.id) {
                <div class="col-6">
                    <div class="position-relative">
                        <img [src]="'https://o3.galimberti.eu/web/image/' + photo.id" class="img-thumbnail w-100"
                            style="height: 200px; object-fit: cover;" [alt]="photo.name">
                        <a [href]="'https://o3.galimberti.eu/web/image/' + photo.id" target="_blank"
                            class="btn btn-sm btn-outline-primary position-absolute bottom-0 start-50 translate-middle-x mb-2">
                            <i class="fa fa-external-link me-1"></i>
                            Apri
                        </a>
                    </div>
                </div>
                }
            </div>
            }

            <!-- messages -->
            @if (messages){
            <div class="messages-section">
                @for (message of QuantsToShow[0].package_id.value?.message_ids.values; track message.id) {
                <div class="message-card mb-2">
                    <div class="card">
                        <div class="card-body p-2">
                            <div class="d-flex justify-content-between align-items-start mb-1">
                                <div>
                                    <h6 class="mb-0 text-primary">{{message.author_id.name}}</h6>
                                    <small class="text-muted">{{message.date | date:'dd/MM/yyyy HH:mm'}}</small>
                                </div>
                            </div>
                            <div class="message-body small" [innerHTML]="message.body"></div>
                        </div>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </div>
    }

</div>
}