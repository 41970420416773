import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  socket: any;

  quoteEnabled: boolean = false;
  keySequence: string = '';

  constructor(private router: Router, public odooEm: OdooEntityManager) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keySequence += event.key;
    
    // Keep only last 4 characters
    if (this.keySequence.length > 4) {
      this.keySequence = this.keySequence.slice(-4);
    }

    if (this.keySequence === '7331') {
      this.quoteEnabled = !this.quoteEnabled;
      this.keySequence = ''; // Reset after match
    }
  }

  ngOnInit() {
    

  }

}
