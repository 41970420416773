<button 
  class="btn btn-link w-100" 
  type="button" 
  data-bs-toggle="collapse" 
  [attr.data-bs-target]="'#transfers-' + orderLine.id"
  [attr.aria-controls]="'transfers-' + orderLine.id"
  (click)="onButtonClick($event)">
  Vedi trasferimenti
</button>

<div class="collapse" [id]="'transfers-' + orderLine.id">
  <div class="table-responsive">
    <table class="table table-sm table-hover mb-0">
      <thead>
        <tr>
          <th class="text-center">Da</th>
          <th class="text-center">A</th>
          <th class="text-center">Qtà</th>
          <th class="text-center">Stato</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let move of getMoves()" 
        (click)="onMoveClick(move)"
        style="cursor: pointer;">
          <td class="text-center">{{formatLocation(move.location_id?.name)}}</td>
          <td class="text-center">{{formatLocation(move.location_dest_id?.name)}}</td>
          <td class="text-center">{{getQuantity(move) | number:'1.0-2':'it-IT'}}</td>
          <td class="text-center" [ngClass]="getStateClass(move.state)">{{getStateDisplay(move.state)}}</td>
        </tr>
        <tr *ngIf="getMoves().length === 0">
          <td colspan="4" class="text-center text-muted">
            Nessun trasferimento trovato
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>