import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { Partner } from './partner';
import { User } from './user.model';

export class ProjectTag extends OdooModel implements OdooSerializableInterface<ProjectTag> {
    public readonly ODOO_MODEL = 'project.tags';

    name: string = '';
    color: number;
    project_ids: OdooMultiRelationship<Project> = new OdooMultiRelationship<Project>(Project);
    task_ids: OdooMultiRelationship<Task> = new OdooMultiRelationship<Task>(Task);

    constructor(id?: number) {
        super(id);
    }

    create(): ProjectTag {
        return new ProjectTag();
    }
}

export class Project extends OdooModel implements OdooSerializableInterface<Project> {
    public readonly ODOO_MODEL = 'project.project';

    name: string = '';
    display_name: string = '';
    active: boolean = true;
    sequence: number = 10;
    partner_id: OdooRelationship<Partner> = new OdooRelationship<Partner>();
    description: string = '';
    privacy_visibility: string = 'portal';
    task_ids: OdooMultiRelationship<Task> = new OdooMultiRelationship<Task>(Task);
    task_count: number = 0;
    task_count_with_subtasks: number = 0;
    color: number = 0;
    user_id: OdooRelationship<User> = new OdooRelationship<User>();
    alias_enabled: boolean;
    rating_active: boolean;
    rating_status: string = '';
    date_start: string = '';
    date: string = '';
    tag_ids: OdooMultiRelationship<ProjectTag> = new OdooMultiRelationship<ProjectTag>(ProjectTag);


    constructor(id?: number) {
        super(id);
    }

    create(): Project {
        return new Project();
    }
}

export class Task extends OdooModel implements OdooSerializableInterface<Task> {
    public readonly ODOO_MODEL = 'project.task';

    active: boolean = true;
    name: string = '';
    description: string     = '';
    priority: string = '0';
    sequence: number = 10;
    stage_id: OdooRelationship<ProjectStage> = new OdooRelationship<ProjectStage>();
    tag_ids: OdooMultiRelationship<ProjectTag> = new OdooMultiRelationship<ProjectTag>(ProjectTag);
    kanban_state: string = 'normal';
    create_date: string = '';
    write_date: string = '';
    date_end: string = '';
    date_assign: string   = '';
    date_deadline: string = '';
    project_id: OdooRelationship<Project> = new OdooRelationship<Project>();
    planned_hours: number;
    user_ids: OdooMultiRelationship<User> = new OdooMultiRelationship<User>(User);
    partner_id: OdooRelationship<Partner> = new OdooRelationship<Partner>();
    email_from: string = '';
    parent_id: OdooRelationship<Task> = new OdooRelationship<Task>();
    child_ids: OdooMultiRelationship<Task> = new OdooMultiRelationship<Task>(Task);
    subtask_count: number = 0;
    is_closed: boolean = false;
    origin: string = '';

    constructor(id?: number) {
        super(id);
    }

    create(): Task {
        return new Task();
    }
}


export class ProjectStage extends OdooModel implements OdooSerializableInterface<ProjectStage> {
    public readonly ODOO_MODEL = 'project.task.type';

    active: boolean = true;
    name: string        = '';
    display_name: string = '';
    description: string = '';
    project_ids: OdooMultiRelationship<Project> = new OdooMultiRelationship<Project>(Project);
    

    constructor(id?: number) {
        super(id);
    }

    create(): ProjectStage {
        return new ProjectStage();
    }
}