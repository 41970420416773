<app-navbar [loading]="loading" backroute="../..">
  <div class="d-flex align-items-center justify-content-between w-100 me-3">
    <!-- Brand section - left aligned -->
    <a class="navbar-brand text-nowrap">
      <span>
        {{opportunity ? opportunity.tracking_code : relatedSales[0]?.partner_id.name}}
      </span>
      <span *ngIf="part">
        - {{part?.name}}
      </span>
      <span *ngIf="opportunity && relatedSales.length > 0">
        - {{relatedSales[0]?.partner_id.name}}
      </span>
    </a>

    <!-- All tools groups - right aligned -->
    <div class="d-flex align-items-center gap-4">
      <!-- Action tools group -->
      <div class="d-flex align-items-center border-end pe-4">
        <span class="text-white small">Aggiungi:</span>
 
        <div class="d-flex" id="toolList" cdkDropList [cdkDropListConnectedTo]="saleIds">
          <button class="btn btn-light ms-1" title="Mostra inventario" (click)="toggleInventory()">
            <i class="fa-solid fa-layer-plus" [class.text-primary]="showInventory"></i>
          </button>

          <button class="btn btn-light ms-1" title="Aggiungi nota descrittiva" 
                  cdkDrag [cdkDragData]="getNewNote()"
                  (click)="onAddNotes(getNewNote())">
            <i class="fa-regular fa-file-pen"></i>
          </button>

          <button class="btn btn-light ms-1" title="Aggiungi sezione" 
                  cdkDrag [cdkDragData]="getNewSection()"
                  (click)="onAddNotes(getNewSection())">
            <i class="fa-sharp fa-regular fa-file-dashed-line"></i>
          </button>
        </div>

        <button class="btn btn-primary text-white ms-3" 
                title="Crea nuovo ordine in questa sotto-commessa" 
                type="button"
                (click)="createSaleFromRows()">
          <i class="fa-solid fa-plus"></i>
         Ordine
        </button>
        
      </div>

      <!-- Filters group -->
      <div class="d-flex align-items-center me-3">
        <span class="text-white small">Mostra:</span>
        <button class="btn btn-light ms-1" 
                  [class.text-primary]="showDeliveries"
                  (click)="toggleDeliveries()"
                  title="Consegne">
            <i class="fa-solid fa-truck"></i>
  
          </button>
                  <button class="btn btn-light ms-1" 
                  [class.text-primary]="showCosts"
                  (click)="flagCosts()"
                  title="Costi">
                  <i class="fa-regular fa-circle-euro"></i>          </button>

      </div>
    </div>
  </div>
</app-navbar>


<!-- Inventory component -->
@if (loadedInventory ){
<app-order-inventory #orderInventory *ngIf="relatedSales.length > 0" [sales]="relatedSales" [saleIds]="saleIds"
  [saleEditor]="thisIs" [class.d-none]="!showInventory" (loading)="loading = $event"
  (toggleInventory)="showInventory = !showInventory" class="flex-grow-1">
</app-order-inventory>
}

<!----- TABLE ------>
<div class="table-container h-100 overflow-x-scroll overflow-y-scroll" *ngIf="relatedSales.length > 0">
  <table id="order" class="table table-bordered tableFixHead table-hover mb-5">
    <thead class="sticky-top">
      <tr valign="middle">
        <th class="bg-light"></th>
        <th class="bg-light ps-3">ID</th>
        <th class="bg-light">
          <div class="d-flex align-items-center">
            <!-- <i class="fa fa-filter text-muted me-2" (click)="filterColumn['name'] = (filterColumn['name'] != null) ? null: ''"></i> -->
            <span *ngIf="filterColumn['name'] == null">Descrizione</span>
            <input *ngIf="filterColumn['name'] != null" [(ngModel)]="filterColumn['name']" autofocus>
          </div>
        </th>

        <th class="bg-light">
          Percorso
        </th>

        <th class="bg-light text-end " colspan="1">Qtà</th>
        <th class="bg-light" colspan="2">Descr</th>
        <!-- <th class="bg-light text-end" colspan="1"></th> -->
        <th class="bg-light" *ngIf="showDeliveries">Stato
          <span *ngIf="showDeliveries" class="dropdown">
            <button class="btn btn-light ms-1" 
                    title="Legenda icone"
                    data-bs-toggle="dropdown">
              <i class="fa-solid fa-circle-info"></i>
            </button>
          <div  class="dropdown-menu dropdown-menu-end p-3" style="min-width: 300px; z-index: 3000000">
            <h6 class="dropdown-header">Legenda consegne</h6>
            
            <!-- Acquisti -->
            <div class="mb-3">
              <div class="text-muted mb-2">
                <i class="fa fa-cart-circle-check me-2"></i>
                La riga ha un acquisto collegato:
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-cart-circle-check text-success me-2"></i>
                Acquisto arrivato
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-cart-circle-check text-warning me-2"></i>
                Acquisto parzialmente arrivato
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-cart-circle-check text-danger me-2"></i>
                Acquisto non arrivato
              </div>
            </div>
           
            <div class="dropdown-divider"></div>
           
            <!-- Magazzino -->
            <div class="mb-3">
              <div class="text-muted mb-2">
                <i class="fa fa-warehouse me-2"></i>
                <i class="fa fa-lock me-2"></i>
                Disponibilità e prenotazioni
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-warehouse text-success me-2"></i>
                Non riservato, disponibile
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-warehouse text-warning me-2"></i>
                Non riservato, disponibile parzialmente
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-lock text-success me-2"></i>
                Riservato completamente
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-lock text-warning me-2"></i>
                Riservato parzialmente
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-warehouse text-danger me-2"></i>
                Non disponibile
              </div>
            </div>
           
            <div class="dropdown-divider"></div>
           
            <!-- Consegne -->
            <div class="mb-3">
              <div class="text-muted mb-2">
                <i class="fa fa-truck-fast me-2"></i>
                Stato consegna
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-truck-fast text-success me-2"></i>
                Consegnato completamente
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-truck-fast text-warning me-2"></i>
                Parzialmente consegnato o restituito
              </div>
              <div class="ms-3 mb-1">
                <i class="fa fa-truck-fast text-danger me-2"></i>
                Non consegnato
              </div>
            </div>
           </div>
          </span>
        </th>
        <th class="bg-light text-end" *ngIf="showDeliveries">Consegnati</th>
        <th class="bg-light text-end">Larg</th>
        <th class="bg-light text-end">Spes</th>
        <th class="bg-light text-end">Lung</th>
        <th class="bg-light text-end">€ / UdM</th>
        <th class="bg-light text-end">Sconto %</th>
        <th class="bg-light text-end">Totale €</th>
        <th *ngIf="showCosts" class="bg-light text-end text-muted fst-italic">Costo €</th>
      </tr>
    </thead>

    <!-- ORDER HEADER -->
    <ng-container *ngFor="let s of relatedSales ; trackBy: identify">
      <thead class="border-info bg-info">
        <!-- FIRST ROW: NAME, DELIVERY AND TOOLS -->
        <tr class="mt-2 border-top border-0 border-bottom-0 ">
          <td colspan="16" class="ps-2 mt-2 border-0 border-bottom-0" style="border-top: 1px solid #333 !important">
            <div class="d-flex align-items-center">

              <input *ngIf="s._open" type="checkbox" class="me-2" (ngModelChange)="selectOrder($event, s)"
                [(ngModel)]="s._checked" name="checksale">

              <b (click)="toggleSale(s)">
                <i *ngIf="!s._open" class="fa-solid fa-caret-right"></i>
                <i *ngIf="s._open" class="fa-solid fa-caret-down"></i>
                {{s.name}}
              </b>

              <div *ngIf="s._delivery_state">
                <span [title]="getDeliveryBadge(s._delivery_state).title" class="badge"
                  [ngClass]="getDeliveryBadge(s._delivery_state).class">
                  {{s._delivery_state}}&nbsp;
                </span>
              </div>

              <input [ngModel]="s.ga_title" (ngModelChange)="updateOrder(s,$event, 'ga_title')"
                [ngModelOptions]="{'updateOn':'blur'}" type="text" placeholder="Nome"
                class="form-control ms-1 bg-transparent border-0 me-3">

              <ng-container *ngIf="(!opportunity_code || part?.name !== 'Preventivi')">

              <app-purchase-request 
              [saleOrder]="s" 
              [lead]="opportunity"
              [part]="part"
              ></app-purchase-request>

              <app-activity-scheduler
                *ngIf="(s.state == 'sale' && s._delivery_state && s.delivery_status != 'full' )" 
                [sale]="s"
                (loading)="loading = $event">
              </app-activity-scheduler>

              <a [routerLink]="['/pickings']" 
              [queryParams]="{search: s.name}" 
              target="_blank"
              class="btn btn-sm text-white me-2"
              [ngClass]="{'bg-primary': hasOpenMoves(s), 'bg-muted': !hasOpenMoves(s)}">
                <i class="fa fa-truck"></i>
                Vedi Trasferimenti
              </a>

              </ng-container>


              <!-- TOOLS -->

              <div class="dropdown">
                <button class="btn btn-link text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa fa-bars"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li *ngIf="s.state == 'draft'" class="dropdown-item">
                    <button class="btn btn-primary text-white" type="button" (click)="confirm(s)">
                      Conferma
                    </button>
                  </li>

                  <li class="dropdown-item" *ngIf="s.state == 'sale'">
                    <button class="btn btn-primary text-white" type="button" (click)="cancel(s)">
                      Annulla
                    </button>
                  </li>

                  <li *ngIf="s.state == 'cancel'">
                    <button class="btn btn-primary text-white ms-3" type="button" (click)="draft(s)">
                      Imposta a bozza
                    </button>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" target="_blank" (click)="print(s)">Stampa</a></li>

                  <!-- <li><a *ngIf="!s._resolving && !showCosts" class="dropdown-item" target="_blank"
                      (click)="flagCosts()">Mostra costi</a></li> -->
                  <li><a *ngIf="showCosts" class="dropdown-item" target="_blank" (click)="flagCosts()">Nascondi
                      costi</a></li>
                  <li><a class="dropdown-item" target="_blank"
                      href="//o3.galimberti.eu/web#id={{s.id}}&cids=1&menu_id=178&action=296&model=sale.order&view_type=form">Apri
                      in Odoo</a></li>
                  <li><a class="dropdown-item" target="_blank"
                      (click)="copy('https://m3.galimberti.eu/leads/' + s.opportunity_id.id + '/sale/' + s.id)">Copia
                      link</a></li>
                  <li><a class="dropdown-item" target="_blank" (click)="delete(s)">Elimina</a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li class="dropdown-item disabled">
                    Creato il {{s.date_order | date}} <br> di {{s.user_id.name}}
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
        <!-- SECOND ROW: ORDER INFO & ELEMENTS -->
        <tr *ngIf="s._open" class="bg-light ">
          <td colspan="16" class="p-0 border-info">
            <nav class="navbar navbar-expand-md border-0">
              <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-flex justify-content-start" id="navbarTogglerDemo02">
                  <!-- price list-->
                  <div class="input-group mx-2 w-auto flex-nowrap">
                    <select class="form-control" *ngIf="s.pricelist_id.id" [(ngModel)]="s.pricelist_id.id"
                      style="width:auto"
                      title="Prezzi non disponibili per il listino a corpo. Seleziona un listino per attivare i prezzi."
                      (ngModelChange)="updateOrder(s, $event, 'pricelist_id', true)">
                      <option *ngFor="let pl of pricelists" [ngValue]="pl.id">{{pl.name}}</option>
                    </select>
                    <span class="input-group-text bg-white">
                      <i class="fa-solid fa-tags"></i>
                    </span>
                  </div>

                  <div class="input-group flex-nowrap mx-2 w-auto" *ngIf="hasPricelist(s)">
                    <select class="form-control" [ngModel]="s.payment_term_id.id"
                      (ngModelChange)="updateOrder(s, $event, 'payment_term_id', true)" style="width:auto"
                      title="Modalità di pagamento" placeholder="*Seleziona pagamento*">
                      <option *ngFor="let pl of paymentTerms" [value]="pl.id">{{pl.name}}</option>
                    </select>
                    <span class="input-group-text bg-white">
                      <i class="text-muted fa fa-credit-card"></i>
                    </span>
                  </div>

                  <div class="input-group mx-2 w-auto flex-nowrap" *ngIf="hasPricelist(s)">
                    <select class="form-control" [ngModel]="s.incoterm?.id"
                      (ngModelChange)="updateOrder(s,$event, 'incoterm', true)" style="width:auto"
                      title="Condizioni di vendita" placeholde="*Seleziona condizioni consegna*">
                      <option *ngFor="let pl of incoTerms" [value]="pl.id">{{pl.name}}</option>
                    </select>
                    <span class="input-group-text bg-white">
                      <i class="fa fa-truck-fast text-muted"></i>
                    </span>
                  </div>

                  <input type="text" class="mx-2 form-control w-auto flex-nowrap" placeholder="Rif. cliente"
                    [ngModel]="s.client_order_ref ? s.client_order_ref : ''"
                    (ngModelChange)="updateOrder(s, $event, 'client_order_ref')" [ngModelOptions]="{'updateOn':'blur'}"
                    title="Riferimento cliente">


                  <!-- <div class="input-group mx-2 w-auto flex-nowrap">
                    <select class="form-control" [ngModel]="s.picking_policy"
                      (ngModelChange)="updateOrder(s,$event, 'picking_policy')" [value]="s.picking_policy"
                      style="width:auto" title="Politica di prelievo">
                      <option value="one" [selected]="s.picking_policy == 'one'">Tutto insieme</option>
                      <option value="direct" [selected]="s.picking_policy == 'direct'">Prima possibile</option>
                    </select>
                    <span class="input-group-text bg-white">
                      <i class="fa fa-truck-fast text-muted"></i>
                    </span>
                  </div> -->

                  <div class="input-group mx-2 w-auto flex-nowrap">
                    <input type="text" class=" form-control w-auto flex-nowrap" placeholder="indirizzo di consegna"
                      [ngModel]="s.ga_address ? s.ga_address : ''"
                      (ngModelChange)="updateOrder(s, $event, 'ga_address')" [ngModelOptions]="{'updateOn':'blur'}"
                      title="Indirizzo di consegna">
                    <span class="input-group-text bg-white">
                      <i class="fa fa-location-pin text-muted"></i>
                    </span>
                  </div>

                  <!-- sale orde user -->
                  <div class="input-group mx-2 w-auto flex-nowrap">
                    <select class="form-control" [ngModel]="s.user_id.id"
                      (ngModelChange)="updateOrder(s,$event, 'user_id', true)" style="width:auto"
                      title="Utente di vendita">
                      <option *ngFor="let pl of users" [value]="pl.id">{{pl.name}}</option>
                    </select>
                    <span class="input-group-text bg-white">
                      <i class="fa fa-user text-muted"></i>

                    </span>
                  </div>
                </div>
              </div>
            </nav>
          </td>
        </tr>
        <!-- end secondary toolbar-->
      </thead>

      <tr *ngIf="s._open && !s.order_line.values" class="bg-light ">
        <td colspan="16">
          <div class="p-3 py-5">
            <i class="text-dark mx-3 fa fa-spinner  fa-spin"></i> &nbsp;&nbsp;&nbsp;Caricamento in corso...
          </div>
        </td>
      </tr>

      <tbody id='saleList-{{s.id}}' cdkDropList [cdkDropListData]="s" [cdkDropListConnectedTo]="saleIds"
        (cdkDropListDropped)="drop($event)">
        <tr *ngIf="certificationMessage && s._open">
          <td colspan="16" class="text-danger">{{certificationMessage}}</td>
        </tr>
        <tr *ngIf="!s.order_line || s.order_line.values?.length == 0">
          <td colspan="16" class="text-muted">Nota vuota - trascina un prodotto per iniziare</td>
        </tr>
        <ng-container *ngFor="let line of s.order_line.values; trackBy: identify">

          <tr cdkDrag [cdkDragData]="line" class="mw-100" id="tr{{line.id}}">

            <td cdkDragHandle class="align-middle">
              <i class="fa fa-bars fa-large me-2"></i>
              {{line.checked}}
              <input type="checkbox" class="form-check-input" (mouseup)="selectLine($event, line, s)"
                [(ngModel)]="line._checked" (ngModelChange)="updateOrderCheckbox(s)" name="x">
              <i class="fa fa-trash ms-3" *ngIf="line._checked" (click)="deleteLine(s)"></i>
            </td>

            <ng-container *ngIf="!line.display_type && s" [ngTemplateOutlet]="lineProductTpl"
              [ngTemplateOutletContext]="{line: line, order: s}">
            </ng-container>

            <ng-container *ngIf="line.display_type == 'line_section'" [ngTemplateOutlet]="lineSectionTpl"
              [ngTemplateOutletContext]="{line: line, order: s}">
            </ng-container>

            <ng-container *ngIf="line.display_type == 'line_note'" [ngTemplateOutlet]="lineNoteTpl"
              [ngTemplateOutletContext]="{line: line, order: s}">
            </ng-container>
          </tr>
        </ng-container>
      </tbody>

      <tbody *ngIf="!s.order_line || s.order_line.values">
        <td colspan="16">
          <textarea class="form-control w-100" [ngModel]="s.note ? innerText(s.note) : ''"
            placeholder="Note (compaiono nei trasferimenti e in produzione)"
            (ngModelChange)="updateOrder(s,$event, 'note')" [ngModelOptions]="{ updateOn: 'blur'}"></textarea>
        </td>
      </tbody>

      <thead class="bg-info border-info">


        <!-- FOOTER TOTALI NOTA -->
        <tr *ngIf="s._open" class="bg-white  border-bottom border-dark ">

          <td colspan="16" class="p-0 border-0">

            <nav class="navbar w-100 ">
              <div class="container-fluid">

                <div class="text-muted">
                  {{getInfoMultiple(s)}}
                </div>

                <div class="text-nowrap me-3 fw-bold">
                  Totale {{s.amount_untaxed | number : '1.0-2':'it-IT' }} €
                </div>
    
              </div>
            </nav>
          </td>
        </tr>
      </thead>
    </ng-container>
  </table>


  <app-sale-print *ngIf="isPrinting" class="shadow" style="overflow: scroll;" (onDone)="isPrinting = false"
    [orderLines]="orderLinesToPrint" [order]="activeSale"></app-sale-print>

  <ng-template #lineProductTpl let-line="line" let-order="order">

    <!-- id -->
    <td style="vertical-align: middle; width: 30px;" class="ps-3 text-start align-middle"
      [ngClass]="{'text-decoration-line-through text-info': line.product_uom_qty == 0}">
      {{line.product_id.value?.id}}
    </td>

    <!-- name -->
    <td class="text-start align-middle" style="text-wrap:balance;" *ngIf="line.product_id"
      [ngClass]="{'text-decoration-line-through text-info': line.product_uom_qty == 0}">
      {{line.product_id.value?.display_name?.replaceAll(', -','')}}
      <a class="text-wrap btn btn-link ms-1" title="apri in Odoo"
        href="https://o3.galimberti.eu/web?debug=1#id={{line.product_id.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form"
        target="_blank">
        <i class="text-wrap fa-solid fa-magnifying-glass my-0 py-1"></i>
      </a>
    </td>

    <td class="editable text-start align-middle" style="width: 50px;">
      <div class="d-flex align-items-center">

        <div class="dropdown flex-grow-1">
          <button [disabled]="loading || (order.state == 'sale' && line.product_uom_qty != 0)"
            class="btn w-100 p-0 btn-link dropdown-toggle"
            [ngClass]="{'text-decoration-line-through text-info': line.product_uom_qty == 0}" type="button"
            data-bs-toggle="dropdown" aria-expanded="false" (mousedown)="updateSelectableRoutes(order, line)">
            {{(line.route_id && line.route_id.name && line.route_id.name.split("-").length > 0) ?
            line.route_id.name.split("-")[0] : line.route_id.name}}
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let r of selectableRoutes">
              <button class="dropdown-item" (click)="updateLineRoute(order, line, r)">{{r.name}}</button>
            </li>
          </ul>
        </div>
      </div>
    </td>
    <!-- qty -->
    <td class="text-nowrap editable align-middle">
      <div class="d-flex align-items-center justify-content-end">

        <input-number [disabled]="loading" class="me-2" [ngModel]="line.product_uom_qty | number : '1.0-5':'en-EN'"
          (ngModelChange)="updateLine(order,line, 'product_uom_qty', $event)">
          <!-- [ngModelOptions]="{'updateOn':'blur'}" -->
        </input-number>
        <span>{{line.product_id.value?.uom_id?.name}}</span>
      </div>
    </td>


    <!-- qty packaging -->
    <td class="editable align-middle">
      <div class="d-flex align-items-center justify-content-end">

        <input-number [disabled]="loading" class="me-2" [hidden]="!line.product_packaging_id?.id"
          [ngModel]="line.product_packaging_qty | number : '1.0-5':'en-EN'"
          (ngModelChange)="updateLine(order,line, 'product_packaging_qty', $event)">
        </input-number>

        <div class="dropdown d-inline-block">
          <button class="btn w-100 p-0 btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false" (mousedown)="updateSelectablePackaging(line)">
            {{line.product_packaging_id.name}}
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let r of selectablePackagings">
              <button class="dropdown-item" (click)="updateLinePackage(order, line, r)">{{r.name}}</button>
            </li>
          </ul>
        </div>
      </div>
    </td>

    <!-- quantità descrittiva -->
    <td class="align-middle fst-normal text-nowrap">

      <div class="d-flex align-items-center justify-content-start">
        <pre class="mb-0">{{getDescriptive(line)}}</pre>
      </div>
    </td>

    <!-- disponibilità -->
    <td class="align-middle text-center" 
    *ngIf="showDeliveries">
       
      <div class="d-flex">
        <!-- @if (hasInlineProduction(line, order)) {
        <button class="btn btn-sm text-white w-100" [ngClass]="getProductionOutline(line, order)"
          (click)="onClickProduction(line, order)" title="Produci da distinta">
          <i class="fa-solid fa-hammer-crash"></i>
        </button>
        } -->

        <div class="dropdown" *ngIf="!order._resolvedProcurement">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div class="dropdown" *ngIf="order._resolvedProcurement">

          <button class="btn btn-link" type="button" data-bs-toggle="dropdown" aria-expanded="false"
            (click)="resolveLine(line)">
            <ng-container *ngFor="let i of getIconsForLine(line,order)">
              <i class="me-2" [ngClass]="i"></i>
            </ng-container>
          </button>

          <div class="dropdown-menu p-3">
            @if (!line._resolved || !order._resolved) {
              <div class="d-flex">
                <i class="fa fa-spinner fa-spin"></i>
              </div>
            }
            @if (line._resolved && order._resolved) {
              <table class="table table-bordered table-sm mb-2">
                <thead>
                  <tr>
                    <th *ngIf="line._purchaselineids?.length == 0" class="text-center">Stock</th>
                    <th *ngIf="line._purchaselineids?.length > 0 && order.state =='sale' " class="text-center">Arrivi</th>
                    <th *ngIf="line._purchaselineids?.length == 0" class="text-center">Liberi</th>
                    <th class="text-center" *ngIf="order.state =='sale'">Riservati</th>
                    <th class="text-center" *ngIf="order.state =='sale'">Spediti</th>
                    <th class="text-center" *ngIf="order.state =='sale'">Da spedire</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Base UOM row -->
                  <tr>
                    <td *ngIf="line._purchaselineids?.length == 0" class="text-center">
                      {{line.product_id.value?.qty_available || 0 | number : '1.0-2':'it-IT'}} {{line.product_id.value?.uom_id?.name}}
                    </td>
                    <td *ngIf="line._purchaselineids?.length > 0 && order.state =='sale'" class="text-center">
                      {{line._quantity_arrived | number : '1.0-2':'it-IT'}} {{line.product_id.value?.uom_id?.name}}
                    </td>
                    <td *ngIf="line._purchaselineids?.length == 0 && order.state =='sale'" class="text-center">
                      {{getFree(line, order) | number : '1.0-2':'it-IT'}} {{line.product_id.value?.uom_id?.name}}
                    </td>
                    <td class="text-center" *ngIf="order.state =='sale'" >
                      {{(line._reserved_qty || 0) | number : '1.0-2':'it-IT'}} {{line.product_id.value?.uom_id?.name}}
                    </td>
                    <td class="text-center" *ngIf="order.state =='sale'">
                      {{line.qty_delivered | number : '1.0-2':'it-IT'}} {{line.product_id.value?.uom_id?.name}}
                    </td>
                    <td class="text-center" *ngIf="order.state =='sale'">
                      {{line.qty_to_deliver | number : '1.0-2':'it-IT'}} {{line.product_id.value?.uom_id?.name}}
                    </td>
                  </tr>
                  <!-- Packaging row - only show if packaging is selected -->
                  <tr *ngIf="line.product_packaging_id?.value">
                    <td *ngIf="line._purchaselineids?.length == 0" class="text-center text-muted small">
                      {{getPackagingQuantity(line.product_id.value?.qty_available, line)}}
                    </td>
                    <td *ngIf="line._purchaselineids?.length > 0 && order.state =='sale'"  class="text-center text-muted small">
                      {{getPackagingQuantity(line._quantity_arrived, line)}}
                    </td>
                    <td *ngIf="line._purchaselineids?.length == 0" class="text-center text-muted small">
                      {{getPackagingQuantity(getFree(line, order), line)}}
                    </td>
                    <td class="text-center text-muted small" *ngIf="order.state =='sale'">
                      {{getPackagingQuantity((line._reserved_qty || 0), line)}}
                    </td>
                    <td class="text-center text-muted small" *ngIf="order.state =='sale'">
                      {{getPackagingQuantity(line.qty_delivered, line)}}
                    </td>
                    <td class="text-center text-muted small" *ngIf="order.state =='sale'">
                      {{getPackagingQuantity(line.qty_to_deliver, line)}}
                    </td>
                  </tr>
                </tbody>
                <!-- vedi i trasferimenti as a tfoot button -->
                <tfoot *ngIf="order.state=='sale' && line.product_id.value.detailed_type != 'service'" >
                  <tr>
                    <td colspan="6" class="text-center">
                      <app-transfers-table
                      [saleOrder]="order"
                      [orderLine]="line"
                      (loading)="loading = $event"
                      ></app-transfers-table>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <!-- divider -->
                <div class="dropdown-divider"></div>

                <!-- Cost lines - using w-100 and justify-content-between -->
                <div class="d-flex w-100 justify-content-between" *ngIf="order.state == 'sale' || order.state == 'draft'">
                    <span>Costo di acquisto</span>
                    <span><b>{{ (line._line_cost_fetched_data?.cost | number : '1.0-2':'it-IT') + " €/" + (line.product_id.value?.uom_id?.name) }}</b></span>
                </div>

                <div class="d-flex w-100 justify-content-between" *ngIf="order.state == 'sale' || order.state == 'draft'">
                    <span>Fonte costo</span>
                    <span><b>{{line._line_cost_fetched_data?.origin }}</b></span>
                </div>

                <!-- connected purchase -->
                <div class="d-flex w-100" *ngIf="line._purchase_line_values?.length > 0">
                    <div class="w-100">
                        <div *ngFor="let p of line._purchase_line_values" class="mt-1">
                            <button class="btn btn-sm btn-primary text-white w-100" (click)="onClickPurchase(p)">
                                {{p?.order_id.name}}
                            </button>
                        </div>
                    </div>
                </div>

            }
          </div>
        </div>
      </div>
    </td>
    <!-- consegnati -->
    <td class="align-middle text-center"
    *ngIf="showDeliveries">
      <div
        *ngIf="order.state == 'sale' && line.product_uom_qty > 0 && line.product_id.value?.detailed_type !== 'service'"
        [ngClass]="getDeliveryClass(line)">
        {{line.qty_delivered | number : '1.0-2':'it-IT'}} / {{line.product_uom_qty | number : '1.0-2':'it-IT'}}
        {{line.product_id.value?.uom_id?.name}}
      </div>
    </td>


    <!-- DIMENSIONI-->
    <td class="text-right align-middle" [ngClass]="{'editable': isWidthEditable(line)}">
      <input-number data-print-col [disabled]="!isWidthEditable(line) || order.state == 'sale' || loading"
        (ngModelChange)="updateVariant3(order, line, $event, 'Larghezza')"
        [ngModel]="getVariantAttribute(line, 'Larghezza')?.name"></input-number>
    </td>

    <td class="text-right align-middle" [ngClass]="{'editable': isHeightEditable(line)}">
      <input-number data-print-col [disabled]="!isHeightEditable(line) || order.state == 'sale'"
        (ngModelChange)="updateVariant3(order, line, $event, 'Altezza')"
        [ngModel]="getVariantAttribute(line, 'Altezza')?.name"></input-number>
    </td>

    <td class="text-right align-middle" [ngClass]="{'editable': isLengthEditable(line)}">
      <input-number [disabled]="!isLengthEditable(line) || loading || order.state == 'sale'"
        (ngModelChange)="updateVariant3(order, line, $event, 'Lunghezza')"
        [ngModel]="getVariantAttribute(line, 'Lunghezza')?.name">
      </input-number>
    </td>

    <td class="text-end align-middle editable">
      <input-number [disabled]="loading || hasPricelist(order)==false"
        [ngClass]="{'text-light': hasPricelist(order)==false}" [ngModel]="line.price_unit"
        (ngModelChange)="updateLine(order, line, 'price_unit', $event)"></input-number>
    </td>

    <td class="editable text-end align-middle">
      <input-number [disabled]="loading || hasPricelist(order)==false"
        [ngClass]="{'text-light': hasPricelist(order)==false}" [ngModel]="line.discount"
        (ngModelChange)="updateLine(order, line, 'discount', $event)"></input-number>
    </td>

    <td class="text-end align-middle" [ngClass]="{'text-white': hasPricelist(order)==false}">
      {{(line.price_subtotal | number : '1.0-2':'it-IT')}}
    </td>

    <td class="text-end align-middle text-muted fst-italic" *ngIf="showCosts">
      <span *ngIf="!costsLoaded">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
      <span *ngIf="costsLoaded" >

      {{ (line._line_cost_fetched_data?.cost * line.product_uom_qty | number : '1.0-2':'it-IT')}}
      </span>
    </td>
  </ng-template>

  <ng-template #lineSectionTpl let-line="line" let-order="order">

    <td colspan="16" class="">
      <input class="text-muted fw-bold form-control border-0 bg-transparent" [ngModelOptions]="{'updateOn':'blur'}"
        [ngModel]="line.name" (ngModelChange)="updateLine(order, line, 'name',$event)">
    </td>
  </ng-template>

  <ng-template #lineNoteTpl let-line="line" let-order="order">

    <td colspan="16" class="">
      <input class="text-muted w-100 form-control border-0 bg-transparent" [ngModel]="line.name"
        [ngModelOptions]="{'updateOn':'blur'}" (ngModelChange)="updateLine(order, line, 'name',$event)">
    </td>
  </ng-template>


  <router-outlet>

  </router-outlet>