import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lead } from 'src/app/models/crm.lead.model';
import { ODOO_IDS} from 'src/app/models/deal';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { Product, ProductWithOnlyVariants } from 'src/app/models/product.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { StockLocationRoute } from 'src/app/models/stock-location-route.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockPicking } from 'src/app/models/stock-picking';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { ProductPricelist } from 'src/app/models/product.pricelist';
import { firstValueFrom, max, merge } from 'rxjs';
import { AccountPaymentTerm } from 'src/app/models/account-payment-term.model';
import { AccountIncoTerm } from 'src/app/models/account-inco-term.model';
import { SaleOrderCancel } from 'src/app/models/sale-order-cancel.model';
import { ProductPackaging } from 'src/app/models/product.packaging.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CrmLeadPart } from 'src/app/models/crm.lead.part.model';
import { ProcurementGroup } from 'src/app/models/procurement.group.model';
import { StockQuant } from 'src/app/models/stock-quant';
import Decimal from 'decimal.js'
import { Contact } from 'src/app/models/contact.model';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { PurchaseOrderLine } from 'src/app/models/purchase-order-line.model';
import { PriceList } from 'src/app/models/price.list.model';
import { MailActivity } from 'src/app/models/mail.message';
import { MrpProduction } from 'src/app/models/mrp-production';
import { OrderInventoryComponent } from 'src/app/components/order-inventory/order-inventory.component';
import { User } from 'src/app/models/user.model';


@Component({
  selector: "app-sale-order-editor",
  templateUrl: "./sale-order-editor.component.html",
})
export class SaleOrderEditorComponent implements OnInit {


  @ViewChild('orderInventory') orderInventory:OrderInventoryComponent;
  identify: TrackByFunction<any> = (index, item) => item.id;

  // Component properties
  id: any;
  loading: boolean = true;
  selectableRoutes: StockLocationRoute[] = [];
  inventoryClosed: boolean = true;
  pickings: StockPicking[];
  moves: StockMove[];
  pricelists: PriceList[];
  paymentTerms: AccountPaymentTerm[] = [];
  incoTerms: AccountIncoTerm[] = [];
  draggingLine: SaleOrderLine;
  isPrinting: boolean = false;
  roundedTotal: number;
  filterColumn: { name } = { name: null };
  editingDiscount = false;
  draggingQtyColumn: SaleOrderLine;
  selectablePackagings: ProductPackaging[];
  dragTarget: any;
  relatedSales: SaleOrder[] = [];
  detailPicking: StockPicking;
  saleIds: any;
  opportunity: Lead;
  activeSale: SaleOrder;
  thisIs: this;
  certificationMessage: string="";
  showCosts: boolean = false;
  costsLoaded: boolean = false;
  users: User[] = [];
  loadedInventory: boolean = false;
  showInventory: boolean = false;
  showDeliveries: boolean = false;

  isMultiple: boolean;
  orderLinesToPrint: any[];
  part: CrmLeadPart;
  procurementGroup: ProcurementGroup;
  replenishQty: number = null;
  replenishPackaging: ProductPackaging;

  // Constructor to inject services
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private odooEm: OdooEntityManager,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    // Subscribe to route parameters to get the order_id
    this.route.params.subscribe(async (params) => {
      this.id = params["order_id"];
  
      // fetch possible users to assign
      this.users = await firstValueFrom(this.odooEm.search<User>(new User()))

      // Asynchronous search for SaleOrder with the obtained order_id
      let orders = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", this.id]])); 
      let order = orders[0];
      // Initialize an array to load related sales and resolve the sale order
      let p = [];
      p.push(this.loadRelatedSales(order));
      p.push(this.resolveSale(order));

      // Asynchronous search for AccountIncoTerm and update incoTerms once data is received + paynment terms
      this.pricelists = await firstValueFrom(
        this.odooEm.search<ProductPricelist>(new ProductPricelist())
      );
      this.odooEm
        .search<AccountIncoTerm>(new AccountIncoTerm())
        .subscribe((x) => {
          this.incoTerms = x;
        });
        this.paymentTerms = await firstValueFrom( this.odooEm.search<AccountPaymentTerm>(new AccountPaymentTerm()))
    });

    // Hack to bind 'this' context
    this.thisIs = this;
  }

  async loadRelatedSales(order: SaleOrder) {
    // Search for CrmLeadPart related to the sale order
    var parts = await firstValueFrom(this.odooEm.search<CrmLeadPart>(new CrmLeadPart(), [["sale_order_ids", "in", [order.id]],]));

    if (parts && parts.length) {
      // If parts are found, search for SaleOrders related to the CrmLeadPart
      this.part = parts[0];
      var s = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "in", this.part.sale_order_ids.ids],]));
    } else {
      // If no parts are found, search for the SaleOrder with the given order id
      var s = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", order.id],]));
    }

    // Reverse the order of sales for further processing
    s.reverse();

    // Replace the original order in the list with the updated order object
    let o = s.find((x) => x.id == order.id);
    let i = s.indexOf(o);
    s.splice(i, 1, order);

    // Update the relatedSales and saleIds property with the modified list of sales
    this.relatedSales = s;
    this.saleIds = null;
    this.saleIds = s
      .map((x) => "saleList-" + x.id)
      .concat(["inventoryList", "toolList"]);

    await firstValueFrom(this.odooEm.resolveArray(new MailActivity(),this.relatedSales,"activity_ids"));

    // Compute the delivery state for each related sale
    this.odooEm.call2("sale.order", "ga_compute_order_state", [null, this.relatedSales.map((r) => r.id),])
      .then((x) => {
        this.relatedSales.forEach((r, i) => {
          r._delivery_state = x.result[i];
        });
      });

    // Resolve the opportunity associated with the first related sale
    firstValueFrom(this.odooEm.resolveSingle(new Lead(), this.relatedSales[0].opportunity_id)
    ).then(() => {
      this.opportunity = this.relatedSales[0].opportunity_id?.value
      this.checkCertficiation()
    });
  }

  async checkCertficiation() {
    if (this.opportunity) {
      if (this.opportunity.tag_ids.ids.includes(ODOO_IDS.crmTagPEFC)) {
        this.certificationMessage = "ATTENZIONE! Questo ordine richiede la certificazione PEFC"
      }
      if (this.opportunity.tag_ids.ids.includes(ODOO_IDS.crmTagstrutturale)) {
        this.certificationMessage = "ATTENZIONE! Questo ordine richiede la certificazione strutturale"
      } 
    }
  }

  async resolveSale(s: SaleOrder): Promise<SaleOrder> {
    this.loading = true;
    // Fetch the SaleOrder details from the server
    var res = await firstValueFrom(
      this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", s.id]])
    );

    // component variables to store the progress of the resolution
    s._open = true;
    s._resolving = true;
    s._resolved = false;
    s.state = res[0].state;

    // Resolve the order lines and their associated products
    await firstValueFrom(this.odooEm.resolve(res[0].order_line))
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), res[0].order_line.values, 'product_id'))
    
    // Update the SaleOrder properties with the resolved data
    s.order_line.ids = res[0].order_line.ids
    s.order_line.values = res[0].order_line.values
    s.pricelist_id = res[0].pricelist_id
    s.amount_untaxed = res[0].amount_untaxed
    s.procurement_group_id.id = res[0].procurement_group_id.id
    s._open = true
    s._resolving = false

    //run fetch cost if show costs is on
    if (this.showCosts) {
      this.loading = true;
      this.costsLoaded = false;
      const promises = (s.order_line.values || [])
        .map(l => this.fetchCostData(l));
      await Promise.all(promises);
      this.costsLoaded = true;
      this.loading = false;
    }

    // Collect all products from the order lines
    var products = [];
    s.order_line.values.forEach((s) => {
      if (s.product_id && s.product_id.id && s.product_id.value)
        products.push(s.product_id.value);
    });

    // Resolve product packagings and template attribute values if products exist
    if (products.length > 0) {
      await this.odooEm
        .resolveArray(new ProductPackaging(), products, "packaging_ids")
        .toPromise();
      await this.odooEm
        .resolveArrayOfSingle(
          new ProductPackaging(),
          s.order_line.values,
          "product_packaging_id"
        )
        .toPromise();
      this.odooEm
        .resolveArray(
          new ProductTemplateAttributeValue(),
          products,
          "product_template_attribute_value_ids"
        )
        .toPromise();
    }

    //load procurement groups, purchases
    this.checkProcuremntGroup(s).then(() => {
      this.checkPurchases(s);
    });

    // Compute the delivery state of the SaleOrder
    this.odooEm.call2('sale.order', 'ga_compute_order_state', [ null, [s.id]]).then(x => {
      s._delivery_state = x.result[0]
    })

    this.activeSale = s;
    s._resolved = true;
    this.loading = false;

    return s;
  }


  hasPricelist(s: SaleOrder) {
    // Checks if the given SaleOrder has a pricelist that is not equal to the predefined 'pricelist_commessa'
    return s.pricelist_id.id != ODOO_IDS.pricelist_commessa;
  }

  selectLine(ev: PointerEvent, line: SaleOrderLine, o: SaleOrder) {
    var newValue = (ev.target as any).value;

    // Handle multi-selection with shift key
    if (ev.shiftKey) {
      // find the last selected line
      var sl = o.order_line.values.find((l) => {
        return l._checked;
      });

      var bi = o.order_line.values.indexOf(sl);
      var ei = o.order_line.values.indexOf(line);

      // Update the _checked property based on the range between the last selected line and the current line
      for (var x = 0; x < o.order_line.values.length; x++) {
        if (ei == bi) {
          o.order_line.values[x]._checked = false;
          o._checked = false;
        } else if (x >= bi && x < ei) o.order_line.values[x]._checked = true;
        else {
          o.order_line.values[x]._checked = false;
          o._checked = false;
        }
      }
      // Update the isMultiple flag
      this.isMultiple = bi != ei;
    }

    return true;
  }

  selectOrder(ev, o: SaleOrder) {
    // Selects or deselects all lines in the SaleOrder based on the provided event value.
    if (o.order_line && o.order_line.values?.length > 0) {
      o.order_line.values.forEach((x) => (x._checked = ev));
    }
  }

  updateOrderCheckbox(o) {
    //Updates the _checked property of the SaleOrder based on the selection state of its lines.
    if (o.order_line && o.order_line.values?.length > 0) {
      var c = o.order_line.values.filter((x) => x._checked == true);
      o._checked = c.length == o.order_line.values.length;
    }
  }

  async toggleSale(s: SaleOrder) {
    //Toggles the open state of a SaleOrder.
    if (!s._open) {
      // Resolve and open the sale order if it's not already open
      await this.resolveSale(s);
      s._open = true;
      
      //if the flag is on, fetch costs
      if (this.showCosts) { 
        this.loading = true;
        this.costsLoaded = false;
        
        // Create array of promises from all order lines
        const promises = (s.order_line.values || [])
          .map(l => this.fetchCostData(l));
        
        // Wait for all cost data to be fetched
        await Promise.all(promises);
        
        this.costsLoaded = true;
        this.loading = false;
      }
    } else {
      // Close the sale order and clear its order lines
      s.order_line.values = null;
      s._open = false;
    }
}

  async flagCosts() {
    this.showCosts = !this.showCosts;
    this.costsLoaded = false;

    if (this.showCosts) { 
  
      
      // Filter all open sales
      let openSales = this.relatedSales.filter(s => s._open);
      
      // Create array of all promises for lines that do not have cost data
      const promises = openSales
        .map(s => s.order_line.values)
        .flat()
        .filter(l => !l._line_cost_fetched_data)
        .map(l => this.fetchCostData(l));
      // Wait for all promises to resolve
      await Promise.all(promises);
      
      this.costsLoaded = true;
  
    }
}

async toggleDeliveries() {
  this.showDeliveries = !this.showDeliveries;
}


  // CODE IS COMMENTET UP TO THIS POINT
  async checkPurchases(s: SaleOrder) {
    //se il prodotto è un servizio, prendi line.purchase_line_ids

    s.order_line.values.forEach((l) => {
      if (!l.display_type && l.product_id.value.detailed_type == "service") {
        l._purchaselineids = l.purchase_line_ids.ids;
      }
    });
    s.procurement_group_id.value?.stock_move_ids.values.forEach((m) => {
      if (m.picking_code == "incoming" &&m.location_id.id == ODOO_IDS.stock_location_vendor) {
        // check moves for product id
        for (let line of s.order_line.values) {
          //if descriprive skip

          if (!line.display_type && line.product_id.id == m.product_id.id) {
            if (line._quantity_arrived == null) {
              line._quantity_arrived = 0;
            }
            line._quantity_arrived = line._quantity_arrived + m.quantity_done;
            line._purchaselineids.push(m.purchase_line_id.id);
          }
        }
      }
    });


    //for each sale line resolve the purchase lines
    await firstValueFrom(
      this.odooEm.resolveArray(
        new PurchaseOrderLine(),
        s.order_line.values,
        "purchase_line_ids"
      )
    );

  }

  getDeliveryBadge(state: string): { class: string, title: string } {
    const badges = {
      'Confermato': { class: 'bg-primary', title: 'Confermato' },
      'Spedito interamente': { class: 'bg-success', title: 'Spedito interamente' },
      'Confermato - acquisti arrivati': { class: 'bg-primary', title: 'Confermato - acquisti arrivati' },
      'Preparato - non spedito': { class: 'bg-warning', title: 'Preparato - non spedito' },
      'Spedito parzialmente': { class: 'bg-warning', title: 'Spedito parzialmente' },
      'Bozza': { class: 'bg-dark', title: 'Bozza' },
      'Annullato': { class: 'bg-light text-dark', title: 'Annullato' }
    };
  
    return badges[state] || { class: 'bg-secondary', title: state }; // Default fallback
  }

  getFilteredRowsForSale(sale: SaleOrder) {
    return sale.order_line?.values;
  }

  async onClickPurchase(line: PurchaseOrderLine) {
    
    this.router.navigate(["purchase", line.order_id.id], {
      relativeTo: this.route,
    });
    //}
    this.loading = false;
  }

  async onViewPickings(s: SaleOrder) {
    this.router.navigate(["/pickings"], {
      relativeTo: this.route,
      queryParams: { search: s.name },
    });
  }

  async checkProcuremntGroup(s: SaleOrder) {
    await firstValueFrom(
      this.odooEm.resolveSingle(new ProcurementGroup(), s.procurement_group_id)
    );

    if (s.procurement_group_id.value)
      await firstValueFrom(
        this.odooEm.resolve(s.procurement_group_id.value.stock_move_ids)
      );

     //for each sale line run getreserved
    s.order_line.values.forEach((l) => {
      if (l.product_id.value) {
        l._reserved_qty = this.getReserved(l,s);
      }
    }
    ); 

    s._resolvedProcurement = true;


    return s.procurement_group_id.value;
  }

  public async draft(s: SaleOrder) {
    this.loading = true;
    try {
      await this.odooEm.call(new SaleOrder(), "action_draft", s.id).toPromise();
    } catch (e) {
      this.loading = false;
      alert(e.message);
    }

    await this.resolveSale(s);
    this.loading = false;
  }

  public async cancel(s: SaleOrder) {
    this.loading = true;

    let purch = s.purchase_order_count;
    if (purch > 0) {
      this.loading = false;
      return alert(
        "Impossibile annullare, ci sono " +
          purch +
          " acqusito/i collegato/i " +
          "\nContatta responsabile acqusiti per modificare l'ordine"
      );
    }

    //let pg = await this.checkProcuremntGroup(s)
    // controlla se ci sono picking con qty_done >0
    let done = s.procurement_group_id?.value?.stock_move_ids.values.find(
      (m) =>
        m.quantity_done &&
        m.state !== "cancel" &&
        m.location_id.id != ODOO_IDS.stock_location_vendor
    );
    if (done) {
      this.loading = false;
      return alert(
        "Impossibile annullare, ci sono trasferimenti completati " +
          done.picking_id.name +
          "\nContatta responsabile magazzino"
      );
    }

    var cancel = await firstValueFrom(
      this.odooEm.create<SaleOrderCancel>(new SaleOrderCancel(), {
        order_id: s.id,
      })
    );

    try {
      await this.odooEm
        .call(new SaleOrderCancel(), "action_cancel", [cancel.id])
        .toPromise();
    } catch (e) {
      this.loading = false;
      alert(e.message);
    }
    await this.resolveSale(s);
    this.loading = false;
  }

  async check(f) {
    let r = await f;
    if (r.error) {
      this.loading = false;
      alert(r.error.data.message);
      throw r;
    }
    return r;
  }

  public async confirm(s: SaleOrder) {
    if (!s.partner_id.value)
      await firstValueFrom(
        this.odooEm.resolveSingle(new Contact(), s.partner_id)
      );

    if (!s.partner_id.value.ga_arca)
      return alert(
        "Impossibile confermare. Il cliente non ha un codice per la fatturazione."
      );

    // check every line with a product has a route
    var fs = s.order_line.values.filter(
      (l) => l && l.product_id?.value && l && !l.display_type && !l.route_id.id
    );
    if (fs.length > 0)
      return alert("Tutti i prodotti devono avere un percorso")
    this.loading = true

    await this.check(this.odooEm.call2(new SaleOrder().ODOO_MODEL, "action_confirm",[[s.id]] ))

    await this.resolveSale(s);
    this.loading = false;
  }

  async delete(o: SaleOrder) {
    if (!confirm("Confermi di eliminare la nota ?")) return;
    this.loading = true;
    var res = await firstValueFrom(this.odooEm.delete(new SaleOrder(), [o.id]));
    if (res) this.relatedSales.splice(this.relatedSales.indexOf(o), 1);
    this.loading = false;
  }

  async deleteLine(o: SaleOrder) {
    this.loading = true; 

    var ids = o.order_line.values.filter((l) => l._checked).map((x) => x.id);
    var objs = o.order_line.values.filter((l) => l._checked);

    //if order is not sale, delete all the lines
    if (confirm("Confermi eliminazione delle righe selezionate?")) {

      if (o.state !== "sale") {
          let del = [];
          del = objs.map((x) => x.id);

        if (del.length > 0) {
          await firstValueFrom(this.odooEm.delete(new SaleOrderLine(), del));
        }
      } else {
        //if order is sale, delete only notes and sections, and set products quatity to 0, then delete
        let notes = objs.filter((x) => x.display_type).map((x) => x.id);
        let prods = objs.filter((x) => !x.display_type);
        if (notes.length > 0)
          await this.odooEm.delete(new SaleOrderLine(), notes);
        //fot orher lines, set quantity to 0 through updateline

        if (prods.length > 0) {

          //for each prod call the update line
          for (let p of prods) {
            await this.updateLine(o, p, "product_uom_qty", 0);
          
          }

        }
      }
    }

    await this.resolveSale(o);
    this.loading = false;
  }

  getMoveClass(m) {
    if (m.state == "waiting") return "bg-muted";
    if (m.state == "assigned") return "bg-success";
    if (m.state == "done") return "bg-success text-stroke";
    if (m.state == "confirmed") return "bg-warning";
  }

  getVariantAttribute(line: SaleOrderLine, name: string) {
    if (!line.product_id) return;

    if (
      !line.product_id.value ||
      !line.product_id.value.product_template_attribute_value_ids
    )
      return "";

    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f =
        line.product_id.value.product_template_attribute_value_ids.values.filter(
          (value) => {
            return value.attribute_id.name.startsWith(name);
          }
        );
    }

    return f && f.length > 0 ? f[0] : null;
  }

  async updateLineRoute(order: SaleOrder, line: SaleOrderLine, route) {
    this.loading = true;

    // if multiple lines are selected, update all of them but skip notes and sections
    var objs = order.order_line.values.filter(
      (l) => l._checked && !l.display_type
    );

    if (objs.length > 0)
      await this.odooEm.updateMultiple(objs, { route_id: route.id });
    else await this.odooEm.update(line, { route_id: route.id }).toPromise();

    // this.selectLine

    // await this.load()
    await this.resolveSale(order);
  }

  async updateLinePackage(order, line, p) {
    this.loading = true;

    var pqty = line.product_uom_qty;

    await firstValueFrom(
      this.odooEm.update(line, {
        product_uom_qty: pqty,
        product_packaging_id: p.id,
      })
    );

    await this.refreshLine(line);
  }

  // hack buttons should be in the inventory
  async onAddNotes(l: SaleOrderLine) {
    var s = this.relatedSales.find((x) => x._open);
    if (s) {
      await firstValueFrom(
        this.odooEm.create(new SaleOrderLine(), {
          display_type: l.display_type,
          name: l.name,
          order_id: s.id,
          sequence: 10000,
        })
      );

      await this.odooEm.call2(s.ODOO_MODEL, "reorder_order_line", [
        s.id,
        l.id,
        10000,
      ]);

      this.resolveSale(s);
    }
  }

  toggleInventory() { //this allows to load the inventory only when the tab is opened the first time, then simply hide it
    if (!this.loadedInventory) {
      this.loadedInventory = true;
    }
    this.showInventory = !this.showInventory;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const inventory = document.getElementById('inventory');
    const toggleButton = event.target as HTMLElement;
    
    if (this.showInventory && 
        inventory && 
        !inventory.contains(event.target as Node) &&
        !toggleButton.closest('.btn-light')) {
      this.showInventory = false;
    }
  }

  async refreshLine(line: SaleOrderLine) {
    this.loading = true;
    // refresh the needed computed on line too
    var fresh = (
      await this.odooEm
        .search<SaleOrderLine>(new SaleOrderLine(), [["id", "=", line.id]])
        .toPromise()
    )[0];

    line.product_uom_qty = fresh.product_uom_qty;
    line.product_packaging_qty = fresh.product_packaging_qty;

    line.product_packaging_id.id = fresh.product_packaging_id.id;
    line.product_packaging_id.name = fresh.product_packaging_id.name;
    line.product_packaging_id.value = fresh.product_packaging_id.value = null;
    await firstValueFrom(
      this.odooEm.resolveSingle(
        new ProductPackaging(),
        line.product_packaging_id
      )
    );

    line.price_unit = fresh.price_unit;
    line.discount = fresh.discount;

    line.forecast_expected_date = fresh.forecast_expected_date;
    line.free_qty_today = fresh.free_qty_today;
    line.qty_available_today = fresh.qty_available_today;
    line.price_subtotal = fresh.price_subtotal;
    this.loading = false;
  }

  async createSaleFromRows() {
    this.loading = true;

    // create new order
    let so = {
      partner_id: this.relatedSales[0].partner_id.id,
      opportunity_id: this.relatedSales[0].opportunity_id.id,
      picking_policy: "direct",
      ga_address: this.relatedSales[0].opportunity_id.value?.street,
      pricelist_id: ODOO_IDS.pricelist_commessa,
    };
    var newSo = await firstValueFrom(
      this.odooEm.create<SaleOrder>(new SaleOrder(), so)
    );

    // update the part
    // TODO shoud have automatic external key in odoo
    await this.odooEm
      .update(this.part, { sale_order_ids: [[4, newSo.id]] })
      .toPromise();

    await this.loadRelatedSales(this.activeSale);
    await this.resolveSale(this.relatedSales[this.relatedSales.length - 1]);
    this.loading = false;
  }

  async insertProduct(order:SaleOrder, product:Product|ProductWithOnlyVariants, sequence?) {

    // extract parts of route
    //  
    var hasProduction = this.router.url.split("/").includes("production")
    if (hasProduction) {
      //fetch prodcution from url
      let production = await firstValueFrom(this.odooEm.search<MrpProduction>(new MrpProduction(), [['id', '=', Number(this.router.url.split("/").pop())]]))

      let init = {
        //url after last slash
        // production_id : production[0].id,
        product_id: product.id,
        product_uom_qty:1,
        location_id: production[0].location_src_id.id,
        bom_line_id: false,
        additional: false,
        raw_material_production_id: production[0].id
      }
      if (product.packaging_ids.ids && product.packaging_ids.ids.length > 0) {
        init['product_packaging_id'] = product.packaging_ids.ids[0]
      }

      //let move = await firstValueFrom(this.odooEm.create<StockMove>(new StockMove(), init))

      await firstValueFrom(this.odooEm.update<MrpProduction>(production[0], {
        "move_raw_ids": [[0, 0, init]]
      }))
      this.odooEm.messages.next("reload")

    } else {
      // copy route_id from first line
      // let route_id = order.order_line.values.find(l => l.route_id)?.route_id.id
      let route_id = false
      let init = {
        order_id : order.id,
        sequence: sequence,
        product_id: product.id,
        product_uom_qty:0,
        route_id: route_id ? route_id : false
      }

      if (product.packaging_ids.ids && product.packaging_ids.ids.length > 0) {
        init['product_packaging_id'] = product.packaging_ids.ids[0]
      }

      await firstValueFrom(this.odooEm.create(new SaleOrderLine(), init))
      await this.resolveSale(order)
    }
   
    
  }

  async drop(el: CdkDragDrop<SaleOrder, SaleOrder, SaleOrderLine>) {
    if (
      el.item.data instanceof SaleOrderLine &&
      !el.item.data.id &&
      (el.item.data.display_type == "line_note" ||
        el.item.data.display_type == "line_section")
    ) {
      var ln = {
        order_id: el.container.data.id,
        sequence: 10000,
        name: el.item.data.name,
        product_id: false,
        product_uom_qty: false,
        display_type: el.item.data.display_type,
      };

      var line = await firstValueFrom(
        this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), ln)
      );
      await this.odooEm.call2(
        el.container.data.ODOO_MODEL,
        "reorder_order_line",
        [
          el.container.data.id,
          line.id,
          el.container.data.order_line.values[el.currentIndex].sequence,
        ]
      );
      await this.resolveSale(el.container.data);
      return;
    }

    if (
      el.item.data instanceof Product ||
      el.item.data instanceof ProductWithOnlyVariants
    ) {
      await this.insertProduct(
        el.container.data,
        el.item.data,
        el.currentIndex
      );
      return;
    
    }

    var sourceSale: SaleOrder = el.previousContainer.data;
    var targetSale: SaleOrder = el.container.data;

    //drag and drop solo tra sales in draft!!!
    // escludo dal controllo se è lo stesso sale
    if (
      sourceSale.id != targetSale.id &&
      (sourceSale.state != "draft" || targetSale.state != "draft")
    ) {
      return alert(
        "Non e' possibile spostare righe tra ordini confermati o annullati, solo ordini in bozza"
      );
    }
    this.loading = true;

    var sourceLine = el.item.data;
    if (sourceSale.id != targetSale.id) {
      await firstValueFrom(
        this.odooEm.update(sourceLine, {
          order_id: targetSale.id,
        })
      );
      this.resolveSale(sourceSale);
      await this.resolveSale(targetSale);
    } else {
      await this.odooEm.call2(targetSale.ODOO_MODEL, "reorder_order_line", [
        targetSale.id,
        el.item.data.id,
        targetSale.order_line.values[el.currentIndex].sequence,
      ]);
      await this.resolveSale(targetSale);
    }
    this.loading = false;
  }

  async updateSelectablePackaging(line: SaleOrderLine) {
    await firstValueFrom(
      this.odooEm.resolve(line.product_id.value.packaging_ids)
    );
    this.selectablePackagings = line.product_id.value.packaging_ids.values;
  }

  async updateSelectableRoutes(order: SaleOrder, line: SaleOrderLine) {
    //fetch selected lines except for sections and notes

    var lines = order.order_line.values.filter(
      (l) => l._checked && !l.display_type
    );
    if (lines.length == 0) lines = [line];

   // Find line with the most routes to start intersection
   let idx = lines.reduce((p, c, i, a) => {
    const currentTotal = (
      c.product_id.value.route_ids.ids.length + 
      c.product_id.value.route_from_categ_ids.ids.length
    );
    const previousTotal = (
      a[p].product_id.value.route_ids.ids.length + 
      a[p].product_id.value.route_from_categ_ids.ids.length
    );
    return currentTotal > previousTotal ? i : p;
  }, 0);

  // Get combined routes from the first line
  let vals = [
    ...lines[idx].product_id.value.route_ids.ids,
    ...lines[idx].product_id.value.route_from_categ_ids.ids
  ];

  // Filter for common routes across all selected lines
  lines.forEach((l) => {
    const lineRoutes = [
      ...l.product_id.value.route_ids.ids,
      ...l.product_id.value.route_from_categ_ids.ids
    ];
    vals = vals.filter(x => lineRoutes.includes(x));
  });

  // Fetch the actual route records
  this.selectableRoutes = await firstValueFrom(
    this.odooEm.search<StockLocationRoute>(
      new StockLocationRoute(), 
      [["id", "in", vals]]
    )
  );

  // Filter out routes that should be hidden
  this.selectableRoutes = this.selectableRoutes.filter(
    route => !ODOO_IDS.routes_to_hide_ids.includes(route.id)
  );
}

  getInfoMultiple(o) {
    // filter checked lines sith !display_type

    var sl = o.order_line.values?.filter((x) => x._checked && !x.display_type);
    var sums = {};

    sl?.forEach((x) => {
      var k = x.product_id.value?.uom_id?.name;
      if (!sums[k]) sums[k] = 0;
      sums[k] = Number(sums[k] + Number(x.product_uom_qty));
    });

    if (sl?.length < 2) {
      return "";
    }

    var s = sl?.length + " righe";
    for (const [key, value] of Object.entries(sums)) {
      s = s + " - " + parseFloat(Number(value).toFixed(5)) + " " + key;
    }

    if (this.showCosts && sl?.length>0){ //add cost * line quantity for each line
      var total = 0
      sl.forEach(l => {
        total = total + l._line_cost_fetched_data?.cost * l.product_uom_qty
      })
      s = s + " || Costo totale stimato: " + total.toFixed(2) + " €"
    }
    return s;
  }


  async resolveLine(line:SaleOrderLine, order:SaleOrder) {

    let lines = await firstValueFrom(this.odooEm.search<PurchaseOrderLine>(new PurchaseOrderLine(), [['id', 'in',  line._purchaselineids ]]))
    line._purchase_line_values = lines
    // this.hasInlineProduction(line, order)
    //run fetch cost data if flag is on
    
    await this.fetchCostData(line)
 
    line._resolved = true

  }

  hasOpenMoves(s: SaleOrder): boolean {

      if (s.state === 'sale' && s.procurement_group_id && s._resolvedProcurement) {
        // Search for moves that are not in 'cancel', 'done', or 'draft' states
        const moves = s.procurement_group_id.value?.stock_move_ids.values?.filter(
          m => ['cancel', 'done', 'draft'].includes(m.state) === false
        );
        return moves? moves.length > 0 : false;
      }
      return false;
  }

  getPurchasesForLine(line: SaleOrderLine, order: SaleOrder): PurchaseOrderLine[] {
    //if not purchase for line don't show
    if (!line._quantity_arrived) return [];
    // return purchase line
    else return line.purchase_line_ids.values;
  }

  getArrivedDate(line: SaleOrderLine, order: SaleOrder) {
    let moveids = line._purchase_line_values?.map((x) => x.move_ids.ids).flat();
    let ls = order.procurement_group_id.value?.stock_move_ids.values?.filter(
      (m) => {
        return moveids?.includes(m.id);
      }
    );
    return ls;
  }

  //run ctrl cost
  async fetchCostData(line:SaleOrderLine){
  //running to test
  if(!line || !line.id) return
  let ctrl = await this.odooEm.run(830, line.id, "sale.order.line");
  // fill _line_cost_fetched_data of sale order line with the result of the ctrl
  line._line_cost_fetched_data = ctrl.result?.params.data
  }

  getFree(line: SaleOrderLine, order: SaleOrder) {
    let t = 0
    if (order.state == "sale" && !order.procurement_group_id.value && !order._resolvedProcurement || !line.product_id.value ) return 0;
    if (line.product_id.value.detailed_type === "consu") {
      t = 9999 }
    else {
    t = line.product_id.value.qty_available - line.product_id.value.outgoing_qty
    if (t < 0) t = 0;
    }
    return t;
  }

  getReserved(line: SaleOrderLine, order: SaleOrder) {

    //exit if no procurement group or no quantity on this line
    if (!order.procurement_group_id.value || line.product_uom_qty == 0)
      return 0;
    // total reserve
    let r = 0;

    order.procurement_group_id.value?.stock_move_ids.values?.forEach((m) => {
      if (m.product_id.id == line.product_id.id 
        && m.state != "cancel" 
        && m.state != "draft"
        && m.state != "done"
        && m.location_id.id != ODOO_IDS.stock_location_vendor) {
          r = r +  m.reserved_availability
        }
      
    });
    //add the quantity delivered for all lines of the same product in the order
    order.order_line.values.forEach((l) => {
      if (l.product_id.id == line.product_id.id) {
        r = r + l.qty_delivered;
      }
    });

    return r;
  }

// helper to set quantity in packaging units
  getPackagingQuantity(quantity: number, line: SaleOrderLine): string {
    if (!quantity) {
      quantity = 0;
    }
    if (!line.product_packaging_id?.value?.qty) {
      return null;
    }
  
    return Number(
      new Decimal(quantity)
        .div(line.product_packaging_id.value.qty)
        .toFixed(5)
    ).toLocaleString() + ' ' + line.product_packaging_id.name;
  }

  getDeliveryClass(line: SaleOrderLine) {
    if (line.qty_delivered == 0) 
      return "";
    if (line.qty_delivered.toFixed(4) < line.product_uom_qty.toFixed(4)) 
      return "text-warning";
    if (line.qty_delivered.toFixed(4) === line.product_uom_qty.toFixed(4))
      return "text-success";
    if (line.qty_delivered.toFixed(4) > line.product_uom_qty.toFixed(4))
      return "text-danger";
  }
  


  
  getIconsForLine(line: SaleOrderLine, order: SaleOrder): string[] {
    const icons: string[] = [];
    
    // Skip everything if order is cancelled
    if (order.state === 'cancel') return [];
    
  
    // 1. TRUCK (PURCHASE/ARRIVALS) ICON - only for confirmed sales
    if (order.state === 'sale') {
      // For services with purchase lines
      if (line.product_id.value.detailed_type === 'service' && line.purchase_line_ids.ids.length > 0) {
        icons.push('fa fa-cart-circle-check text-success');
      }
      // For products with quantity > 0 and a connected purchase line
      else if (line.product_id.value.detailed_type !== 'service' && line.product_uom_qty > 0) {
        if (line._quantity_arrived === 0 && line._purchaselineids?.length > 0) {
          icons.push('fa fa-cart-circle-check text-danger');
        } else if (line._quantity_arrived > 0 && line.product_uom_qty > line._quantity_arrived) {
          icons.push('fa fa-cart-circle-check text-warning');
        } else if (line._quantity_arrived > 0 && line.product_uom_qty <= line._quantity_arrived) {
          icons.push('fa fa-cart-circle-check text-success');
        }
      }
    }
  
    // 2. LOCK OR WAREHOUSE ICON - never both
    if (['draft', 'sale'].includes(order.state) 
      && line.qty_delivered < line.product_uom_qty
      && line.product_uom_qty > 0
      && line.product_id.value.detailed_type !== 'service') {
   
        if (line.product_id.value.detailed_type === 'consu') {
          // Consumables: lock in sale state, warehouse in draft
          if (order.state === 'sale') {
            icons.push('fa fa-lock text-success');
          } else {
            icons.push('fa fa-warehouse text-success');
          }
        } else {
          
            // Calculate reserved quantities for confirmed orders
            let totalRequested = 0;
            let totalReserved = 0;
  
            order.order_line.values.forEach(orderLine => {
              if (orderLine.product_id.id === line.product_id.id) {
                totalRequested += orderLine.product_uom_qty;
              }
            });
  
            totalReserved = line._reserved_qty;

            if(totalReserved > 0 || line._purchaselineids?.length > 0) {

            // Show lock icon with appropriate color based on reservation
            if (Number(totalRequested.toFixed(5)) <= Number(totalReserved.toFixed(5))) {
              icons.push('fa fa-lock text-success');
            } else if (Number(totalRequested.toFixed(5)) > Number(totalReserved.toFixed(5)) && totalReserved > 0) {
              icons.push('fa fa-lock text-warning');
            } else {
              icons.push('fa fa-lock text-danger');
            }


          } else {
            // Show warehouse icon
            let totalRequired = 0;
            order.order_line.values.forEach(orderLine => {
              if (orderLine.product_id.id === line.product_id.id) {
                totalRequired += orderLine.product_uom_qty - orderLine.qty_delivered;
              }
            });

            let  availableQty = line.product_id.value.qty_available - line.product_id.value.outgoing_qty + (order.state === 'sale' ? totalRequired : 0)

            if (availableQty < 0) availableQty = 0;

  
            if (Number(totalRequired.toFixed(5)) <= Number(availableQty.toFixed(5))) {
              icons.push('fa fa-warehouse text-success');
            } else if (Number(totalRequired.toFixed(5)) > Number(availableQty.toFixed(5)) && availableQty > 0) {
              icons.push('fa fa-warehouse text-warning');
            } else {
              icons.push('fa fa-warehouse text-danger');
            }
          }
        
      }
    }
  
    // 3. DELIVERY ICON (only for confirmed sales)
    if (order.state === 'sale' 
      && line.product_uom_qty > 0
      && line.product_id.value.detailed_type !== 'service') {
      const delivered = Number(line.qty_delivered.toFixed(5));
      const quantity = Number(line.product_uom_qty.toFixed(5));
  
      if (delivered === 0) {
        icons.push('fa fa-truck-fast text-danger');
      } else if (delivered > 0 && delivered < quantity) {
        icons.push('fa fa-truck-fast text-warning');
      } else if (delivered >= quantity || line.qty_to_deliver === 0) {
        icons.push('fa fa-truck-fast text-success');
      }
    }
  
    return icons;
  }


  async updateOrder(order: SaleOrder, val: string, field, isNumber = false) {
    this.loading = true;
    let j = {};
    if (isNumber) j[field] = Number(val);
    else j[field] = val;
    await firstValueFrom(this.odooEm.update<SaleOrder>(order, j));

    if (field == "pricelist_id") {
      if (confirm("vuoi aggiornare i prezzi ?"))
        await this.odooEm.check(
          this.odooEm.call2(order.ODOO_MODEL, "action_update_prices", [
            order.id,
          ])
        );
    }
    order[field] = val;

    if (field == "commitment_date" || field == "pricelist_id") {
      await this.resolveSale(order);
    }

    this.loading = false;
  }

  async updateLine(order: SaleOrder, line: SaleOrderLine, fieldName: string, value) {
    
    this.loading = true;
    const oldVal = Number(line[fieldName]).toString();

    //standard controls to avoid overriding values when not possible
    this.controlLine(order, line, fieldName, value, oldVal)

    //procedure to update moves when reducing quantity -- this is needed when there are connected purchase orders otherwise odoo creates returns
    if ((order.state == "sale" && order.purchase_order_count > 0 && fieldName == "product_uom_qty" && value < line.product_uom_qty) ||
      (order.state == "sale" && order.purchase_order_count > 0 && fieldName == "product_packaging_qty" && value < line.product_packaging_qty))  {
    this.overrideMovesQuantities(order, line, fieldName, value) }


    // QUI INIZIA LA MODIFICA DELLA LINE

    if (fieldName == "product_packaging_qty") { //UPDATE PACKAGING QUANTITY. KEEP PRICE UNIT AND DISCOUNT
    //DISATTIVATO PER ORA VEDI SE SI LAMENTANO CHE LO RIATTIVIAMO: SPECIAL HANDLE FOR PACKAGING QUANTITY
      // var r = await firstValueFrom(
      //   this.odooEm.search<ProductPackaging>(new ProductPackaging(), [
      //     ["id", "in", line.product_id.value.packaging_ids.ids],
      //   ])
      // );
      // var netti = r.find((x) => x.name.includes("netti"));
      // var pezzo = r.find((x) => x.name.toLowerCase() == "pz");

      // if (netti && pezzo) {
      //   // var pzlor = (value * netti.qty) / pezzo.qty
      //   line.product_uom_qty =
      //     Number(value) * line.product_packaging_id.value.qty;
      //   line.product_packaging_qty = Number(value);
      // } else if (pezzo && line.product_packaging_id.name == "Pz") {
      //   line.product_uom_qty = Number(value * pezzo.qty);
      //   line.product_packaging_qty = value;

      // } else {
      //   line.product_uom_qty =
      //     Number(value) * line.product_packaging_id.value.qty;
      //   line.product_packaging_qty = Number(value);
      // }

      line.product_uom_qty = Number(value) * line.product_packaging_id.value.qty;
      line.product_packaging_qty = Number(value);

      var n = {};
      n["price_unit"] = line.price_unit;
      n["discount"] = line.discount;
      n["product_packaging_qty"] = line.product_packaging_qty;
      n["product_uom_qty"] = line.product_uom_qty;
      n["product_packaging_id"] = line.product_packaging_id?.id;
    } 
    else if (fieldName == "discount") { //UPDATE DISCOUNT. KEEP PRICE UNIT
      var n = {};
      n["price_unit"] = line.price_unit;
      n[fieldName] = Number(value);
    } else if (fieldName == "price_unit") { //UPDATE PRICE UNIT. KEEP DISCOUNT
      var n = {};
      n["price_unit"] = line.price_unit;
      n["discount"] = line.discount;
      n[fieldName] = Number(value);
    } else {  //UPDATE OTHER. KEEP DISCOUNT AND PRICE UNIT
      var n = {};
      if (typeof line[fieldName] === "number") n[fieldName] = Number(value);
      else n[fieldName] = value;
      n["discount"] = line.discount;
      n["price_unit"] = line.price_unit;
      n["product_packaging_id"] = line.product_packaging_id.id;
    }

    await firstValueFrom(this.odooEm.update<SaleOrderLine>(line, n));

    // refresh the needed computed on line and reassign all values to the line to keep ui updated
    
    var fresh = (await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(), [["id", "=", line.id]]).toPromise())[0];
    await this.reassignLineValues(order, line, fresh)

    // silently refresh total amount
    this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", order.id]]).subscribe((x) => {
        order.amount_untaxed = x[0].amount_untaxed;
      });

    this.loading = false;
  }

  async controlLine(order: SaleOrder, line: SaleOrderLine, fieldName: string, value, oldVal) {
    // CONTROLLO 1: BLOCCA MODIFICA QUANTITA' SE I PRODOTTI SONO GIA' STATI ORDINATI SU MISURA (NO SERVIZI)
    if (order.state == 'sale' &&  line.product_id.value?.detailed_type !== 'service' && line._purchaselineids.length > 0 && (fieldName == 'product_uom_qty' || fieldName == 'product_packaging_qty')) {
      alert("Questo è materiale già ordinato su misura. Contattare responsabie acqusiti per modificare la quantità")
      await this.resolveLine(line, order)
      this.loading = false
      return
    }

    // CONTROLLO 2: BLOCCA RIDUZIONE QUANTITA' SE I PRODOTTI SONO GIA' STATI PREPARATI
    let done = order.procurement_group_id?.value?.stock_move_ids.values.find(
      (m) =>  m.quantity_done && m.state !== "cancel" &&  m.location_id.id != ODOO_IDS.stock_location_vendor &&  m.product_id.id == line.product_id.id );
    if (
      (order.state == "sale" && done &&
        fieldName == "product_uom_qty" && value < line.product_uom_qty) ||
      (order.state == "sale" && done && 
        fieldName == "product_packaging_qty" && value < line.product_packaging_qty)
    ) {
      line[fieldName] = Number(oldVal);
      this.changeDetectorRef.detectChanges();

      alert(
        "Non è possibile ridurre la quantità confermata di " + line.product_id.name + " perchè è già stato preparato. Contatta responsabile magazzino" );
      this.loading = false;
      return
    }

    // CONTRLLO 3: SELEZIONA PERCORSO
    if (order.state == 'sale' && !line.route_id.id && !line.display_type) {
      alert("Seleziona un percorso per " + line.product_id.name)
      return
     } 
    }

  async overrideMovesQuantities(order: SaleOrder, line: SaleOrderLine, fieldName: string, value) {
    //cycle all pickings and update the related move
    let moves = order.procurement_group_id.value?.stock_move_ids.values;
    //filter out incoming moves, draft, cancelled and completed that have the same product_id
    moves = moves.filter(
      (m) =>
        m.location_id.id != ODOO_IDS.stock_location_vendor &&
        m.state != "cancel" &&
        m.state != "draft" &&
        m.state != "done" &&
        m.product_id.id == line.product_id.id
    );

    //calculate the new quantity to deliver, which is this line new quantity and all the other lines with the same product_id
    let newQty = 0;
    if (fieldName == "product_packaging_qty") {
      newQty = Number(value) * line.product_packaging_id.value.qty;
    }
    else {
      newQty =  Number(value);
    }
    //now add any other line with the same product_id
    order.order_line.values.forEach((l) => {
      if (l.product_id.id == line.product_id.id && l.id != line.id) {
        newQty = newQty + l.product_uom_qty;
      }
    });

    //cycle all moves and update the quantity to deliver
    await firstValueFrom(this.odooEm.updateMulti<StockMove>(new StockMove(), moves, { product_uom_qty: newQty }));
  }

  async reassignLineValues(order: SaleOrder, line: SaleOrderLine, fresh) {

    line.product_uom_qty = fresh.product_uom_qty;
    line.product_packaging_qty = fresh.product_packaging_qty;
    await firstValueFrom(this.odooEm.resolveSingle(new ProductPackaging(), fresh.product_packaging_id));
    line.product_packaging_id.id = fresh.product_packaging_id.id;
    line.product_packaging_id.name = fresh.product_packaging_id.name;
    line.product_packaging_id.value = fresh.product_packaging_id.value;
    line.price_unit = fresh.price_unit;
    line.discount = fresh.discount;
    line.forecast_expected_date = fresh.forecast_expected_date;
    line.free_qty_today = fresh.free_qty_today;
    line.qty_available_today = fresh.qty_available_today;
    line.price_subtotal = fresh.price_subtotal;
  }

  isLineDoubleUM(line) {
    return true;
  }

  isLengthEditable(line) {
    return line.product_id.value.product_tag_ids?.ids.includes(
      ODOO_IDS.length_editable_tag_id
    );
  }

  isWidthEditable(line) {
    return line.product_id.value.product_tag_ids?.ids.includes(
      ODOO_IDS.width_editable_tag_id
    );
  }

  isHeightEditable(line) {
    return line.product_id.value.product_tag_ids?.ids.includes(
      ODOO_IDS.height_editable_tag_id
    );
  }

  isAttributeEditable(line: SaleOrderLine, att: number) {
    return line.product_id.value?.product_template_attribute_value_ids.values?.find(
      (ptav) => ptav.attribute_id.id == 33
    );
  }

  isLineEditable(line: SaleOrderLine) {
    return line.product_id.value?.product_template_attribute_value_ids.values?.find(
      (ptav) => ptav.attribute_id.id == 33
    );
  }

  getDescriptive(line: SaleOrderLine) {
    if (!line.product_id.value) return;

    if (
      line.product_uom_qty == 0 ||
      !line.product_id.value.packaging_ids.values
    )
      // dont want to show
      return;

    var ps = line.product_id.value.packaging_ids.values
      .slice()
      .sort((a, b) => b.qty - a.qty);
    var q = line.product_uom_qty;

    var d = "";

    ps = ps.filter((x) => !x.name.includes("netti") && x.sales == true);

    var totale = new Decimal(q);

    ps.forEach((p, i) => {
      if (!p.sales || p.name.includes("netti")) return;

      if (totale.toNumber() <= 0) return;

      let quo;
      //if last
      if (i == ps.length - 1) quo = totale.div(p.qty);
      else quo = totale.divToInt(p.qty);
      totale = totale.minus(quo.mul(p.qty));

      if (quo.toNumber() > 0)
        d =
          d +
          "" +
          Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") +
          " " +
          p.name +
          "\n";
    });

    return d;
  }

  async updateLine2(order, line, field, val) {
    await this.updateLine(order, line, field, val);
  }

  copy(s) {
    navigator.clipboard.writeText(s);
  }

  async updateVariant3(
    order: SaleOrder,
    line: SaleOrderLine,
    e,
    attributeName
  ) {
    this.loading = true;
    var l =
      line.product_id.value.product_template_attribute_value_ids.values.find(
        (v) => v.attribute_id.name.startsWith(attributeName)
      );
    var li =
      line.product_id.value.product_template_attribute_value_ids.values.indexOf(
        l
      );
    await this.odooEm
      .resolveSingle(
        new ProductTemplate(),
        line.product_id.value.product_tmpl_id
      )
      .toPromise();

    var tmpl = line.product_id.value.product_tmpl_id.value;

    let v;
    let oldv: ProductAttributeValue[] = await this.odooEm
      .search<ProductAttributeValue>(new ProductAttributeValue(), [
        ["name", "=", e],
        ["attribute_id", "=", l.attribute_id.id],
      ])
      .toPromise();

    if (oldv.length > 0) v = oldv[0];
    else {
      // new attribute value
      v = await this.odooEm
        .create<ProductAttributeValue>(new ProductAttributeValue(), {
          name: e,
          attribute_id: l.attribute_id.id,
        })
        .toPromise();
    }

    await this.odooEm.resolve(tmpl.attribute_line_ids).toPromise();
    var tal = tmpl.attribute_line_ids.values[li];
    var ids = tal.value_ids.ids;
    ids.splice(ids.length, 0, v.id);

    // faster than
    // PATCH ODOO
    // await this.odooEm.update(tmpl, {
    //   attribute_line_ids:[[1, tal.id, {value_ids: [[4, v.id]]}]]
    //     //  attribute_line_ids:[[1, tal.id, {value_ids: [[6, false, ids]]}]]
    // }).toPromise()
    await this.odooEm.update(tal, { value_ids: [[4, v.id]] }).toPromise();

    // create product template
    await firstValueFrom(
      this.odooEm.create(new ProductTemplateAttributeValue(), {
        product_attribute_value_id: v.id,
        attribute_line_id: tal.id,
        ptav_active: true,
      })
    );

    let ptav: ProductTemplateAttributeValue[] = await this.odooEm
      .search<ProductTemplateAttributeValue>(
        new ProductTemplateAttributeValue(),
        [
          ["attribute_id", "=", l.attribute_id.id],
          ["product_attribute_value_id", "=", v.id],
          ["product_tmpl_id", "=", l.product_tmpl_id.id],
          ["ptav_active", "=", true],
        ]
      )
      .toPromise();

    var ids =
      line.product_id.value.product_template_attribute_value_ids.values.map(
        (v) => v.id
      );
    ids[li] = ptav[0].id;

    var r: any = await this.odooEm.odoorpcService.sendRequest(
      "/api/sale/create_product_variant",
      {
        product_template_attribute_value_ids: JSON.stringify(ids),
        product_template_id: line.product_id.value.product_tmpl_id.id,
      }
    );

    // todo PEZZO PACK
    await this.odooEm.run(666, r.result, "product.product");

    var freshP = (
      await this.odooEm
        .search<Product>(new Product(), [["id", "=", r.result]])
        .toPromise()
    )[0];
    if (freshP) {
      var u = await this.odooEm
        .update<SaleOrderLine>(line, {
          product_id: freshP.id,
          product_uom_qty: line.product_uom_qty,
          product_packaging_id: freshP.packaging_ids.ids[0],
        })
        .toPromise();

      // force refresh for the line
      var freshline = (
        await this.odooEm
          .search<SaleOrderLine>(new SaleOrderLine(), [["id", "=", line.id]])
          .toPromise()
      )[0];
      // // dirty selective update
      await this.odooEm
        .resolve(freshP.product_template_attribute_value_ids)
        .toPromise();
      await this.odooEm.resolve(freshP.packaging_ids).toPromise();

      (line.product_id as any).value = freshP;
      line.product_id.id = freshP.id;
      line.product_id.name = freshP.display_name;
      line.product_uom_qty = freshline.product_uom_qty;
      line.product_packaging_qty = freshline.product_packaging_qty;

      //we have to set the new packaging ids to the line otherwise it keeps the old ones
      line.product_packaging_id.id = freshP.packaging_ids.ids[0];
      line.product_packaging_id.name = freshP.packaging_ids.values[0].name;
      line.product_packaging_id.value = freshP.packaging_ids.values[0];

      this.loading = false;
    } else {
      alert("Errore nella creazione della variante");
    }
  }

  innerText(s: string) {
    if (s) return s.replace("<p>", "").replace("</p>", "");
  }

  async replenish($event, order: SaleOrder, p: Product, qty) {
    if (!confirm("Confermi retifica della quantità a magazzino ?")) {
      $event.stopPropagation();
      return;
    }

    let q = await firstValueFrom(
      this.odooEm.create<StockQuant>(new StockQuant(), {
        product_id: p.id,
        location_id: ODOO_IDS.stock_location_stock,
        inventory_quantity_set: true,
        inventory_quantity:
          this.replenishQty * this.replenishPackaging.qty - p.qty_available,
      })
    );

    await this.odooEm.call2("stock.quant", "action_apply_inventory", [[q.id]]);
    await this.resolveSale(order);
  }

  getMoves(line: SaleOrderLine) {
    if (!line || !line.move_ids.values) return [];

    // todo - getting just the last one ?
    var next = line.move_ids.values[line.move_ids.values.length - 1];
    var moves = [];
   
    return this.moves.filter((m) => {
      return m.product_id.id == line.product_id.id;
    });
  }

  getOrderStateName(s): string {
    switch (s) {
      case "draft":
        return "Bozza";
      case "sale":
        return "Confermato";
      case "cancel":
        return "Annulato";
    }
  }

  async openPicking(p: StockPicking) {
    this.detailPicking = p;
  }

  getNewNote() {
    var s = new SaleOrderLine();
    s.display_type = "line_note";
    s.name = "Nuova nota";
    return s;
  }

  getNewSection() {
    var s = new SaleOrderLine();
    s.display_type = "line_section";
    s.name = "Nuova sezione";
    return s;
  }

  async print(s: SaleOrder) {
    this.activeSale = s;

    var lines = [];
    this.relatedSales.forEach((o) => {
      if (o.order_line && o.order_line.values?.length > 0) {
        // if (o._checked)
        //   lines = lines.concat(o.order_line.values)
        lines = lines.concat(o.order_line.values.filter((l) => l._checked));
      }
    });

    if (lines.length == 0 && !this.activeSale?.order_line.values) {
      this.activeSale = await this.resolveSale(this.activeSale);
      lines = this.activeSale?.order_line.values;
    } else if (lines.length == 0 && this.activeSale?.order_line.values) {
      lines = this.activeSale?.order_line.values;
    }

    this.orderLinesToPrint = lines;

    this.isPrinting = true;
  }

  round(val) {
    return val;
  }
}

