import { Component, OnInit} from '@angular/core';
import { Partner } from '../../models/partner';
import { User } from '../../models/user.model';
import { MailActivity } from '../../models/mail.message';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import {MailNotification } from '../../models/mail.notification';
import { MailMessage } from '../../models/mail.message';
import { SaleOrderFlash } from 'src/app/models/sale-order.model';
import { Lead } from 'src/app/models/crm.lead.model';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { StockPicking } from 'src/app/models/stock-picking';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { AccountMove } from 'src/app/models/account-move.model';
import { Project, Task } from 'src/app/models/project.model';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html'
})
export class CalendarWidgetComponent implements OnInit {

  activities: MailActivity[] = [];
  notifications: MailNotification[] = [];
  loading = false;
  userId: string;
  user: User;
  activeTab = 'activities'; // Default tab
  showUnreadOnly = true;
  activitiesLength: number;
  unreadNotificationsLength: number;
  activitiesloaded = false;
  notificationsloaded = false;
  loaded = false; //numbers loaded
  isOpen = false;
  isLate:boolean = false;
  allUsers: User[] = [];


  //models for the notifications and activities
  saleOrders: SaleOrderFlash[] = [];
  partners: Partner[] = [];
  leads: Lead[] = [];
  purchases: PurchaseOrder[] = [];
  pickings: StockPicking[] = [];
  productTemplates: ProductTemplate[] = [];
  invoices: AccountMove[] = [];
  projects: Project[] = [];
  tasks: Task[] = [];


  constructor(private odooEm: OdooEntityManager) {}

  async ngOnInit() {
      await this.loadUserInfo();
      //set an interval to loadnumbers every 30 seconds, after 5 seconds from the start
      setTimeout(async () => {
        this.loadNumbers();
      }, 5000);
  }

  //update info every xx seconds 
  async ngAfterViewInit() {
      setInterval(async () => {  await this.loadNumbers();  }
      , 30000);
  }

  async loadNumbers(): Promise<void> {
    if (this.isOpen) return;
    
    try {
      const [notifications, activities] = await Promise.all([
        //notifications with id descending order. id DESC
        firstValueFrom(this.odooEm.search<MailNotification>(
          new MailNotification(),
          [['notification_type', '=', 'inbox'], ['res_partner_id', '=', this.user?.partner_id?.id]],
          100,
          "",
          'id DESC'
        )),
        firstValueFrom(this.odooEm.search<MailActivity>(
          new MailActivity(),
          [['user_id', '=', this.user?.id], ['activity_type_id', '!=', 6]],
          100
        ))
  
      ]);

      this.notifications = notifications;
      this.activities = activities;
      
      // Calculate metrics
      const fiveDaysFromNow = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
      this.unreadNotificationsLength = notifications.filter(n => !n.is_read).length;
      this.activitiesLength = activities.filter(a => 
        new Date(a.date_deadline).getTime() < fiveDaysFromNow
      ).length;
      this.isLate = activities.some(a => a.state === 'overdue');
      this.loaded = true;
    } catch (error) {
      console.error('Error loading numbers:', error);
    }
  }

  async toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.loadDetails();
    }
  }


  async loadDetails() {
    // await this.loadNumbers()
    await this.fetchModelsData();
    this.loadUsers();
     this.loadActivities();
     this.loadNotifications();

  }


  async loadUserInfo() {
    const result: any = await this.odooEm.odoorpcService.getSessionInfo();
      this.userId = result.result.user_id[0];
      //fetch all userse
      let usersFound = await firstValueFrom(this.odooEm.search<User>(new User(), [['id', '=', this.userId]]));
      this.user = usersFound[0]
  }

  async loadActivities() {
    this.activitiesloaded = false;
    this.loading = true;
    try {
 
    this.loadActivitiesDescription(this.activities);
    //   // Sort activities by deadline in ascending order     
   
    this.activities.sort((a, b) => {
      return new Date(a.date_deadline).getTime() - new Date(b.date_deadline).getTime();
    });
    this.activitiesloaded = true;

      this.activitiesLength = this.activities.length;
    } catch (error) {
      console.error('Error loading activities:', error);
    } finally {
      this.loading = false;
    }
  }

  async loadNotifications() {
    this.notificationsloaded = false;
    this.loading = true;
    
    try {
      
      this.loadNotificationDescription(this.notifications);
      this.notificationsloaded = true;
      // Sort notifications by id in descending order
    this.notifications.sort((a, b) => b.id - a.id);
      this.unreadNotificationsLength = this.notifications.filter(n => !n.is_read).length;
    } catch (error) {
      console.error('Error loading notifications:', error);
    } finally {
      this.loading = false;
    }
  }

  //accept activity or notification and return a string to show in the calendar
  getOutput(input: MailActivity | MailNotification): string {
    let output = '';
    if (input instanceof MailActivity) {
      output = this.getResModelName(input.res_model);
    } else if (input instanceof MailNotification) {
      output = this.getResModelName(input.mail_message_id?.value?.model);
    }
    if (output != 'Contatta ML!') {
      //truni descriiption after 20 chars
    output += ' '
    if (input._ga_description.length > 30) {
      output += input._ga_description.substring(0, 30) + '...';
    }
    else {
      output += input._ga_description;
    }
    }
    return output;
  }

  async loadUsers() { //load users for the avatars, onoy if not already loaded
    if (this.allUsers.length > 0) return;
    this.allUsers = await firstValueFrom(this.odooEm.search<User>(new User(), [], 60));
  }

  findUser(name: string) {
    let user = this.allUsers.find(u => u.name === name);
    if (user) {
      return user
    }
  }


    getResModelName(model: string): string {
    let name = model + " -->  contatta ML"
    if (model === 'res.partner') {
      name = 'Contatto |';
    } else if (model === 'sale.order') {
      name = 'Vendita |';
    } else if (model === 'crm.lead') {
      name = 'Commessa |';
    }
    else if (model === 'purchase.order') {
      name = 'Acquisto |';
    }
    else if (model === 'stock.picking') {
      name = 'Trasferimento |';
    }
    else if (model === 'product.template') {
      name = 'Prodotto |';
    }
    else if (model === 'account.move') {
      name = 'Fattura |';
    }
    else if (model === 'project.project') {
      name = 'Board |';
    }
    else if (model === 'project.task') {
      name = 'Board |';
    }  
    return name;
  }

  getFilteredNotifications(): MailNotification[] {
    if (!this.notifications) return [];
    
    return this.notifications
      .filter(notification => !this.showUnreadOnly || !notification.is_read);
      // No need to sort here as the array is already sorted
  }

  async fetchModelsData() {
    //first we fetch all the models names form activities and notifications : activity.res_model and notification.mail_message_id.value.model

    //resolve mail message references
    await firstValueFrom(
      this.odooEm.resolveArrayOfSingle(
        new MailMessage(), 
        this.notifications, 
        'mail_message_id'
      )
    );

    //store all ids needed for a single search
    let saleOrderIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'sale.order').map(n => n.mail_message_id.value.res_id);
    saleOrderIds.push(...this.activities.filter(a => a.res_model === 'sale.order').map(a => a.res_id));
    let partnerIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'res.partner').map(n => n.mail_message_id.value.res_id);
    partnerIds.push(...this.activities.filter(a => a.res_model === 'res.partner').map(a => a.res_id));
    let leadIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'crm.lead').map(n => n.mail_message_id.value.res_id);
    leadIds.push(...this.activities.filter(a => a.res_model === 'crm.lead').map(a => a.res_id));
    let purchaseIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'purchase.order').map(n => n.mail_message_id.value.res_id);
    purchaseIds.push(...this.activities.filter(a => a.res_model === 'purchase.order').map(a => a.res_id));
    let pickingIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'stock.picking').map(n => n.mail_message_id.value.res_id);
    pickingIds.push(...this.activities.filter(a => a.res_model === 'stock.picking').map(a => a.res_id));
    let productIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'product.template').map(n => n.mail_message_id.value.res_id);
    productIds.push(...this.activities.filter(a => a.res_model === 'product.template').map(a => a.res_id));
    let invoiceIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'account.move').map(n => n.mail_message_id.value.res_id);
    invoiceIds.push(...this.activities.filter(a => a.res_model === 'account.move').map(a => a.res_id));
    let projectIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'project.project').map(n => n.mail_message_id.value.res_id);
    projectIds.push(...this.activities.filter(a => a.res_model === 'project.project').map(a => a.res_id));
    let taskIds = this.notifications.filter(n => n.mail_message_id.value?.model === 'project.task').map(n => n.mail_message_id.value.res_id);
    taskIds.push(...this.activities.filter(a => a.res_model === 'project.task').map(a => a.res_id));



    //remove duplicates
    saleOrderIds = [...new Set(saleOrderIds)];
    partnerIds = [...new Set(partnerIds)];
    leadIds = [...new Set(leadIds)];
    purchaseIds = [...new Set(purchaseIds)];
    pickingIds = [...new Set(pickingIds)];
    productIds = [...new Set(productIds)];
    invoiceIds = [...new Set(invoiceIds)];
    projectIds = [...new Set(projectIds)];
    taskIds = [...new Set(taskIds)];
 
    //from each array, remove already existing ids in the models arrays, this allows not to load new data if already present
    saleOrderIds = saleOrderIds.filter(id => !this.saleOrders.some(so => so.id === id));
    partnerIds = partnerIds.filter(id => !this.partners.some(p => p.id === id));    
    invoiceIds = invoiceIds.filter(id => !this.invoices.some(i => i.id === id));
    leadIds = leadIds.filter(id => !this.leads.some(l => l.id === id));
    purchaseIds = purchaseIds.filter(id => !this.purchases.some(p => p.id === id));
    pickingIds = pickingIds.filter(id => !this.pickings.some(p => p.id === id));
    productIds = productIds.filter(id => !this.productTemplates.some(p => p.id === id));
    projectIds = projectIds.filter(id => !this.productTemplates.some(p => p.id === id));
    taskIds = taskIds.filter(id => !this.productTemplates.some(p => p.id === id));

    //run the searches only if length is > 0
    let limit = 300;
    if (saleOrderIds.length > 0) {
    this.saleOrders = await firstValueFrom(this.odooEm.search<SaleOrderFlash>(new SaleOrderFlash(), [['id', 'in', saleOrderIds]], limit));    }
    if (partnerIds.length > 0) {
    this.partners = await firstValueFrom(this.odooEm.search<Partner>(new Partner(), [['id', 'in', partnerIds]], limit));    }
     let leadNames =[]
    if (invoiceIds.length > 0) {   
    this.invoices = await firstValueFrom(this.odooEm.search<AccountMove>(new AccountMove(), [['id', 'in', invoiceIds]], limit));    
    // store leadnames from invoices origins. this is needed to fetch lead data
    leadNames = this.invoices.map(i => i.invoice_origin) }
    //  search for leads with name in leadNames or id in leadIds
    if (leadIds.length > 0) { 
    this.leads = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['id', 'in', leadIds]], limit));  }
    if (leadNames.length > 0) {
    let ll = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['tracking_code', 'in', leadNames]], limit));
    this.leads.push(...ll);
    }  
    if (purchaseIds.length > 0) {
    this.purchases = await firstValueFrom(this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [['id', 'in', purchaseIds]], limit));    }
    if (pickingIds.length > 0) {
    this.pickings = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['id', 'in', pickingIds]], limit));    }
    if (productIds.length > 0) {
    this.productTemplates = await firstValueFrom(this.odooEm.search<ProductTemplate>(new ProductTemplate(), [['id', 'in', productIds]], limit));    }
    if (projectIds.length > 0) {
    this.projects = await firstValueFrom(this.odooEm.search<Project>(new Project(), [['id', 'in', projectIds]], limit));    }
    if (taskIds.length > 0) {
    this.tasks = await firstValueFrom(this.odooEm.search<Task>(new Task(), [['id', 'in', taskIds]], limit));    }
  }

 

  async loadActivitiesDescription(activities: MailActivity[]) {
      activities.forEach(a => {
        if (a.res_model === 'sale.order') {
          let saleOrder = this.saleOrders.find(so => so.id === a.res_id);
          if(!saleOrder) return;
          a._ga_description = saleOrder.name + ' - ' + saleOrder.partner_id.name;
          if (saleOrder.opportunity_id.id) {
            a._ga_linkToObject = 'leads/'+saleOrder.opportunity_id.id+'/sale/'+saleOrder.id;
          }
          else {
            a._ga_linkToObject = '/immediate-sale/s/'+saleOrder.id;
          }
        } else if (a.res_model === 'res.partner') {
          let partner = this.partners.find(p => p.id === a.res_id);
          if (!partner) return;
          a._ga_description = partner.name;
          a._ga_linkToObject = '/contact/'+partner.id;
        } else if (a.res_model === 'crm.lead') {
          let lead = this.leads.find(l => l.id === a.res_id);
          if (!lead) return;
          a._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
          a._ga_linkToObject = '/leads/'+lead.id;
        }
        else if (a.res_model === 'purchase.order') {
          let purchase = this.purchases.find(p => p.id === a.res_id);
          if (!purchase) return;
          a._ga_description = purchase.name + ' - ' + purchase.partner_id.name;
          a._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+purchase.id+'&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form';
        }
        else if (a.res_model === 'stock.picking') {
          let picking = this.pickings.find(p => p.id === a.res_id);
          if (!picking) return;
          a._ga_description = picking.name + ' - ' + picking.partner_id.name;
          a._ga_linkToObject = '/pickings/internal/'+picking.id;
        }
        else if (a.res_model === 'product.template') {
          let product = this.productTemplates.find(p => p.id === a.res_id);
          if (!product) return;
          a._ga_description = product.display_name;
          a._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+product.id+'&cids=1&menu_id=223&action=392&model=product.template&view_type=form'
        }
        else if (a.res_model === 'account.move') {
          let invoice = this.invoices.find(i => i.id === a.res_id);
          let lead = this.leads.find(l => l.tracking_code === invoice.invoice_origin);
          if (!lead) return;
          a._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
          a._ga_linkToObject = '/leads/'+lead.id;
        }
        else if (a.res_model === 'project.project') {
          let project = this.projects.find(p => p.id === a.res_id);
          if (!project) return;
          a._ga_description = project.display_name;
          a._ga_linkToObject = 'https://o3.galimberti.eu/web#action=635&active_id='+project.id+'&model=project.task&view_type=kanban&cids=1&menu_id=422'
        }
        else if (a.res_model === 'project.task') {
          let task = this.tasks.find(t => t.id === a.res_id);
          if (!task) return;
          a._ga_description = task.project_id.name + ': ' + task.name;
          a._ga_linkToObject = 'https://o3.galimberti.eu/web#action=635&active_id='+task.project_id.id+'&model=project.task&view_type=kanban&cids=1&menu_id=422'
        }
        
      });
  }

  async loadNotificationDescription(notifications: MailNotification[]) {
    console.log('notifications:', notifications);
    //cicle through notifications to compile  _ga_linkToObject
    notifications.forEach(n => {
      if (n.mail_message_id.value?.model === 'sale.order') {
        let saleOrder = this.saleOrders.find(so => so.id === n.mail_message_id.value.res_id);
        if (!saleOrder) return;
        n._ga_description = saleOrder.name + ' - ' + saleOrder.partner_id.name;
        if (saleOrder.opportunity_id.id) {
          n._ga_linkToObject = 'leads/'+saleOrder.opportunity_id.id+'/sale/'+saleOrder.id;          
        }
        else {
          n._ga_linkToObject = '/immediate-sale/s/'+saleOrder.id;
        }
      } else if (n.mail_message_id.value?.model === 'res.partner') {
        let partner = this.partners.find(p => p.id === n.mail_message_id.value.res_id);
        if (!partner) return;
        n._ga_description = partner.name;
        n._ga_linkToObject = '/contact/'+partner.id;
      } else if (n.mail_message_id.value?.model === 'crm.lead') {        
        let lead = this.leads.find(l => l.id === n.mail_message_id.value.res_id);
        if (!lead) return
        n._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
        n._ga_linkToObject = '/leads/'+lead.id;
      } else if (n.mail_message_id.value?.model === 'purchase.order') {
        let purchase = this.purchases.find(p => p.id === n.mail_message_id.value.res_id);
        if (!purchase) return;
        n._ga_description = purchase.name + ' - ' + purchase.partner_id.name;
        n._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+purchase.id+'&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form';
      } else if (n.mail_message_id.value?.model === 'stock.picking') {
        let picking = this.pickings.find(p => p.id === n.mail_message_id.value.res_id);
        if (!picking) return;
        n._ga_description = picking.name + ' - ' + picking.partner_id.name;
        n._ga_linkToObject = '/pickings/internal/'+picking.id;
      }
      else if (n.mail_message_id.value?.model === 'product.template') {
        let product = this.productTemplates.find(p => p.id === n.mail_message_id.value.res_id);
        if (!product) return;
        n._ga_description = product.display_name;
        n._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+product.id+'&cids=1&menu_id=223&action=392&model=product.template&view_type=form'
      }
      else if (n.mail_message_id.value?.model === 'account.move') {
        let invoice = this.invoices.find(i => i.id === n.mail_message_id.value.res_id);
        let lead = this.leads.find(l => l.tracking_code === invoice.invoice_origin);
        if (!lead) return;
        n._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
        n._ga_linkToObject = '/leads/'+lead.id;
      }
      else if (n.mail_message_id.value?.model === 'project.project') {
        let project = this.projects.find(p => p.id === n.mail_message_id.value.res_id);
        if (!project) return;
        n._ga_description = project.display_name;
        n._ga_linkToObject = 'https://o3.galimberti.eu/web#action=635&active_id='+project.id+'&model=project.task&view_type=kanban&cids=1&menu_id=422'
      }
      else if (n.mail_message_id.value?.model === 'project.task') {
        let task = this.tasks.find(t => t.id === n.mail_message_id.value.res_id);
        if (!task) return;
        n._ga_description = task.project_id.name + ': ' + task.name;
        n._ga_linkToObject = 'https://o3.galimberti.eu/web#action=635&active_id='+task.project_id.id+'&model=project.task&view_type=kanban&cids=1&menu_id=422'
      }

    });
  }

  async openCalendar(){
    window.open('https://o3.galimberti.eu/web?#cids=1&action=843&model=mail.activity&view_type=calendar', '_blank');
  }

  async markAllAsRead() {
    if(confirm("Sei sicuro di voler segnare tutte le notifiche come lette?")) {
      this.loading = true;
      try {
        const unreadNotifications = this.notifications.filter(n => !n.is_read);

        await this.odooEm.updateMulti<MailNotification>(new MailNotification(), unreadNotifications, { is_read: true }).toPromise();
  
        this.unreadNotificationsLength = 0;
        // this.emitDataToNavbar();
      } catch(error) {
        console.error('Error marking all as read:', error);
      } finally {
        this.loading = false;
      }
    }
  }
  
  setActiveTab(tab: 'activities' | 'notifications', event?: MouseEvent) {
    event?.stopPropagation();
    this.activeTab = tab;
  }

  async openNotification(n: MailNotification, event?: MouseEvent) {
    event?.stopPropagation();
    if (n._ga_linkToObject) {
      await window.open(n._ga_linkToObject, '_blank');
    }
    this.flagNotification(n);
    
  }

  openActivity(a: MailActivity, event?: MouseEvent) {
    event?.stopPropagation();  
    if (a._ga_linkToObject) {
      window.open(a._ga_linkToObject, '_blank');
    }
  }

  async flagNotification(n: MailNotification, event?: MouseEvent) {
    event?.stopPropagation();  // Add this
    n.is_read = !n.is_read;
   await this.odooEm.update(n, { is_read: n.is_read }).toPromise();
   this.unreadNotificationsLength = this.notifications.filter(n => !n.is_read).length;
  }

//completing and deleting activity is the same shit so,,,,

  async completeActivity(a: MailActivity, event?: MouseEvent) {
    event?.stopPropagation();  // Add this
    if(confirm("Sei sicuro di voler completare l'attività?")) {
      this.loading = true;
      // call action_feedback method on the activity
      await this.odooEm.call2('mail.activity', 'action_feedback', [a.id]);
      this.activities = this.activities.filter(activity => activity.id !== a.id);
      await this.loadActivities();
      this.activitiesLength = this.activities.length;
      this.loading = false;
    }
    // this.emitDataToNavbar();
  }
}