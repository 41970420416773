<app-navbar>
  <div class="d-flex justify-content-between align-items-center w-100">
    <a class="navbar-brand">
      Home
    </a>
    <app-user-info></app-user-info>
  </div>
</app-navbar>

<div class="container-fluid p-4">
  <!-- Anagrafiche e inventario -->
  <h5 class="mb-2 text-muted">Anagrafiche e inventario</h5>
  <div class="row g-3 mb-5">
    <div class="col-4 col-md-3 col-lg-2">
      <a routerLink="/contact" class="text-decoration-none">
        <div class="card h-100 text-center p-3 border shadow-sm">
          <i class="fa-solid fa-address-book text-primary mb-2" style="font-size: 2rem;"></i>
          <div class="text-dark">Contatti</div>
        </div>
      </a>
    </div>
    <div class="col-4 col-md-3 col-lg-2">
      <a routerLink="/catalog" class="text-decoration-none">
        <div class="card h-100 text-center p-3 border shadow-sm">
          <i class="fa-solid fa-tag text-primary mb-2" style="font-size: 2rem;"></i>
          <div class="text-dark">Disponibilità e prezzi</div>
        </div>
      </a>
    </div>
    <div class="col-4 col-md-3 col-lg-2">
      <a routerLink="/packagelist" class="text-decoration-none">
        <div class="card h-100 text-center p-3 border shadow-sm">
          <i class="fa-solid fa-table-list text-primary mb-2" style="font-size: 2rem;"></i>
          <div class="text-dark">Colli e articoli</div>
        </div>
      </a>
    </div>
  </div>

    <!-- Vendite -->
    <h5 class="mb-2 text-muted">Vendite</h5>
    <div class="row g-3 mb-5">
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/leads" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class=" fa-solid fa-shop text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Commesse</div>
          </div>
        </a>
      </div>
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/immediate-sale" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class=" fa-solid fa-square-list text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Vendite</div>
          </div>
        </a>
      </div>
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/flash-sale" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-grid-2-plus text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Scansiona e vendi</div>
          </div>
        </a>
      </div>
    </div>

    <!-- Magazzino e produzione -->
    <h5 class="mb-2 text-muted">Magazzino e produzione</h5>
    <div class="row g-3 mb-5">
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/pickings" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-box-check text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Movimenti di magazzino</div>
          </div>
        </a>
      </div>
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/search" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-barcode-scan text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Scansiona</div>
          </div>
        </a>
      </div>
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/packs" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-boxes-packing text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Operazioni sui pacchi</div>
          </div>
        </a>
      </div>
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/productions" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-conveyor-belt-arm text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Produzioni</div>
          </div>
        </a>
      </div>
    </div>

    <!-- Contabilità -->
    <h5 class="mb-2 text-muted">Contabilità</h5>
    <div class="row g-3 mb-5">
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/delivery-note2" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-truck text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Documenti di trasporto</div>
          </div>
        </a>
      </div>
      <div class="col-4 col-md-3 col-lg-2">
        <a routerLink="/invoice-search" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-file-invoice-dollar text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Fatturazione e SAL</div>
          </div>
        </a>
      </div>
    </div>

    <!-- Progetti e Cantieri -->
    <h5 class="mb-2 text-muted">Progetti e Cantieri</h5>
    <div class="row g-3 mb-5">
      <div class="col-4 col-md-3 col-lg-2">
        <a href="//c2.galimberti.eu" target="_blank" class="text-decoration-none">
          <div class="card h-100 text-center p-3 border shadow-sm">
            <i class="fa-solid fa-image text-primary mb-2" style="font-size: 2rem;"></i>
            <div class="text-dark">Catpro</div>
          </div>
        </a>
      </div>
    </div>

    <!-- cit of the day -->
  <div *ngIf="quoteEnabled">

    <div class="text-center mt-4 text-muted">
      Esplorare lo spazio, comunque è una cosa importante
    </div>
    <div class="text-end mt-4">
      <small class="text-muted">Riccardo Foschi, 12.02.2024 </small>
    </div>
  </div>

    <div class="text-center mt-4">
      <small class="text-muted">Aggiornamento 28.01.25 11:30</small>
    </div>

  </div>