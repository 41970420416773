import { Component, Input, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { SaleOrder } from '../models/sale-order.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { Project, Task } from '../models/project.model';
import * as bootstrap from 'bootstrap';
import { firstValueFrom } from 'rxjs';
import { User } from '../models/user.model';
import { Lead } from '../models/crm.lead.model';
import { CrmLeadPart } from '../models/crm.lead.part.model';

@Component({
  selector: 'app-purchase-request',
  templateUrl: './purchase-request.component.html'
})
export class PurchaseRequestComponent implements OnInit, AfterViewInit {
  @Input() saleOrder: SaleOrder;
  @Input() lead?: Lead;
  @Input() part?: CrmLeadPart

  
  purchaseProject: Project;
  existingTask: Task | null = null;
  formModal: any;
  loading = false;
  
  // Form data
  deadline: string = '';
  description: string = '';
  
  private modalElement: HTMLElement;

  constructor(
    private odooEm: OdooEntityManager,
    private elRef: ElementRef
  ) {}
  
  async ngOnInit() {
    // Load the "Richieste di acquisto" project with id = 9
    let projects = await firstValueFrom(this.odooEm.search<Project>(new Project(), [['id', '=', 9]]));

    this.purchaseProject = projects[0];
    
    // Check for existing task
    const tasks = await firstValueFrom(
      this.odooEm.search<Task>(new Task(), [['origin', '=', this.saleOrder.name]]))
    if (tasks && tasks.length > 0) {
      this.existingTask = tasks[0];

    }
    
  }

   ngAfterViewInit() {
    // Get the modal element
    this.modalElement = this.elRef.nativeElement.querySelector('.modal');
    // Move it to body
    document.body.appendChild(this.modalElement);
    // Initialize Bootstrap modal
    this.formModal = new bootstrap.Modal(this.modalElement, {
      backdrop: 'static',  // Prevents closing when clicking outside
      keyboard: false      // Prevents closing with keyboard
    });
    
    // Add event listener for when modal is fully hidden
    this.modalElement.addEventListener('hidden.bs.modal', () => {
      // Clean up modal if task was created successfully
      if (this.loading === false) {
        this.formModal.dispose();
        document.body.removeChild(this.modalElement);
      }
    });
  }

  openModal() {
    this.formModal.show();
  }

  closeModal() {
    this.formModal.hide();
  }

  async createPurchaseTask() {
    this.loading = true;

    //if no lead, try and fetch it from sale order
    if (!this.lead && this.saleOrder.opportunity_id) {
      await firstValueFrom(this.odooEm.resolveSingle<Lead>(new Lead(), this.saleOrder.opportunity_id));
      this.lead = this.saleOrder.opportunity_id.value;
    }

    try {
      // Build task name with conditional parts
      let taskName = '';
      
      // Add tracking code and sale order name
      if (this.lead?.tracking_code) {
        taskName += `${this.lead.tracking_code} - `;
      }
      taskName += this.saleOrder.name;
      
      // Add lead description if available
      if (this.lead?.name) {
        taskName += ` | ${this.lead.name}`;
      }
      
      // Add part name if available
      if (this.part?.name) {
        taskName += ` (${this.part.name})`;
      }
      
      // Add ga_title if available
      if (this.saleOrder.ga_title) {
        taskName += ` | ${this.saleOrder.ga_title}`;
      }

     // Format description with HTML
     let formattedDescription = '';
      
     // Add user's description if present
     if (this.description) {
       formattedDescription += `<div>${this.description}</div>`;
     }
     
     // Add spacer
     formattedDescription += '<br><br><br>';
     
     // Add clickable link to current page
     formattedDescription += `<div><a href="${window.location.href}" target="_blank">${window.location.href}</a></div>`;

      const taskData = {
        name: taskName,
        project_id: this.purchaseProject.id,
        origin: this.saleOrder.name,
        partner_id: this.saleOrder.partner_id.id,
        date_deadline: this.deadline,
        description: formattedDescription,  // Using the formatted HTML description
        stage_id: 73,
        user_ids: [[6, 0, [18]]]
      };

      console.log('Creating task with data:', taskData, this.lead, this.part, this.saleOrder);


      // Create the task using the data object
      const createdTask = await firstValueFrom(
        this.odooEm.create<Task>(new Task(), taskData)
      );

 // Hide modal before opening new window
 this.formModal?.hide();
// Small delay to ensure modal is hidden
await new Promise(resolve => setTimeout(resolve, 100));

      window.open(`https://o3.galimberti.eu/web#id=${createdTask.id}&cids=1&menu_id=422&action=635&active_id=7&model=project.task&view_type=form`, '_blank');
      this.existingTask = createdTask;

       // Cleanup
       if (this.modalElement && this.modalElement.parentNode) {
        this.formModal.dispose();
        document.body.removeChild(this.modalElement);
      }

    } catch (error) {
      console.error('Error creating purchase task:', error);
      alert('Errore durante la creazione della richiesta di acquisto');
    } finally {
      this.loading = false;
    }
}

ngOnDestroy() {
  if (this.modalElement && this.modalElement.parentNode) {
    this.formModal?.dispose();
    document.body.removeChild(this.modalElement);
  }
}

}