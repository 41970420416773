import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {  ProductWithOnlyVariants } from '../models/product.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import {  firstValueFrom } from 'rxjs';
import { StockQuant } from '../models/stock-quant';
import { ODOO_IDS } from '../models/deal';
import { StockQuantPackage } from '../models/stock-quant-package';
import { IrAttachment } from '../models/mail.message';

@Component({
  selector: 'app-packs-photos',
  templateUrl: './packs-photos.component.html',
  styles: [`
    .dropdown-menu {
      width: auto;
      min-width: 200px;
    }
    .dropdown-menu.expanded {
      width: min(600px, calc(100vw - 100px));
    }
  `]
})

export class PacksPhotosComponent implements OnInit {

  @Input() from: 'product' | 'package'; 
  @Input() singleQuant?: StockQuant;  //if wre are showing only one package (ex package list) we pass the quant
  @Input() productWithQuants: ProductWithOnlyVariants;

   attachments: boolean = false;
   messages: boolean = false;
   loaded: boolean = false;


  public QuantsToShow: StockQuant[] = [];

  constructor(
    private odooEm: OdooEntityManager, 
    private cdr: ChangeDetectorRef //needed cause order inventory mpasses data asynchrounously
  ) {}

async ngOnInit() {

  console.log("PACKAGES APP RECEIVED THIS DATA: ", this.from, this.singleQuant, this.productWithQuants);
  this.loaded = false;

  await this.getValidPackages();
}


async getValidPackages() { 
  if (this.from === 'package') { 
    if (this.singleQuant.package_id.id) { 
        this.singleQuant._productQuantity = this.singleQuant.available_quantity
      };
      this.QuantsToShow.push( this.singleQuant);
    }
  else {
    if (this.from === 'product') { 
      this.productWithQuants.stock_quant_ids.values?.forEach((quant: StockQuant) => {
        if (quant.package_id.id 
          && quant.available_quantity > 0
          && !ODOO_IDS.exclude_packs_location_ids.includes(quant.location_id.id)
          && quant.product_id.id === this.productWithQuants.id) {
          quant._productQuantity = quant.available_quantity;
          
          this.QuantsToShow.push(quant);
        }
      });
    }
  }
  this.loaded = true; 
  console.log("PACKAGES TO SHOW: ", this.QuantsToShow);

}

getNumberOfPacks() {
  let q = 0;
  q = this.QuantsToShow.filter(pack => pack.package_id.id).length;
 return q
}

async onClickPack(pack: StockQuantPackage, event: Event) {
  // Prevent the main dropdown from closing
  event.preventDefault();
  event.stopPropagation();
  //find attachments (photos)

    if (pack.message_attachment_count > 0) {
      pack._attachments = await firstValueFrom(
        this.odooEm.search<IrAttachment>(
          new IrAttachment(), 
          [
            ['res_id', '=', pack.id], 
            ['res_model', '=', 'stock.quant.package']
          ]
        )
      );
    this.attachments = true  
    }
    
    //set messages to true if there are messages
      if (pack.message_ids && pack.message_ids.ids.length > 0) {
  await firstValueFrom(this.odooEm.resolve(pack.message_ids));
    this.messages = true;
    if (this.messages) {
      console.log("MESSAGES: ", pack);
    }
  }
    
 
    
  }
}
