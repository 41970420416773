<app-navbar [loading]="loading" backroute=".." class="w-100">
  <!-- Wrap everything in a flex container -->
  <div class="d-flex justify-content-between align-items-center w-100">
    <!-- Left side -->
    <a class="navbar-brand">
      <span>Commesse</span>&nbsp;
    </a>

    <!-- Right side -->
    <div class="dropdown">
      <button class="btn btn-primary text-white dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        Nuovo
      </button>

      <div class="dropdown-menu dropdown-menu-end p-3" style="min-width: 300px;">
        <form>
          <div class="mb-3">
            <label for="sectorSelect" class="form-label fw-bold">Settore</label>
            <select id="sectorSelect" name="area" class="form-select" [(ngModel)]="newDeal.area"
              (change)="filter.next(newDeal.area)">
              <option value="" disabled selected>Seleziona un settore</option>
              <option *ngFor="let a of areas" [ngValue]="a.name">
                {{ a.name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label class="form-label fw-bold">Contatto</label>
            <app-contact-picker2 [mode]="'embedded'" class="embedded overflow-hidden border-0" 
              (onSelect)="onContact($event)"></app-contact-picker2>
          </div>

          <div class="d-grid gap-2">
            <button [disabled]="!newDeal.partner_id.id" class="btn btn-primary" (click)="onCreate()">
              Crea nuova commessa
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-navbar>
<!-- Filters Section -->
<div class="bg-light px-3 py-3 w-100">
  <div class="container-fluid">
    <!-- First Row - Fixed with columns -->
    <div class="row">
      <!-- Settore on the left -->
      <div class="col-md-8">
        <div class="d-flex align-items-center flex-wrap">
          <span class="me-2">Settore:</span>
          <button (click)="filter.next('')"
            [ngClass]="!filter.getValue() ? 'btn-primary text-white' : 'btn-outline-muted'" class="btn ms-2">
            <i class="fa fa-asterisk"></i>
          </button>
          <button *ngFor="let area of areas" (click)="filter.next(area.name)"
            [ngClass]="filter.getValue() == area.name ? getAreaBadgeClass(area.name) : 'btn-outline-muted'"
            class="btn ms-2">
            {{ area.name }}
          </button>
        </div>
      </div>

      <!-- Sort and View controls on the right -->
      <div class="col-md-4">
        <div class="d-flex justify-content-end gap-2 align-items-center">
          <div class="input-group border-light">
            <span class="input-group-text">Ordina per</span>
            <select class="form-select" [(ngModel)]="sortField" (change)="refresh()">
              <option value="tracking_code">Codice</option>
              <option value="partner_name">Cliente</option>
              <option value="expected_revenue">Importo</option>
              <option value="stage_id">Fase</option>
              <option value="write_date">Data modifica</option>
              <option value="create_date">Data creazione</option>
            </select>
            <button class="btn btn-outline-muted text-dark" (click)="toggleSortDirection()">
              <i class="fa-light" [ngClass]="sortAscending ? 'fa-arrow-up-a-z' : 'fa-arrow-down-z-a'"></i>
            </button>
          </div>
          
          <!-- View controls group -->
    <div class="btn-group">
      <button class="btn" [ngClass]="!isTableView ? 'btn-primary text-white' : 'btn-outline-muted'"
        (click)="toggleView()">
        <i class="fa-solid fa-grip-vertical"></i>
      </button>
      <button class="btn" [ngClass]="isTableView ? 'btn-primary text-white' : 'btn-outline-muted'"
        (click)="toggleView()">
        <i class="fa-solid fa-table-list"></i>
      </button>
      <!-- dropdown that opens a password field to open portfolio-->
      <div class="dropdown">
        <button class="btn btn-outline-muted" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa-solid fa-lock"></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <form class="p-3">
            <div class="mb-3">
              <label for="portfolioPassword" class="form-label">Password</label>
              <input    name="portfolioPassword" class="form-control" id="portfolioPassword" [(ngModel)]="portfolioPassword">
            </div>
            <button type="button" class="btn btn-primary" (click)="openPortfolio()">Apri</button>
          </form>
        </div>
      </div>
      

    </div>
  </div>
</div>

    <!-- Second Row -->
    <div class="row mt-3">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center flex-grow-1">
            <span class="me-2">Fase:</span>
            <div class="d-flex flex-wrap">
              <button *ngFor="let stage of stages" 
                      (click)="updateStageFilter(stage.id)" 
                      class="btn ms-2"
                      [ngClass]="isStageSelected(stage.id) ? 'btn-primary text-white' : 'btn-outline-muted'">
                {{ stage.name }}
              </button>
            </div>
          </div>

          <div class="d-flex gap-2">
            <!-- mostra portfolio -->
            <button class="btn me-2 text-nowrap" (click)="toggleInvoices()"
            [ngClass]="showInvoices ? 'bg-primary text-white' : 'bg-muted text-white'">
              <i class="fa-solid fa-eye me-1"></i>Mostra avanzamento
            </button>
            <button class="btn btn-outline-muted" (click)="resetFilters()">
              <i class="fa-solid fa-refresh me-1"></i>Reset filtri
            </button>
            <button class="btn btn-outline-primary" (click)="saveFilters()">
              <i class="fa-solid fa-bookmark me-1"></i>Salva filtri
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Third Row -->
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="input-group">
          <span class="input-group-text">Cerca</span>
          <input type="text" class="form-control" placeholder="Cliente, commessa o numero di ordine"
            [(ngModel)]="searchDealsInput" #searchInput />
        </div>
      </div>

      <div class="col-md-4">
        <div class="input-group">
          <span class="input-group-text">Utente</span>
          <select class="form-select" [(ngModel)]="selectedUser" (change)="refresh()">
            <option [ngValue]="null">Tutti gli utenti</option>
            <option *ngFor="let follower of followers" [ngValue]="follower">
              {{ follower.name }}
            </option>
          </select>
          <!-- my leads button -->
          <button *ngIf="userId" class="btn" (click)="myDeals()" title="solo le mie commesse" 
          [ngClass]="selectedUser?.id === userId ? 'bg-primary text-white' : 'bg-muted text-white'"
          >
            <i class="fa-solid fa-user"></i>
          </button>
        </div>
      </div>

      <div class="col-md-4">
        <div class="d-flex align-items-center">
          <button class="btn me-2 text-nowrap" [ngClass]="justRecents ? 'bg-primary text-white' : 'bg-muted text-white'"
            (click)="toggleRecents()">
            Solo recenti
          </button>
          <div class="input-group">
            <span class="input-group-text">Data creazione</span>
            <input type="date" class="form-control" [(ngModel)]="startDate" (change)="refresh()" />
            <span class="input-group-text">a</span>
            <input type="date" class="form-control" [(ngModel)]="endDate" (change)="refresh()" />
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- cards section -->

<div class="w-100">
  <!-- Table View -->
  <div *ngIf="isTableView" class="vh-100 d-flex flex-column pt-4">
    <div class="table-responsive flex-grow-1">
      <div style="height: calc(100vh - 250px);" class="overflow-auto">
        <table class="table table-hover">
          <thead class="bg-white position-sticky top-0" style="z-index: 4;">
            <tr>
     <!-- Sticky header columns -->
     <th  style="left: 0; min-width: 120px;">Settore</th>
     <th  style="left: 120px; min-width: 100px;">Codice</th>
     <th  style="left: 220px; min-width: 200px;">Cliente</th>
     <!-- Rest of headers -->
              <th>Descrizione</th>
              <!-- <th>Indirizzo</th> -->
              <th>Città</th>
              <th>Fase</th>
              <th>Responsabile</th>              
              <th>Data Creazione</th>
              <th *ngIf="!showInvoices">Importo stimato</th>
              <th class="text-center" *ngIf="showInvoices">Contratti</th>
              <th class="text-center" colspan="2" class="text-center" *ngIf="showInvoices">Fatturato</th>
              <th class="text-center" *ngIf="showInvoices">Residuo</th>
            </tr>
            <!-- grand total -->
            <tr *ngIf="showInvoices" >
              <th colspan="8" ></th>

              <th class="text-center ">
                {{ totalContract | currency:'EUR':'symbol':'1.0-0' }}
              </th>
              <th class="text-center">
                {{ totalInvoiced | currency:'EUR':'symbol':'1.0-0' }}
              </th>
              <th >
              <div class="progress text-center" style="min-width: 80px;">
                <div class="progress-bar bg-success" role="progressbar" [style.width.%]="(totalInvoiced/totalContract ) * 100"
                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                {{ totalInvoiced/totalContract | percent:'1.0-0' }} 
                </div>
              </div>
              </th>
              <th class="text-center">
                {{ totalLeftToInvoice | currency:'EUR':'symbol':'1.0-0' }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="cardsLoaded">
            <ng-container *ngFor="let card of cards">
              <tr *ngIf="isStageSelected(card.stage_id.id)" (click)="redirectDeal(card.id)" style="cursor: pointer;">
      <!-- Sticky body columns -->
      <td class="bg-light position-sticky" style="left: 0; z-index: 1; min-width: 120px;">
        <span class="badge" [ngClass]="getAreaBadgeClass(card.area)">
          {{ card.area }}
        </span>
      </td>
      <td class="bg-light position-sticky" style="left: 120px; z-index: 1; min-width: 100px;">
        {{ card.tracking_code }}
      </td>
      <td class="bg-light position-sticky" style="left: 220px; z-index: 1; min-width: 200px;">
        {{ card.partner_id.name | uppercase }}
      </td>
                <td>{{ card.name }}</td>
                <!-- <td>{{ card.street }}</td> -->
                <td>{{ card.city }}</td>
                <td>{{ getStageName(card) }}</td>
                <td>
                  <span class="badge bg-primary text-white">
                    {{ card.user_id.name }}
                  </span>
                </td>    
                <td >{{ card.create_date | date:'dd/MM/yyyy' }}</td>
                <td class="text-center" *ngIf="!showInvoices">
                  {{ card.expected_revenue | currency:'EUR':'symbol':'1.0-0' }}
                </td>
                <td class="text-center" *ngIf="showInvoices "
                [ngClass]="!card._contractsExist ? 'text-danger' : ''"
                >
                  <span >
                    {{ card._contractTotal | currency:'EUR':'symbol':'1.0-0' }}
                  </span>
                </td>
                <td class="text-center" *ngIf="showInvoices">
                  <span >
                    {{ card._invoicedTotal | currency:'EUR':'symbol':'1.0-0' }}
                  </span>
                </td>
                <td class="text-center" *ngIf="showInvoices ">
                  <div class="progress" style="min-width: 100px;">
                    <div class="progress-bar bg-success" role="progressbar" [style.width.%]="(card._invoicedTotal/card._contractTotal) * 100"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  {{ card._invoicedTotal/card._contractTotal | percent:'1.0-0' }}
                    </div>
                  </div>
                </td>
                <td class="text-center" *ngIf="showInvoices ">
                  {{ card._leftToInvoice | currency:'EUR':'symbol':'1.0-0' }}
                </td>

            
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  
  <!-- Cards View -->
  <div class="w-100 trello-like" *ngIf="!isTableView">
    <ng-container *ngFor="let stage of stages">
      <ul *ngIf="isStageSelected(stage.id)">
        <!-- Fixed Header with stage name and badge for total-->
        <li>
          <div class="d-flex justify-content-between align-items-start">
            <h3 class="mb-0">{{ stage.name }}</h3>
            <div class="text-end">
              <span class="badge bg-primary text-white">{{ stage._total_revenue | currency:'EUR' }}</span>
            </div>
          </div>
        </li>
        <!-- Scrollable Cards Container -->

        <!-- HIDDEN CAUSE TOO MUCH DATA HAS TO BE CHECKED TO PASS A STAGE -->
        <!--  
      [id]="stage.id.toString()"
      [cdkDropListData]="filterCards(stage.id)"
      [cdkDropListConnectedTo]="stageDropIds"
      (cdkDropListDropped)="onCardDrop($event)"> -- -->


        <!-- Cards -->
        <div cdkDropList >
          <!-- droplist needed for styling -->
          <li *ngFor="let card of filterCards(stage.id)" cdkDrag [cdkDragDisabled]="true">

            <!-- Drag Preview -->
            <!-- <div *cdkDragPreview class="bg-white p-3 rounded shadow">
                <h6 class="mb-0">{{ card.tracking_code }} - {{ card.partner_id.name | uppercase }}</h6>
              </div> -->


            <!-- Card Content -->
            <div (click)="redirectDeal(card.id)">
              <div class="d-flex flex-column mx-1">
                <!-- First row -->
                <div class="d-flex justify-content-between align-items-center mb-1 mt-1">
                  <h6 class="mb-0">{{ card.tracking_code }} - {{ card.partner_id.name | uppercase }}</h6>
                  <!-- Bell icon with activities counter -->
                  <div class="position-relative" *ngIf="card.activity_ids.ids?.length > 0" [ngClass]="card._isOverdue ? 
                    'text-danger' : 'text-muted'">
                    <i class="fa-solid fa-lg fa-bell">
                    </i>
                    <span class="badge text-dark position-absolute top-0 start-100 translate-middle">
                      {{ card.activity_ids.values?.length }}
                    </span>
                  </div>
                </div>

                <!-- Second row -->
                <div class="d-flex justify-content-between align-items-center ">
                  <p class="mb-0 text-muted">{{ card.name }} - {{ card.city }}</p>
                </div>

                <!-- add a divider line -->
                <hr class="my-2">

                <!-- Third row -->
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <span class="badge" [ngClass]="getAreaBadgeClass(card.area)">
                    {{ card.area }}
                  </span>
                  <div class="badge bg-warning text-dark" *ngIf="card.expected_revenue">
                    {{ card.expected_revenue | currency:'EUR':'symbol':'1.0-0' }}
                  </div>
                </div>


                <!-- Fourth row - Followers -->
                <div class="d-flex flex-wrap gap-1" *ngIf="card.message_follower_ids.ids.length>0">
                  <span *ngFor="let follower of card.message_follower_ids?.values"
                    class="badge d-flex align-items-center"
  
                    [ngClass]="follower?.name === card.user_id.name ? 'bg-primary text-white' : 'bg-light text-dark'"
                    >
                    {{ follower?.name }}
                  </span>
                </div>
              </div>
            </div>
          </li>

          <!-- Empty List Placeholder HIDDEN CAUSE TOO MUCH DATA HAS TO BE CHECKED TO PASS A STAGE-->
          <!-- <li *ngIf="filterCards(stage.id).length === 0" class="empty-drop-list">
          <div class="text-muted text-center py-3">
            <i class="fa fa-arrow-down opacity-25 fa-2x mb-2"></i><br>
            Trascina qui
          </div>
        </li> -->
        </div>
      </ul>
    </ng-container>
  </div>
</div>

<router-outlet></router-outlet>