import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { OdooRelationship } from './odoo-relationship.model';
import { AccountMove } from './account-move.model';
import { Lead } from './crm.lead.model';
import { SaleOrder } from './sale-order.model';

export class ContactLink extends OdooModel implements OdooSerializableInterface<Contact> {

  public ODOO_MODEL = 'res.partner.link';

  type: string = ""
  note: string = ""
  partner_id:OdooRelationship = new OdooRelationship() 
  value: string = ""
  
  constructor(id?:number, type?:string, value?:string, note?:string) {
    super(id);
    this.type = type 
    this.value = value
    this.note = (note != 'false') ? note : ""
  }

  create() {
    return new ContactLink()
  }
}

export class Contact extends OdooModel implements OdooSerializableInterface<Contact> {
  public readonly ODOO_MODEL = 'res.partner';

  id: number;
  name: string = "";
  active:boolean = false;
  ga_arca:string = ""
  phone:string = ""
  mobile:string = ""
  email:string = ""
  comment:string = ""
  credit_limit:number = 0
  parent_id: OdooRelationship<Contact> = new OdooRelationship<Contact>();
  // ga_comment?: string = "";
  company_type: string;
  vat: string = "";
  // company_id:OdooRelationship<Contact> = new OdooRelationship<Contact>()
  // partner_id:OdooRelationship<Contact> = new OdooRelationship<Contact>()
  is_company:boolean = false;
  street: string = "";
  city: string = "";
  function : string = "";
  zip: string = "";
  child_ids: OdooMultiRelationship<Contact> = new OdooMultiRelationship<Contact>(Contact);
  ref: string = ""
  type: string = ""
  country_id: OdooRelationship<Country> = new OdooRelationship<Country>();

  //relationships
  invoice_ids: OdooMultiRelationship<AccountMove> = new OdooMultiRelationship<AccountMove>(AccountMove);
  opportunity_ids: OdooMultiRelationship<Lead> = new OdooMultiRelationship<Lead>(Lead);
  sale_order_ids: OdooMultiRelationship<SaleOrder> = new OdooMultiRelationship<SaleOrder>(SaleOrder);

  use_partner_credit_limit: boolean = false;

  constructor(id?: number) {
    super(id);
  }

  create(): Contact {
    return new Contact();
  }

  deserialize<T>(input: any): T {
    return super.deserialize(input);
  }
}


export class Country extends OdooModel implements OdooSerializableInterface<Country> {
  ODOO_MODEL: string = 'res.country';
// Base fields
name: string = "";
code: string = "";
address_format: string = "";
// address_view_id: OdooRelationship = new OdooRelationship();
// country_group_ids: OdooMultiRelationship<any> = new OdooMultiRelationship<any>(null);
currency_id: OdooRelationship = new OdooRelationship();
display_name: string = "";
image_url: string = "";
name_position: string = "";
phone_code: number = 0;
// state_ids: OdooMultiRelationship<any> = new OdooMultiRelationship<any>(null);
state_required: boolean = false;
vat_label: string = "";
zip_required: boolean = false;

// Tracking fields
create_date: Date;
create_uid: OdooRelationship = new OdooRelationship();
write_date: Date;
write_uid: OdooRelationship = new OdooRelationship();
__last_update: Date;

constructor(id?: number) {
    super(id);
}

create(): Country {
    return new Country();
}

}
