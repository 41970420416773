<app-navbar [loading]="loading" backroute=".." class="w-100">
    <!-- Wrap everything in a flex container -->
    <div class="d-flex justify-content-between align-items-center w-100">
        <!-- Left side -->
        <a class="navbar-brand">
            <span>Portafoglio ordini</span>&nbsp;
        </a>
    </div>
</app-navbar>

<!-- Filters Section -->
<div class="bg-light px-3 py-3">
    <div class="container-fluid">
        <!-- First Row - Page Selector -->
        <div class="row">
            <div class="col-12">
                <div class="btn-group w-100">
                    <button class="btn"
                        [ngClass]="viewType === 'riepilogo' ? 'btn-primary text-white' : 'btn-outline-muted'"
                        (click)="setViewType('riepilogo')">
                        Riepilogo
                    </button>
                    <button class="btn"
                        [ngClass]="viewType === 'inviati' ? 'btn-primary text-white' : 'btn-outline-muted'"
                        (click)="setViewType('inviati')">
                        Inviati
                    </button>
                    <button class="btn"
                        [ngClass]="viewType === 'confermati' ? 'btn-primary text-white' : 'btn-outline-muted'"
                        (click)="setViewType('confermati')">
                        Confermati
                    </button>
                    <button class="btn"
                        [ngClass]="viewType === 'persi' ? 'btn-primary text-white' : 'btn-outline-muted'"
                        (click)="setViewType('persi')">
                        Persi
                    </button>
                    <button class="btn"
                        [ngClass]="viewType === 'gruppi' ? 'btn-primary text-white' : 'btn-outline-muted'"
                        (click)="setViewType('gruppi')">
                        Gruppi
                    </button>
                </div>
            </div>
        </div>

        <!-- Second Row - Area and User -->
        <div class="row mt-3">
            <!-- Area Filter -->
            <div class="col-md-7">
                <div class="d-flex align-items-center flex-wrap">
                    <span class="me-2">Settore:</span>
                    <button (click)="selectedArea = null; refreshData()"
                        [ngClass]="!selectedArea ? 'btn-primary text-white' : 'btn-outline-muted'" 
                        class="btn ms-2">
                        <i class="fa fa-asterisk"></i>
                    </button>
                    <button *ngFor="let area of areas" 
                        (click)="selectedArea = area.name; refreshData()"
                        [ngClass]="selectedArea == area.name ? getAreaBadgeClass(area.name) : 'btn-outline-muted'"
                        class="btn ms-2">
                        {{ area.name }}
                    </button>
                </div>
            </div>

            <!-- Portfolio Toggle -->
            <div class="col-md-2">
                <div class="form-check form-switch d-flex align-items-center" *ngIf="viewType === 'riepilogo'">
                    <input class="form-check-input me-2" type="checkbox" 
                        [(ngModel)]="showPortfolio" 
                        (change)="refreshData()"
                        id="portfolioToggle">
                    <label class="form-check-label" for="portfolioToggle">
                        Portafoglio
                    </label>
                </div>
            </div>

            <!-- User Filter -->
            <div class="col-md-3">
                <div class="input-group">
                    <span class="input-group-text">Utente</span>
                    <select class="form-select" [(ngModel)]="selectedUser" (change)="refreshData()">
                        <option [ngValue]="null">Tutti gli utenti</option>
                        <option *ngFor="let follower of followers" [ngValue]="follower">
                            {{ follower.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <!-- Third Row - Year and Month -->
        <div class="row mt-3">
            <div class="col-12">
                <div class="d-flex align-items-center gap-4">
                    <div class="d-flex align-items-center">
                        <span class="me-2">Anno:</span>
                        <div class="btn-group">
                            <button *ngFor="let year of years" (click)="toggleYear(year)"
                                [ngClass]="selectedYears.has(year) ? 'btn-primary text-white' : 'btn-outline-muted'"
                                class="btn">
                                {{ year }}
                            </button>
                        </div>
                    </div>

                    <div class="d-flex align-items-center flex-grow-1">
                        <span class="me-2">Mese:</span>
                        <div class="btn-group">
                            <button (click)="selectedMonths.clear(); refreshData()"
                                [ngClass]="selectedMonths.size === 0 ? 'btn-primary text-white' : 'btn-outline-muted'"
                                class="btn">
                                <i class="fa fa-asterisk"></i>
                            </button>
                            <button *ngFor="let month of months" (click)="toggleMonth(month.value)"
                                [ngClass]="selectedMonths.has(month.value) ? 'btn-primary text-white' : 'btn-outline-muted'"
                                class="btn">
                                {{ month.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Riepilogo View -->
<div class="container-fluid mt-4" *ngIf="viewType === 'riepilogo'">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead class="table-light">
                <tr>
                    <th rowspan="2" class="align-middle">Mese</th>
                    <th colspan="4" class="text-center border-end">Offerte Inviate nel mese</th>
                    <th colspan="2" class="text-center border-end">Offerte Approvate nel mese</th>
                    <th colspan="7" class="text-center" *ngIf="showPortfolio">Portafoglio (Contratti - Fatture)</th>
                </tr>
                <tr>
                    <th class="text-end">Valore €</th>
                    <th class="text-end">N°</th>
                    <th class="text-end ">Chiuse €</th>
                    <th class="text-end border-end">Chiuse (N°/%)</th>
                    <th class="text-end">Valore €</th>
                    <th class="text-end border-end">N°</th>
                    <!-- Portfolio columns -->
                    <th class="text-end" *ngIf="showPortfolio && selectedArea==='Tetti'" >Tetti</th>
                    <th class="text-end" *ngIf="showPortfolio && selectedArea==='Case'" >Case</th>
                    <th class="text-end" *ngIf="showPortfolio && selectedArea === 'Facciate e Decking'"
                    >Facciate</th>
                    <th class="text-end" *ngIf="showPortfolio && selectedArea==='Pavimenti'" >Pavimenti</th>
                    <th class="text-end" *ngIf="showPortfolio && selectedArea==='Massello'" >Massello</th>
                    <th class="text-end" *ngIf="showPortfolio && !selectedArea" >Totale</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let stat of monthlyStats" [class.table-active]="stat.isSelected"
                    (click)="toggleSelection(stat)" style="cursor: pointer;">
                    <td class="fw-medium">{{ stat.month | date:'MMMM yyyy':'':'it' }}</td>
                    <!-- Sent section -->
                    <td class="text-end">
                        <span class="text-primary fw-bold">
                            {{ stat.sentAmount | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end">{{ stat.sentCount }}</td>
                    <td class="text-end ">
                        {{ stat.closedFromSentValue | currency:'EUR':'symbol':'1.0-0' }}
                    </td>

                    <td class="text-end border-end">
                        {{ stat.closedFromSentCount }}
                        <small class="text-muted">({{ stat.closedFromSentPercentage | number:'1.0-0' }}%)</small>
                    </td>
                    <!-- Approved section -->
                    <td class="text-end">
                        <span class="text-success fw-bold">
                            {{ stat.approvedAmount | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end border-end">{{ stat.approvedCount }}</td>
                    <!-- Portfolio values -->
                    <td class="text-end" *ngIf="showPortfolio && selectedArea==='Tetti'">
                        <span class="text-success">
                            {{ stat.portfolio?.tettiValue | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end" *ngIf="showPortfolio && selectedArea==='Case'">
                        <span class="text-danger" >
                            {{ stat.portfolio?.caseValue | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end" *ngIf="showPortfolio && selectedArea === 'Facciate e Decking'"
                    >
                        <span class="text-secondary" >
                            {{ stat.portfolio?.facciateValue | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end" *ngIf="showPortfolio && selectedArea==='Pavimenti'">
                        <span class="text-warning"  >
                            {{ stat.portfolio?.pavimentiValue | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end" *ngIf="showPortfolio && selectedArea==='Massello'">
                        <span class="text-info">
                            {{ stat.portfolio?.masselloValue | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end fw-bold" *ngIf="showPortfolio && !selectedArea">
                        {{ stat.portfolio?.totaleValue | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                </tr>
            </tbody>
            <!-- Global Totals -->
            <tfoot class="table-light">
                <tr>
                    <td class="fw-bold">Totale Generale</td>
                    <td class="text-end fw-bold text-primary">
                        {{ getTotalSentAmount() | currency:'EUR':'symbol':'1.0-0' }}
                    </td>

                    <td class="text-end fw-bold">{{ getTotalSentCount() }}</td>
                    <td class="text-end fw-bold">
                        {{ getTotalClosedFromSentValue() | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end border-end fw-bold">
                        {{ getTotalClosedFromSent() }}
                        <small class="text-muted">
                            ({{ (getTotalClosedFromSent() / getTotalSentCount() * 100) | number:'1.0-0' }}%)
                        </small>
                    </td>
                    <td class="text-end fw-bold text-success">
                        {{ getTotalApprovedAmount() | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end fw-bold">{{ getTotalApprovedCount() }}</td>
                </tr>
                <!-- Selection Totals -->
                <tr class="table-primary">
                    <td class="fw-bold">Totale Selezione</td>
                    <td class="text-end fw-bold text-primary">
                        {{ getSelectedTotals().sentAmount | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end fw-bold">{{ getSelectedTotals().sentCount }}</td>
                    <td class="text-end fw-bold">
                        {{ getSelectedTotals().closedFromSentValue | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end border-end fw-bold">
                        {{ getSelectedTotals().closedFromSentCount }}
                        <small class="text-muted">
                            ({{ getSelectedTotals().closedFromSentPercentage | number:'1.0-0' }}%)
                        </small>
                    </td>
                    <td class="text-end fw-bold text-success">
                        {{ getSelectedTotals().approvedAmount | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end fw-bold">{{ getSelectedTotals().approvedCount }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<!-- Inviati View -->
<div class="container-fluid mt-4" *ngIf="viewType === 'inviati'">
    <div class="table-responsive">
        <table class="table table-hover table-sm">
            <thead class="table-light">
                <tr>
                    <th>Codice</th>
                    <th>Cliente</th>
                    <th>Descrizione</th>
                    <th>Responsabile</th>
                    <th>Data Invio</th>
                    <th>Fase</th>
                    <th class="text-end">Importo Inviato</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lead of sentLeads">
                    <td>{{ lead.tracking_code }}</td>
                    <td>{{ lead.partner_id?.name }}</td>
                    <td>{{ lead.name }}</td>
                    <td>{{ lead.user_id?.name }}</td>
                    <td>{{ lead.ga_sent_date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ lead.stage_id?.name }}</td>
                    <td class="text-end">
                        {{ lead.ga_sent_amount | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Confermati View -->
<div class="container-fluid mt-4" *ngIf="viewType === 'confermati'">
    <div class="table-responsive">
        <table class="table table-hover table-sm">
            <thead class="table-light">
                <tr>
                    <th>Codice</th>
                    <th>Cliente</th>
                    <th>Descrizione</th>
                    <th>Responsabile</th>
                    <th>Data Invio</th>
                    <th>Data Approv.</th>
                    <th>Fase</th>
                    <th class="text-end">Importo Atteso</th>
                    <th class="text-end text-muted">Tot. Contratti</th>
                    <th class="text-end">Fatturato</th>
                    <th class="text-end">% Fatt.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lead of approvedLeads">
                    <td>{{ lead.tracking_code }}</td>
                    <td>{{ lead.partner_id?.name }}</td>
                    <td>{{ lead.name }}</td>
                    <td>{{ lead.user_id?.name }}</td>
                    <td>{{ lead.ga_sent_date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ lead.ga_approved_date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ lead.stage_id?.name }}</td>
                    <td class="text-end" [ngClass]="{
                  'text-primary': lead._contractTotal === 0 && lead.expected_revenue > 0
                }">
                        {{ lead.ga_sent_amount | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end" [ngClass]="{
                  'text-muted': lead._contractTotal === 0,
                  'text-primary': lead._contractTotal > 0
                }">
                        {{ lead._contractTotal | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end">
                        {{ lead._invoicedTotal | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                    <td class="text-end" [ngClass]="{
                  'text-danger': lead._invoicedPercentage < 33,
                  'text-warning': lead._invoicedPercentage >= 33 && lead._invoicedPercentage < 66,
                  'text-success': lead._invoicedPercentage >= 66
                }">
                        {{ lead._invoicedPercentage | number:'1.0-0' }}%
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Lost Leads View -->
<div class="container-fluid mt-4" *ngIf="viewType === 'persi'">
    <div class="table-responsive">
        <table class="table table-hover table-sm">
            <thead class="table-light">
                <tr>
                    <th>Codice</th>
                    <th>Cliente</th>
                    <th>Descrizione</th>
                    <th>Responsabile</th>
                    <th>Data Invio</th>
                    <th>Data Perdita</th>
                    <th>Fase</th>
                    <th class="text-end">Importo Inviato</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lead of lostLeads">
                    <td>{{ lead.tracking_code }}</td>
                    <td>{{ lead.partner_id?.name }}</td>
                    <td>{{ lead.name }}</td>
                    <td>{{ lead.user_id?.name }}</td>
                    <td>{{ lead.ga_sent_date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ lead.ga_lost_date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ lead.stage_id?.name }}</td>
                    <td class="text-end">
                        {{ lead.ga_sent_amount | currency:'EUR':'symbol':'1.0-0' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Gruppi View -->
<div class="container-fluid mt-4" *ngIf="viewType === 'gruppi'">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead class="table-light">
                <tr>
                    <th rowspan="2" class="align-middle">Utente</th>
                    <th colspan="3" class="text-center border-end">Offerte Inviate</th>
                    <th colspan="2" class="text-center">Offerte Approvate</th>
                </tr>
                <tr>
                    <th class="text-end">Valore €</th>
                    <th class="text-end">N°</th>
                    <th class="text-end border-end">Chiuse (N°/%)</th>
                    <th class="text-end">Valore €</th>
                    <th class="text-end">N°</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let stat of getGroupStats()">
                    <td class="fw-medium">{{ stat.userName }}</td>
                    <td class="text-end">
                        <span class="text-primary fw-bold">
                            {{ stat.sentAmount | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end">{{ stat.sentCount }}</td>
                    <td class="text-end border-end">
                        {{ stat.closedFromSentCount }}
                        <small class="text-muted">({{ stat.closedFromSentPercentage | number:'1.0-0' }}%)</small>
                    </td>
                    <td class="text-end">
                        <span class="text-success fw-bold">
                            {{ stat.approvedAmount | currency:'EUR':'symbol':'1.0-0' }}
                        </span>
                    </td>
                    <td class="text-end">{{ stat.approvedCount }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>