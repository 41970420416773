import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService } from '../../shared/services/rest-api.service';
import {  first} from 'rxjs/operators';
import { Contact, Country } from '../../models/contact.model';
import { firstValueFrom,} from 'rxjs';
import { SaleOrder } from '../../models/sale-order.model';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ContactPickerComponent } from '../contact-picker/contact-picker.component';
import { parseXMLVAT } from '../contact-search.component';
import { Lead } from 'src/app/models/crm.lead.model';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';



@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  activeTab: string = 'informazioni';
  id: number;
  contact: Contact;
  sales: SaleOrder[];
  leads: Lead[];
  purchases: PurchaseOrder[];
  loading = false;
  isViewingChild: boolean;
  isMobileView: boolean = true;
  viewMessage: boolean = false;
  @ViewChild('picker') picker: ContactPickerComponent;
  editMode :boolean = false
  countries: Country[] = [];

  placesOptions = {
    types: ['address'],
  };

  constructor(
    private route: ActivatedRoute,
    public restapi: RestapiService,
    private router: Router,
    private odooEm: OdooEntityManager,
  ) {
  }

  ngOnInit() {

    this.checkScreenSize();
    this.route.params.subscribe(async params => {
      this.loading = true
      this.id = params.id
      this.countries = await firstValueFrom(this.odooEm.search<Country>(new Country()))
      await this.resolveContact()
      this.loading = false
    })
  }

  private checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
    this.viewMessage = !this.isMobileView;
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    if (tab === 'commesse' && !this.leads) {
      this.fetchLeads();
    } else if (tab === 'vendite' && !this.sales) {
      this.fetchSales();
    } else if (tab === 'acquisti' && !this.purchases) {
      this.fetchPurchases();
    }
  }

  async resolveContact() {
    this.isViewingChild = false
    this.loading = true
    // odooEM.search non trova gli archiviati di default con il solo ID, quindi ...
    var cs = new Contact()
      cs = (await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [
        ['id', '=', this.id]
      ])))[0]
    this.contact = cs
    console.log("countries", this.countries)
    console.log("xxcon ", cs, this.contact)
    this.loading = false;
  } 

  async fetchLeads() {
    this.loading = true;
    try {
      await firstValueFrom(this.odooEm.resolve(this.contact.opportunity_ids));

      this.leads = this.contact.opportunity_ids?.values;

      this.leads.sort((a, b) => {
        if (a.stage_id.name !== b.stage_id.name) {
          return a.stage_id.name.localeCompare(b.stage_id.name);
        }
        return a.tracking_code.localeCompare(b.tracking_code);
      });
      console.log("leads", this.leads)
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      this.loading = false;
    }
  }

  async fetchSales() {
    console.log("fetchSales")
    this.loading = true;
    try {
      await firstValueFrom(this.odooEm.resolve(this.contact.sale_order_ids))


      this.sales = this.contact.sale_order_ids?.values.filter(s => !s.opportunity_id.id)

      this.sales.sort((a, b) => {
        if (a.date_order !== b.date_order) {
          return new Date(b.date_order).getTime() - new Date(a.date_order).getTime();
        }
        return a.name.localeCompare(b.name);
      });

      console.log("sales", this.sales)
      // Compute the delivery state for each related sale
       this.odooEm.call2('sale.order', 'ga_compute_order_state', [ null, this.sales.map(r => r.id)]).then(x => {
      // ga_compute_order_state returns an ordered array of strings in the result key
      this.sales.forEach((r, i) => {
        r._delivery_state = x.result[i]
      })  
    })

    } catch (error) {
      console.error('Error fetching sales:', error);
    } finally {
      this.loading = false;
      console.log("sales", this.sales)
    }
  }

  async fetchPurchases() {
    this.loading = true;
    try {
      this.purchases = await firstValueFrom(this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [
        ['partner_id.id', '=', this.id]
      ]));
      
      // Sort purchases by date if needed
      this.purchases.sort((a, b) => 
        new Date(b.date_approve).getTime() - new Date(a.date_approve).getTime()
      );
    } catch (error) {
      console.error('Error fetching purchases:', error);
    } finally {
      this.loading = false;
    }
  }

  redirectPurchase(id: number) {
    //https://o3.galimberti.eu/web#id=3521&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form
    const url = "https://o3.galimberti.eu/web#id=" + id + "&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form";
    window.open(url, '_blank');
  }


  async restore() {
    if (confirm("Vuoi annullare l'archivazione?")) {
      await firstValueFrom(this.odooEm.call(
        new Contact(),
        "action_unarchive",
        Number(this.id),
        null
      ))
      this.contact.active = true
    }
  }

  async obsolete() {
    await firstValueFrom(this.odooEm.call(
      new Contact(),
      "action_archive",
      [Number(this.id)],
      null,
    ))
    this.contact.active = false
  }

  async onDelete() {
    if (!confirm('Sei sicuro di voler archiviare il contatto ?'))
      return
    this.loading = true
    await this.obsolete()
    this.loading = false
  }

  updateComment(htmlContent: string) {
    this.contact.comment = htmlContent;
    this.update('comment');
  }

  async update(prop: string) {
    this.loading = true
    var p = {}
    p[prop] = this.contact[prop]

    await this.odooEm.update(this.contact, p).pipe(first()).toPromise()

    console.log("HERE ", p)
    this.loading = false
  }

async handleAddressChange(event: any) {
  const components = event.address_components;
  let streetParts: string[] = [];
  let streetNumber = '';
  let country = '';
  
  for (const component of components) {
    const type = component.types[0];
    const value = component.long_name.replaceAll("'", " ");

    switch(type) {
      case 'route':
        streetParts.push(value);
        break;
      case 'street_number':
        streetNumber = value;
        break;
      case 'postal_code':
        this.contact.zip = value;
        await this.update('zip');
        break;
      case 'locality':
      case 'administrative_area_level_3':
        this.contact.city = value;
        await this.update('city');
        break;
      case 'country':
        country = value;
        // Find the country in our countries array and update it
        const countryMatch = this.countries.find(c => c.name.toLowerCase() === country.toLowerCase());
        if (countryMatch) {
          console.log("countryMatch", countryMatch)
          await this.updateCountry(countryMatch.id);
        }
        break;
    }
  }
  
  if (streetNumber) {
    streetParts.push(streetNumber);
  }
  
  if (streetParts.length > 0) {
    this.contact.street = streetParts.join(' ');
    await this.update('street');
  }
}

async updateCountry(countryId: number) {

  console.log("updateCountry", countryId)
  this.loading = true;
  try {
    await firstValueFrom(this.odooEm.update(this.contact, { 
      country_id: Number(countryId),  
    }));  
    await this.resolveContact();
  
  } finally {
    this.loading = false;
  }
}

  onVAT(vat) {
    this.loading = true
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', 'checkvat/taxation_customs/vies/services/checkVatService', true);

    // build SOAP request
    var sr =
      "<s11:Envelope xmlns:s11='http://schemas.xmlsoap.org/soap/envelope/'>" +
      "<s11:Body>" +
      "<tns1:checkVat xmlns:tns1='urn:ec.europa.eu:taxud:vies:services:checkVat:types'>" +
      "<tns1:countryCode>IT</tns1:countryCode>" +
      "<tns1:vatNumber>" + vat + "</tns1:vatNumber>" +
      "</tns1:checkVat>" +
      "</s11:Body>" +
      "</s11:Envelope>"

    xmlhttp.onreadystatechange = () => {
      this.loading = false
      if (xmlhttp.readyState == 4) {
        if (xmlhttp.status == 200) {
          var r = parseXMLVAT(xmlhttp.responseText)

          console.log(r)

            // this.contact.name = r.name
            // this.contact.city = r.city
            // this.contact.street = r.street
            // this.contact.zip = r.postalcode

            this.bulkUpdate(r)
        }
      }
    }
    // Send the POST request
    xmlhttp.setRequestHeader('Content-Type', 'text/xml');
    xmlhttp.send(sr);
  }

  async bulkUpdate(fields) {
    this.loading = true
    await this.odooEm.update<Contact>(this.contact, fields).pipe(first()).toPromise()
    await this.resolveContact()
    this.route
    this.loading = false
  }

  redirectDeal(id: number) {
    // Construct the URL
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/leads', id])
    );

    // Open the URL in a new window/tab
    window.open(url, '_blank');
  }

  redirectSale(id: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/immediate-sale/s', id])
    );
    window.open(url, '_blank');
  }
}
enum OrderState {
  ASSIGNED = 'assigned',
  DRAFT = 'preventivo',
  PRODUCTION = 'in_produzione',
  PLANNING = 'in_progettazione',
  SALE = 'sale'
}

