import { Component, OnInit } from "@angular/core";
import { debounceTime, firstValueFrom, Observable, Subject } from "rxjs";
import { OdooEntityManager } from "../shared/services/odoo-entity-manager.service";
import { StockPicking } from "../models/stock-picking";
import { SaleOrder } from "../models/sale-order.model";
import { Lead } from "../models/crm.lead.model";
import { ODOO_IDS } from "../models/deal";
import { Product } from "../models/product.model";
import {
  StockBackorderConfirmation,
  StockBackorderConfirmationLine,
} from "../models/stock.backorder.confirmation.model";
import { CrmTag } from "../models/crm.tag.model";
import { Contact } from "../models/contact.model";
import { StockMove } from "../models/stock-move";
import { SaleOrderLine } from "../models/sale-order-line.model";

interface Move {
  // New interface to represent a single move for output table (product - quantity - uom - category)
  product_id: [number, string];
  product_uom_qty: number;
  quantity_done: number;
  product_uom: { id: number; name: string };
  category: string;
  price_unit?: number;
  discount?: number;
  selected?: boolean;
  odooMove: StockMove;
  qty_to_complete?: number;
}

interface GroupedMove {
  // New interface group moves by category and uom (i don't need single products)
  category: string;
  uom: string;
  moves: Move[];
  expanded: boolean;
}

interface GroupedPicks {
  // New interface to group picks by opportunity and show/hide lead dropdown
  opportunity?: Lead;
  picks: StockPicking[];
  allSelected: boolean;
  showLeadDropdown?: boolean;
}

@Component({
  selector: "app-delivery-note-viewer2",
  templateUrl:  "./delivery-note-viewer2.component.html"    
})
export class DeliveryNoteViewer2Component implements OnInit {
  picks: StockPicking[]; // New property to store all picks fetched from Odoo after filtering
  groupedPicks: GroupedPicks[] = []; // New property to store grouped picks by opportunity
  selectedPicking: StockPicking | null = null; // New property to store the selected picking
  combinedMoves: Move[] = []; // New property to store all moves combined from selected picks
  loading: boolean = false;
  updatingMoves: boolean = false;
  searchString: string = "";
  filterJustReady: boolean = true;
  filterCommesse: boolean = true;
  filterListino: boolean = false;
  origin: SaleOrder;
  showMultipleOpportunityAlert: boolean = false; // New property to show alert when multiple opportunities are selected
  groupedMoves: GroupedMove[] = []; // New property to store grouped moves by category and uom
  saleOrders: SaleOrder[] = []; // New property to store selected sale orders
  pickingSelect$: Subject<void> = new Subject<void>(); // New property to debounce picking selection
  lastCallId: number;
  saleOrderLines: Map<number, SaleOrderLine> = new Map(); // New property to store resolved SaleOrderLines
  certificationAlert: string ="";
  PEFCAlert: string ="";
  PEFCmoved: boolean = false; // New property to store if PEFC has been moved

  constructor(private odooEm: OdooEntityManager) {}

  async ngOnInit(): Promise<void> {
    // Set up debounced picking selection to avoid rapid updates
    this.pickingSelect$.pipe(debounceTime(700)).subscribe(() => {
      this.onPickingSelectDebounced();
    });
    await this.load();
    if (this.filterListino) {
      this.expandAllGroups();
    }
  }

  toggleFilterCommesse() {
    // New method to toggle between commesse and listino filters
    this.filterCommesse = true;
    this.filterListino = false;
    this.certificationAlert = "";
    this.PEFCAlert="";
    this.deselectAllPicks();
    this.load();
  }

  async toggleFilterListino() {
    // New method to toggle between commesse and listino filters
    this.loading = true;
    this.filterListino = true;
    this.filterCommesse = false;
    this.certificationAlert = "";
    this.PEFCAlert="";
    this.deselectAllPicks();
    this.showMultipleOpportunityAlert = false;
    await this.load();
    this.expandAllGroups();
    this.loading = false;
  }

  toggleLeadDropdown(group: GroupedPicks) {
    group.showLeadDropdown = !group.showLeadDropdown;
  }

  toggleSaleDropdown(pick: StockPicking) {
    pick._showSaleDropdown = !pick._showSaleDropdown;
  }

  getLeadBadgeClass(lead: Lead | undefined): string {
    if (!lead || !lead.area) return "bg-dark";
    switch (lead.area.toLowerCase()) {
      case "tetti":
        return "bg-success";
      case "case":
        return "bg-danger";
      case "facciate e decking":
        return "bg-secondary";
      default:
        return "bg-warning";
    }
  }

  getLeadTags(lead: Lead | undefined): string {
    if (!lead || !lead.tag_ids || lead.tag_ids.values?.length === 0)
      return "N/A";
    return lead.tag_ids.values?.map((tag) => tag.name).join(", ");
  }

  async load() {
    this.loading = true;
    try {
      // Fetch picks from Odoo and filter them based on search string and filters
      const criterias = this.buildLoadCriterias();
      this.picks = await this.fetchAndFilterPicks(criterias);
      // Resolve related data and group picks by opportunity
      await this.resolvePicks();
      this.groupPicks();
      this.deselectAllPicks();
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      this.loading = false;
    }
  }

  private buildLoadCriterias(): any[] {
    let criterias: any[] = [
      ["state", "not in", ["draft", "cancel", "done"]],
      ["picking_type_id", "=", ODOO_IDS.picking_type_ddt],
    ];
    if (this.searchString) {
      criterias.push(
        "|",
        "|",
        "|",
        ["origin", "ilike", this.searchString],
        ["name", "ilike", this.searchString],
        ["partner_id", "ilike", this.searchString],
        ["group_id.sale_id.ga_title", "ilike", this.searchString]
      );
    }
    if (this.filterJustReady) {
      criterias.push(["state", "=", "assigned"]);
    }
    criterias.push([
      "group_id.sale_id.pricelist_id",
      this.filterListino ? "!=" : "=",
      ODOO_IDS.pricelist_commessa,
    ]);
    return criterias;
  }

  private async fetchAndFilterPicks(criterias: any[]): Promise<StockPicking[]> {
    // Fetch picks from Odoo and filter out those without a group_id
    const picks = await firstValueFrom(
      this.odooEm.search<StockPicking>(
        new StockPicking(),
        criterias,
        500,
        "",
        "origin DESC"
      )
    );
    return picks.filter((p) => p.group_id.id != null);
  }

  private async resolvePicks() {
    // Resolve sale orders for all picks
    await firstValueFrom(
      this.odooEm.resolveArrayOfSingle(new SaleOrder(), this.picks, "sale_id")
    );
    if (!this.filterListino) {
      // Resolve additional data based on the current view (commesse or listino)
      await this.resolveCommesseData();
    } else {
      await firstValueFrom(
        this.odooEm.resolveArrayOfSingle(
          new Contact(),
          this.picks,
          "partner_id"
        )
      );
    }
  }

  private async resolveCommesseData() {
    const sales = this.picks
      .map((g) => g.sale_id.value)
      .filter((sale) => sale?.opportunity_id?.id);
    if (sales.length > 0) {
      await firstValueFrom(
        this.odooEm.resolveArrayOfSingle(new Lead(), sales, "opportunity_id")
      );
    }
    const leads = this.picks
      .map((p) => p.sale_id.value?.opportunity_id.value)
      .filter((lead) => lead?.id);
    await firstValueFrom(
      this.odooEm.resolveArrayOfSingle(new Contact(), leads, "partner_id")
    );
    await firstValueFrom(
      this.odooEm.resolveArray(new CrmTag(), leads, "tag_ids")
    );
  }

  groupPicks() {
    const groupMap = new Map<number | string, GroupedPicks>();
    this.picks.forEach((pick) => {
      const opportunityId = pick.sale_id?.value?.opportunity_id?.value?.id;
      const key = opportunityId || "no_opportunity";
      if (!groupMap.has(key)) {
        groupMap.set(key, {
          opportunity: opportunityId
            ? pick.sale_id.value.opportunity_id.value
            : undefined,
          picks: [],
          allSelected: false,
        });
      }
      groupMap.get(key)!.picks.push(pick);
    });
    this.groupedPicks = Array.from(groupMap.values());
  }

  onOpportunitySelect(group: GroupedPicks) {
    //
    group.picks.forEach((pick) => (pick._selected = group.allSelected));
    this.updateCombinedMoves();
    this.checkMultipleOpportunitySelection();

  }

  async onPickingSelect(pick: StockPicking, group: GroupedPicks | null) {
    console.log(
      "Picking selected/deselected:",
      pick.id,
      "Selected:",
      pick._selected
    );
    // Update group selection status if applicable
    if (group) {
      group.allSelected = group.picks.every((p) => p._selected);
    }
    // In listino view, only one pick can be selected at a time
    if (this.filterListino) {
      this.picks.forEach((p) => {
        if (p !== pick) p._selected = false;
      });
    }
    // Update selected sale orders
    this.saleOrders = this.picks
      .filter((p) => p._selected)
      .map((p) => p.sale_id.value);
      console.log("SALE ORDERS", this.saleOrders);
    // Trigger debounced update once i have selected a pick
    this.pickingSelect$.next();
  }

  async onPickingSelectDebounced() {
    console.log("Debounced update triggered");
    await this.updateCombinedMoves();
    this.checkMultipleOpportunitySelection();

  }

  async updateCombinedMoves() {
    this.updatingMoves = true;
    console.log("Updating combined moves");
    try {
      // Reset combined moves and fetch fresh picks: i have to clear combinedMoves otherwise it will keep adding up
      this.combinedMoves = [];

      // Get fresh data for selected picks
      const selectedPicks = this.picks.filter((p) => p._selected);
      const freshPicks = await this.fetchFreshPicks(selectedPicks);

      // Resolve all sale order lines at once to avoid duplicate resolutions: i need sal lines data for services and moves
      await this.resolveSaleOrderLines(freshPicks);

      // Process moves and service products
      await this.updateMovesForPicks(freshPicks);
      await this.processServiceProducts();

      // Group moves for display
      await this.groupMovesByCategoryAndUom();
      console.log("Updated combinedMoves:", this.combinedMoves.length);
    } finally {
      this.updatingMoves = false;
    }
  }

  private async resolveSaleOrderLines(freshPicks: StockPicking[]) {
    // Fetch and resolve all sale order lines for sale orders related to the selected picks
    // this is necessary because if i solved only from moves i'd skip service products
    //we can also use order lines to check if some products are PEFC
    const saleIds = [...new Set(freshPicks.map((p) => p.sale_id.id))];
    const sales = await firstValueFrom(
      this.odooEm.search<SaleOrder>(
        new SaleOrder(),
        [["id", "in", saleIds]],
        0,
        "",
        "id ASC"
      )
    );
    await firstValueFrom(
      this.odooEm.resolveArray(new SaleOrderLine(), sales, "order_line")
    );

    // Store resolved sale order lines in a map for quick access
    this.saleOrderLines.clear();
    sales.forEach((sale) => {
      sale.order_line.values?.forEach((line) => {
        this.saleOrderLines.set(line.id, line);
      });
    });

    // CHECK IF THERE IS A PEFC PRODUCT
    this.PEFCmoved = false;
    if(this.saleOrderLines) { 
      console.log("xxxxxxSALE ORDER LINES", this.saleOrderLines);
    this.saleOrderLines.forEach((line) => {
      if (line.product_id.id) {
      if (line.product_id.name.includes("PEFC")) {
        this.PEFCmoved = true;
        return;
      }
    }
    }
    );
  }
    console.log("xxxxxxPEFC MOVED", this.PEFCmoved);
  }

  private async fetchFreshPicks(
    selectedPicks: StockPicking[]
  ): Promise<StockPicking[]> {
    const freshPicks = await firstValueFrom(
      this.odooEm.search<StockPicking>(
        new StockPicking(),
        [["id", "in", selectedPicks.map((p) => p.id)]],
        0,
        "",
        "id ASC"
      )
    );
    await firstValueFrom(
      this.odooEm.resolveArray(new StockMove(), freshPicks, "move_ids")
    );
    return freshPicks;
  }

  private async updateMovesForPicks(freshPicks: StockPicking[]) {
    const moves = freshPicks.flatMap((p) => p.move_ids.values);
    await firstValueFrom(
      this.odooEm.resolveArrayOfSingle(new Product(), moves, "product_id")
    );
    console.log("xxxFRESH PICKS", freshPicks);
    console.log("xxxMOVES", moves);

    for (const move of moves) {
      const saleOrderLine = this.saleOrderLines.get(move.sale_line_id.id);
      this.combinedMoves.push(this.createMoveObject(move, saleOrderLine));
    }
  }

  private createMoveObject(move: StockMove, saleOrderLine?: SaleOrderLine): Move {
    return {
      selected: true, // Initialize as selected by default
      product_id: [move.product_id.id, move.product_id.name || ""],
      product_uom_qty: move.product_uom_qty,
      quantity_done: Math.round(move.reserved_availability * 1000000) / 1000000,
      product_uom: move.product_uom,
      price_unit: saleOrderLine?.price_unit || this.getResidualPrice(move, this.saleOrderLines),
      discount: saleOrderLine?.discount || this.getResidualDiscount(move, this.saleOrderLines),
      category: move.product_id.value?.categ_id.name || "Uncategorized",
      odooMove: move,
    };
  }

  getResidualPrice(move:StockMove, saleOrderLines:Map<number, SaleOrderLine>):number {
    let price = 0;
    saleOrderLines.forEach((line) => {
      if (line.product_id.id === move.product_id.id) {
        price = line.price_unit;
      }
    });
    return price;
  }

  getResidualDiscount(move:StockMove, saleOrderLines:Map<number, SaleOrderLine>):number {
    let discount = 0;
    saleOrderLines.forEach((line) => {
      if (line.product_id.id === move.product_id.id) {
        discount = line.discount;
      }
    });
    return discount;
  }

  async processServiceProducts() {
    const serviceLines = Array.from(this.saleOrderLines.values()).filter(
      (line) => line.product_type === "service"
    );
  
    for (const line of serviceLines) {
      this.combinedMoves.push({
        selected: true, // Initialize as selected by default
        product_id: [line.product_id.id, line.product_id.name || ""],
        product_uom_qty: line.product_uom_qty,
        quantity_done: line.product_uom_qty,
        product_uom: line.product_uom,
        price_unit: line.price_unit,
        discount: line.discount,
        category: "Servizi e lavorazioni",
        odooMove: null,
      });
    }
  }

  expandAllGroups() {
    this.groupedMoves.forEach((group) => (group.expanded = true));
  }

  getQuantityClass(reserved: number, quantity: number): string {
    if (reserved === quantity) {
      return "text-success";
    } else if (reserved < quantity) {
      return "text-warning";
    } else {
      return "text-danger";
    }
  }

  private groupMovesByCategoryAndUom() {
    //here i have to clear data saved in groupedmoves otherwise it will keep adding up
    this.groupedMoves = [];
    const groupedMap = new Map<string, GroupedMove>();

    //recreate grouped moves from combined moves
    console.log("UPDATING GROUPS WITH COMBINED MOVES", this.combinedMoves);
    this.combinedMoves.forEach((move) => {
      const key = `${move.category}_${move.product_uom.name}`;
      if (!groupedMap.has(key)) {
        groupedMap.set(key, {
          category: move.category,
          uom: move.product_uom.name,
          moves: [],
          expanded: this.filterListino,
        });
      }
      groupedMap.get(key)!.moves.push(move);
    });
    console.log("GROUPED MAP", groupedMap);

    // Convert the Map to an array and sort it
    this.groupedMoves = Array.from(groupedMap.values()).sort((a, b) => {
      // First, sort by category
      const categoryComparison = a.category.localeCompare(b.category, "it", {
        sensitivity: "base",
      });
      if (categoryComparison !== 0) {
        return categoryComparison;
      }
      // If categories are the same, sort by UoM
      return a.uom.localeCompare(b.uom, "it", { sensitivity: "base" });
    });
  }

  deselectAllPicks() {
    this.groupedPicks.forEach((group) => {
      group.allSelected = false;
      group.picks.forEach((pick) => (pick._selected = false));
    });
    this.combinedMoves = [];
    this.saleOrders = [];
    this.updateCombinedMoves();
  }

  calculateTotal(move: Move): number {
    return move.quantity_done * move.price_unit * (1 - move.discount / 100);
  }

  calculateGroupTotal(group: GroupedMove): number {
    return group.moves.reduce(
      (total, move) => total + this.calculateTotal(move),
      0
    );
  }

  calculateGrandTotal(): number {
    return this.combinedMoves.reduce(
      (total, move) => total + this.calculateTotal(move),
      0
    );
  }

  getTotalQuantity(group: GroupedMove): number {
    return group.moves.reduce((total, move) => total + move.product_uom_qty, 0);
  }

  getTotalPrepared(group: GroupedMove): number {
    return group.moves.reduce((total, move) => total + move.quantity_done, 0);
  }

  private checkMultipleOpportunitySelection() {
    let tags = [];
    const selectedOpportunities = new Set<number | string>();
    this.groupedPicks.forEach((group) => {

      if (group.picks.some((pick) => pick._selected)) {
        selectedOpportunities.add(group.opportunity?.id || "no_opportunity");
        // Add tags to the set
        if (group.opportunity?.tag_ids) {
          tags = tags.concat(group.opportunity.tag_ids.ids);
        }
        console.log("GROUP", group);
      }
    });
    this.showMultipleOpportunityAlert = selectedOpportunities.size > 1;
    console.log("THIS SALE ORDERS", this.saleOrders);
    //let's check for certification tags. If tags includes crmTagPEFC crmTagstrutturale from odooIds
    this.certificationAlert = "";
    this.PEFCAlert="";
    if (tags.includes(ODOO_IDS.crmTagPEFC)){
      this.PEFCAlert = "ATTENZIONE! Almeno un ordine selezionato richiede la certificazione per LEGNO PEFC";
    }
    if (tags.includes(ODOO_IDS.crmTagstrutturale)){
      // on another line add the second alert
      this.certificationAlert = "ATTENZIONE! Almeno un ordine selezionato richiede la certificazione per LEGNO MASSICCIO STRUTTURALEstrutturale";
    }
  }


  getSelectedCount(): number {
    return this.groupedPicks.reduce(
      (count, group) =>
        count + group.picks.filter((pick) => pick._selected).length,
      0
    );
  }
  
  onMoveSelectionChange(group: GroupedMove) {
    // Update expanded state based on selection
    group.moves.forEach(move => {
      if (!move.selected) {
        group.expanded = true; // Keep group expanded if any move is deselected
      }
    });
  }
  
  getSelectedMoves(): Move[] {
    return this.combinedMoves.filter(move => move.selected);
  }

  async completePicks() {
    const selectedPicks = this.picks.filter((p) => p._selected);
    if (selectedPicks.length === 0) {
      alert("Nessun ordine selezionato. Seleziona almeno un ordine per procedere.");
      return;
    }
    console.log("SELECTED PICKS", selectedPicks);
  
    const selectedMoves = this.getSelectedMoves();
    if (selectedMoves.length === 0) {
      alert("Nessuna riga selezionata. Seleziona almeno una riga per procedere.");
      return;
    }
    console.log("SELECTED MOVES", selectedMoves);
  
    if (!confirm("Confermi l'operazione? Verrà scaricato solo il materiale selezionato")) {
      return;
    }
  
    this.loading = true;
    try {
      for (const pick of selectedPicks) {
        // Assign and set quantities
        await this.check(
          this.odooEm.call2(new StockPicking().ODOO_MODEL, "action_assign", [
            [pick.id],
          ])
        );
        await this.check(
          this.odooEm.call2(
            new StockPicking().ODOO_MODEL,
            "action_set_quantities_to_reservation",
            [[pick.id]]
          )
        );
      }
        
      //then we collect all unselected moves and set quantity_done = 0 (we don't have a bulk update method to set qty done to reserved availability)
      let unselectedMoves: StockMove[] = [];
  
      this.combinedMoves.forEach((move) => {
        if (!move.selected) {
          move.qty_to_complete = 0;
          unselectedMoves.push(move.odooMove);
        }
      });
  
      console.log("UNSELECTED MOVES", unselectedMoves);
      await firstValueFrom(this.odooEm.updateMulti<StockMove>(new StockMove(), unselectedMoves, { quantity_done: 0 }));
  

      // Process each picking
      for (const pick of selectedPicks) {
        // Attempt to validate
        const validationResult = await this.check(
          this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [
            [pick.id],
          ])
        );
  
        // Handle potential wizard
        if (
          validationResult &&
          validationResult.res_model === "stock.backorder.confirmation"
        ) {
          // Create backorder using the provided logic
          const backorderConfirmation = await firstValueFrom(
            this.odooEm.create<StockBackorderConfirmation>(
              new StockBackorderConfirmation(),
              {
                pick_ids: [pick.id],
              }
            )
          );
  
          await firstValueFrom(
            this.odooEm.create(new StockBackorderConfirmationLine(), {
              to_backorder: true,
              backorder_confirmation_id: backorderConfirmation.id,
              picking_id: pick.id,
            })
          );
  
          await firstValueFrom(
            this.odooEm.call(
              new StockBackorderConfirmation(),
              "process",
              [backorderConfirmation.id],
              {
                button_validate_picking_ids: [pick.id],
              }
            )
          );
        } else if (
          validationResult &&
          validationResult.res_model === "stock.immediate.transfer"
        ) {
          // Confirm the immediate transfer
          await this.check(
            this.odooEm.call2("stock.immediate.transfer", "process", [
              [validationResult.res_id],
            ])
          );
        }
      }
  
      alert("Operazione completata con successo!");
      this.deselectAllPicks();
      await this.load();

    } catch (error) {
      console.error("Errore durante il completamento dei pick:", error);
      alert(
        "Si è verificato un errore durante l'operazione. Controlla la console per i dettagli."
      );
    } finally {
      this.loading = false;
    }
  }

  async check(f: Promise<any>): Promise<any> {
    let r = await f;
    if (r.error) {
      throw new Error(r.error.data.message);
    }
    return r.result || r; // Return the result if it exists, otherwise return the whole response
  }
}