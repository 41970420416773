import { Component, OnInit } from '@angular/core';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { Partner } from 'src/app/models/partner';
import { MailFollower } from 'src/app/models/mail.followers';
import { firstValueFrom } from 'rxjs';
import _ from 'lodash';
import { AREAS_CFG, ODOO_IDS } from '../models/deal';
import { AccountMove } from '../models/account-move.model';


interface MonthlyStats {
  month: string;
  sentAmount: number;
  sentCount: number;
  closedFromSentCount: number;
  closedFromSentValue: number;
  closedFromSentPercentage: number;
  approvedAmount: number;
  approvedCount: number;
  isSelected?: boolean;
  portfolio?: PortfolioValues;
}

interface PortfolioValues {
  tettiValue: number;
  caseValue: number;
  facciateValue: number;
  pavimentiValue: number;
  masselloValue: number;
  totaleValue: number;
}


interface LeadWithInvoicing extends Lead {
  _contractTotal?: number;
  _invoicedTotal?: number;
  _invoicedPercentage?: number;
}




@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
})

export class PortfolioComponent implements OnInit {
  loading = false;
  areas = AREAS_CFG;
  followers: Partner[] = [];
  selectedArea: string | null = null;
  selectedUser: Partner | null = null;
  monthlyStats: MonthlyStats[] = [];

  // All data (unfiltered):
  allLeads: LeadWithInvoicing[] = [];
  allInvoices: AccountMove[] = [];
  contractInvoices: AccountMove[] = [];
  salInvoices: AccountMove[] = [];

  // Pre-split leads (unfiltered):
  allSentLeads: LeadWithInvoicing[] = [];
  allApprovedLeads: LeadWithInvoicing[] = [];
  allLostLeads: LeadWithInvoicing[] = [];

  // Filtered data (used in views):
  leads: LeadWithInvoicing[] = [];
  sentLeads: LeadWithInvoicing[] = [];
  approvedLeads: LeadWithInvoicing[] = [];
  lostLeads: LeadWithInvoicing[] = [];


  selectedYears: Set<number> = new Set([new Date().getFullYear()]);  // Initialize with current year
  selectedMonths: Set<number> = new Set();  // Empty set means all months
  showPortfolio = false;
  portfolioByMonth: { [key: string]: PortfolioValues } = {};

  //form 2023 to current year
  years: number[] = [2023, 2024, 2025]

  months = [
    { value: 1, name: 'Gennaio' },
    { value: 2, name: 'Febbraio' },
    { value: 3, name: 'Marzo' },
    { value: 4, name: 'Aprile' },
    { value: 5, name: 'Maggio' },
    { value: 6, name: 'Giugno' },
    { value: 7, name: 'Luglio' },
    { value: 8, name: 'Agosto' },
    { value: 9, name: 'Settembre' },
    { value: 10, name: 'Ottobre' },
    { value: 11, name: 'Novembre' },
    { value: 12, name: 'Dicembre' }
  ];

  viewType: 'riepilogo' | 'inviati' | 'persi' | 'confermati' | 'gruppi' = 'riepilogo';

  constructor(private odooEM: OdooEntityManager) { }

  async ngOnInit() {
    this.loading = true;

    // 1. Fetch followers (users)
    this.followers = await firstValueFrom(
      this.odooEM.search<Partner>(new Partner(), [
        ['email', 'ilike', '@galimberti.eu']
      ])
    );

    // 2. Fetch ALL leads
    const rawLeads = await firstValueFrom(
      this.odooEM.search<Lead>(new Lead(), [
        ['stage_id.sequence', '>=', 3],
        ['company_id', '=', 1],
      ], 5000)
    );
    this.allLeads = rawLeads as LeadWithInvoicing[]; // We'll enrich them soon

    // 3. Fetch all invoices
    this.allInvoices = await firstValueFrom(
      this.odooEM.search<AccountMove>(
        new AccountMove(),
        [['move_type', '=', 'out_invoice']],
        5000
      )
    );

    // 4. Process leads with invoicing details + split into categories
    this.processAllLeadsData();

    // 5. Calculate the entire portfolio for all months/areas
    this.calculateAllPortfolioValues();

    // 6. Now apply the current filters (if any) and compute monthly stats
    await this.refreshData();

    this.loading = false;
  }

/**
   * Enrich leads with `_contractTotal`, `_invoicedTotal`, `_invoicedPercentage`
   * and split into 3 main categories: sent, approved, lost.
   */
processAllLeadsData(): void {
  // Separate contract vs SAL invoices
  this.contractInvoices = this.allInvoices.filter(
    inv => inv.journal_id[0] === ODOO_IDS.contratti_id && inv.state !== 'cancel'
  );
  this.salInvoices = this.allInvoices.filter(
    inv => inv.journal_id[0] === ODOO_IDS.sal_id && inv.state === 'posted'
  );

  // Group by invoice_origin
  const contractsByCode = _.groupBy(this.contractInvoices, 'invoice_origin');
  const invoicesByCode = _.groupBy(this.salInvoices, 'invoice_origin');

  // For each lead, compute sums
  this.allLeads.forEach(lead => {
    const contractTotal = _.sumBy(contractsByCode[lead.tracking_code] || [], 'amount_untaxed');
    const invoicedTotal = _.sumBy(invoicesByCode[lead.tracking_code] || [], 'amount_untaxed');

    lead._contractTotal = contractTotal;
    lead._invoicedTotal = invoicedTotal;

    // If there's a contract total, use that for % calculation; otherwise fallback to expected_revenue
    if (contractTotal) {
      lead._invoicedPercentage = (invoicedTotal / contractTotal) * 100;
    } else if (lead.expected_revenue) {
      lead._invoicedPercentage = (invoicedTotal / lead.expected_revenue) * 100;
    }
  });

  // Split into 3 categories
  this.allSentLeads = this.allLeads
    .filter(lead => lead.ga_sent_date && !lead.ga_approved_date && lead.stage_id.id !== 9)
    .sort((a, b) => new Date(b.ga_sent_date).getTime() - new Date(a.ga_sent_date).getTime());

  this.allApprovedLeads = this.allLeads
    .filter(lead => lead.ga_approved_date && lead.stage_id.id !== 9)
    .sort((a, b) => new Date(b.ga_approved_date).getTime() - new Date(a.ga_approved_date).getTime());

  this.allLostLeads = this.allLeads
    .filter(lead => lead.stage_id.id === 9)
    .sort((a, b) => {
      const aDate = a.ga_lost_date
        ? new Date(a.ga_lost_date).getTime()
        : new Date(a.ga_sent_date).getTime();
      const bDate = b.ga_lost_date
        ? new Date(b.ga_lost_date).getTime()
        : new Date(b.ga_sent_date).getTime();
      return bDate - aDate;
    });
}

 /**
   * Calculate portfolio values for *all* months & areas (unfiltered).
   * This populates `this.portfolioByMonth` for each year/month.
   */
 calculateAllPortfolioValues(): void {
  // Group leads by area
  const leadsByArea = _.groupBy(this.allLeads, 'area');

  // Build a list of all months we might care about (from `this.years` + all 12 months)
  const allMonths: string[] = [];
  for (let year of this.years) {
    for (let m of this.months) {
      const monthKey = `${year}-${String(m.value).padStart(2, '0')}`;
      allMonths.push(monthKey);
    }
  }

  // Initialize portfolio objects with zero
  allMonths.forEach(month => {
    this.portfolioByMonth[month] = {
      tettiValue: 0,
      caseValue: 0,
      facciateValue: 0,
      pavimentiValue: 0,
      masselloValue: 0,
      totaleValue: 0,
    };
  });

  // For each area, compute portfolio by month
  for (let area in leadsByArea) {
    const areaLeads = leadsByArea[area];
    // relevant invoices for these leads
    const areaContracts = this.contractInvoices.filter(inv =>
      areaLeads.map(l => l.tracking_code).includes(inv.invoice_origin)
    );
    const areaSal = this.salInvoices.filter(inv =>
      areaLeads.map(l => l.tracking_code).includes(inv.invoice_origin)
    );

    allMonths.forEach(month => {
      // Summation of contracts up to this month
      const contractAmount = areaContracts
        .filter(inv => new Date(inv.invoice_date).toISOString().substring(0, 7) <= month)
        .reduce((sum, inv) => sum + inv.amount_untaxed, 0);

      // Summation of sal invoices up to this month
      const invoicedAmount = areaSal
        .filter(inv => new Date(inv.invoice_date).toISOString().substring(0, 7) <= month)
        .reduce((sum, inv) => sum + inv.amount_untaxed, 0);

      // Portfolio = contract - invoiced
      const portfolioValue = Math.max(0, contractAmount - invoicedAmount);

      // Assign to the correct key in that month
      switch (area) {
        case 'Tetti':
          this.portfolioByMonth[month].tettiValue = portfolioValue;
          break;
        case 'Case':
          this.portfolioByMonth[month].caseValue = portfolioValue;
          break;
        case 'Facciate e Decking':
          this.portfolioByMonth[month].facciateValue = portfolioValue;
          break;
        case 'Pavimenti':
          this.portfolioByMonth[month].pavimentiValue = portfolioValue;
          break;
        case 'Massello':
          this.portfolioByMonth[month].masselloValue = portfolioValue;
          break;
      }

      // Update total
      this.portfolioByMonth[month].totaleValue =
        this.portfolioByMonth[month].tettiValue +
        this.portfolioByMonth[month].caseValue +
        this.portfolioByMonth[month].facciateValue +
        this.portfolioByMonth[month].pavimentiValue +
        this.portfolioByMonth[month].masselloValue;
    });
  }
}

 /**
   * Re-applies all filters (area, user, selected years, months, etc.)
   * and updates the displayed data + monthly stats.
   */
 async refreshData(): Promise<void> {
  this.loading = true;
  try {
    // 1. Filter from allLeads based on area, user, date
    let filtered = [...this.allLeads];

    // Filter by area
    if (this.selectedArea) {
      filtered = filtered.filter(lead => lead.area === this.selectedArea);
    }

    // Filter by user
    if (this.selectedUser) {
      console.log('Filtering by user:', this.selectedUser);
      filtered = filtered.filter(lead => lead.user_id?.name === this.selectedUser.name);
    }

    // Filter by selected years/months
    const yearsList = Array.from(this.selectedYears);
    const monthsList = Array.from(this.selectedMonths);

    if (yearsList.length > 0) {
      filtered = filtered.filter(lead => {
        const sentDate = lead.ga_sent_date ? new Date(lead.ga_sent_date) : null;
        const approvedDate = lead.ga_approved_date ? new Date(lead.ga_approved_date) : null;
        if (!sentDate) return false; // If there's no sentDate, skip

        const sentYear = sentDate.getFullYear();
        const sentMonth = sentDate.getMonth() + 1;
        const approvedYear = approvedDate?.getFullYear() || null;
        const approvedMonth = approvedDate ? approvedDate.getMonth() + 1 : null;

        // "Matches" if (sentYear, sentMonth) or (approvedYear, approvedMonth) is in the sets
        const matchesSentDate =
          yearsList.includes(sentYear) &&
          (monthsList.length === 0 || monthsList.includes(sentMonth));

        const matchesApprovedDate =
          approvedDate &&
          yearsList.includes(approvedYear!) &&
          (monthsList.length === 0 || monthsList.includes(approvedMonth!));

        return matchesSentDate || matchesApprovedDate;
      });
    }

    // This is the final set of leads displayed in "riepilogo"
    this.leads = filtered;

    // 2. Filter the pre-split categories for the same year/month constraints
    this.sentLeads = this.filterLeadsByDate(this.leads, yearsList, monthsList, 'ga_sent_date');
    this.approvedLeads = this.filterLeadsByDate(this.leads, yearsList, monthsList, 'ga_approved_date');
    this.lostLeads = this.filterLeadsByDate(this.leads, yearsList, monthsList, 'ga_lost_date', 'ga_sent_date');

    console.log('Filtered leads:', this.leads);
    console.log('Sent leads:', this.sentLeads);
    console.log('Approved leads:', this.approvedLeads);
    console.log('Lost leads:', this.lostLeads);


    // 3. Recalculate monthly stats for the *filtered* leads
    this.calculateMonthlyStats();
  } catch (error) {
    console.error('Error filtering leads:', error);
  } finally {
    this.loading = false;
  }
}

/**
   * Helper to filter a given leads array by the selected years/months.
   * Fallback date field can be used if the primary date is missing (e.g. 'ga_lost_date' or 'ga_sent_date').
   */
private filterLeadsByDate(
  leads: LeadWithInvoicing[],
  yearsList: number[],
  monthsList: number[],
  primaryDateField: keyof Lead,
  fallbackDateField?: keyof Lead
): LeadWithInvoicing[] {
  if (yearsList.length === 0 && monthsList.length === 0) {
    // No date filter => return them all
    return leads;
  }
  return leads.filter(lead => {
    let dateToCheck = lead[primaryDateField] ? new Date(lead[primaryDateField] as string) : null;

    // If primary date is null and we have a fallback date field:
    if (!dateToCheck && fallbackDateField && lead[fallbackDateField]) {
      dateToCheck = new Date(lead[fallbackDateField] as string);
    }
    if (!dateToCheck) return false;

    const y = dateToCheck.getFullYear();
    const m = dateToCheck.getMonth() + 1;

    const yearMatch = yearsList.length === 0 || yearsList.includes(y);
    const monthMatch = monthsList.length === 0 || monthsList.includes(m);

    return yearMatch && monthMatch;
  });
}

  /**
   * Compute monthlyStats from the currently filtered leads.
   */
  calculateMonthlyStats(): void {

   // 1. Build an array of all "yyyy-MM" only for selectedYears & selectedMonths
  const allMonths: string[] = [];
  for (let year of this.years) {
    // Skip if year not selected
    if (!this.selectedYears.has(year)) continue;
    if (this.selectedMonths.size === 0) {
   // user wants all months
      for (let m of this.months) {
        const monthKey = `${year}-${String(m.value).padStart(2, '0')}`;
        allMonths.push(monthKey);
      }
      continue;
    }

    // If we do want only specifically checked months:
    for (let m of this.months) {
      // Skip if month not selected
      if (!this.selectedMonths.has(m.value)) continue;

      const monthKey = `${year}-${String(m.value).padStart(2, '0')}`;
      allMonths.push(monthKey);
    }
  }

  console.log('Selected months =>', allMonths);


    // 2. Map over each month to compute stats from *filtered* leads. (this.leads is the filtered set)
  this.monthlyStats = allMonths.map(month => {

    // Filter leads where ga_sent_date matches `month`
    const sentLeads = this.leads.filter(lead => {
      if (!lead.ga_sent_date) return false;
      const sentYearMonth = new Date(lead.ga_sent_date).toISOString().substring(0, 7);
      return sentYearMonth === month;
    });

    // Filter leads where ga_approved_date matches `month`
    const approvedLeads = this.leads.filter(lead => {
      if (!lead.ga_approved_date) return false;
      const approvedYearMonth = new Date(lead.ga_approved_date).toISOString().substring(0, 7);
      return approvedYearMonth === month;
    });

    // Among the approved leads, check how many were also sent in the same month
    const closedFromSent = approvedLeads.filter(l => {
      if (!l.ga_sent_date) return false;
      const sentYearMonth = new Date(l.ga_sent_date).toISOString().substring(0, 7);
      return sentYearMonth === month;
    });
    const closedPercentage = sentLeads.length > 0
      ? (closedFromSent.length / sentLeads.length) * 100
      : 0;


      // Optionally attach the pre-calculated portfolio for that month if showPortfolio is true
      const portfolioValue = this.showPortfolio
        ? this.portfolioByMonth[month] || {
            tettiValue: 0,
            caseValue: 0,
            facciateValue: 0,
            pavimentiValue: 0,
            masselloValue: 0,
            totaleValue: 0,
          }
        : null;

      return {
        month,
        sentAmount: _.sumBy(sentLeads, 'ga_sent_amount'),
        sentCount: sentLeads.length,
        closedFromSentCount: closedFromSent.length,
        closedFromSentValue: _.sumBy(closedFromSent, 'ga_sent_amount'),
        closedFromSentPercentage: closedPercentage,
        approvedAmount: _.sumBy(approvedLeads, 'expected_revenue'),
        approvedCount: approvedLeads.length,
        isSelected: false,
        portfolio: portfolioValue,
      };
    });

    // Sort descending by month
    this.monthlyStats.sort((a, b) => b.month.localeCompare(a.month));
  }

  // -- Filter toggles --

  toggleYear(year: number) {
    if (this.selectedYears.has(year)) {
      // Prevent removing *all* years
      if (this.selectedYears.size > 1) {
        this.selectedYears.delete(year);
      }
    } else {
      this.selectedYears.add(year);
    }
    this.refreshData();
  }

  toggleMonth(monthValue: number) {
    if (this.selectedMonths.has(monthValue)) {
      this.selectedMonths.delete(monthValue);
    } else {
      this.selectedMonths.add(monthValue);
    }
    this.refreshData();
  }

  // -- View helpers --

  setViewType(type: 'riepilogo' | 'inviati' | 'confermati' | 'gruppi' | 'persi') {
    this.viewType = type;
  }

  toggleSelection(stat: MonthlyStats) {
    stat.isSelected = !stat.isSelected;
  }

  getSelectedTotals() {
    const selectedStats = this.monthlyStats.filter(stat => stat.isSelected);
    const totalSentCount = _.sumBy(selectedStats, 'sentCount');
    return {
      sentAmount: _.sumBy(selectedStats, 'sentAmount'),
      sentCount: totalSentCount,
      closedFromSentCount: _.sumBy(selectedStats, 'closedFromSentCount'),
      closedFromSentValue: _.sumBy(selectedStats, 'closedFromSentValue'),
      closedFromSentPercentage:
        totalSentCount > 0
          ? (_.sumBy(selectedStats, 'closedFromSentCount') / totalSentCount) * 100
          : 0,
      approvedAmount: _.sumBy(selectedStats, 'approvedAmount'),
      approvedCount: _.sumBy(selectedStats, 'approvedCount'),
    };
  }

  getGroupStats() {
    // Group the *filtered* leads by user
    const userGroups = _.groupBy(this.leads, lead => lead.user_id?.name || 'Non Assegnato');
    return Object.entries(userGroups).map(([userName, userLeads]) => {
      const sentLeads = (userLeads as LeadWithInvoicing[]).filter(l => l.ga_sent_date);
      const approvedLeads = (userLeads as LeadWithInvoicing[]).filter(l => l.ga_approved_date);
      const closedFromSent = approvedLeads.filter(l => l.ga_sent_date);

      return {
        userName,
        sentAmount: _.sumBy(sentLeads, 'ga_sent_amount'),
        sentCount: sentLeads.length,
        closedFromSentCount: closedFromSent.length,
        closedFromSentPercentage:
          sentLeads.length > 0 ? (closedFromSent.length / sentLeads.length) * 100 : 0,
        approvedAmount: _.sumBy(approvedLeads, 'expected_revenue'),
        approvedCount: approvedLeads.length,
      };
    });
  }

  getAreaBadgeClass(area: string): string {
    switch (area) {
      case 'Tetti':
        return 'bg-success text-white';
      case 'Case':
        return 'bg-danger text-white';
      case 'Facciate e Decking':
        return 'bg-secondary text-white';
      case 'Aziendale':
        return 'bg-muted text-white';
      default:
        return 'bg-warning text-white';
    }
  }

  // -- Totals across all (filtered) months, if needed --

  getTotalSentAmount(): number {
    return _.sumBy(this.monthlyStats, 'sentAmount');
  }

  getTotalSentCount(): number {
    return _.sumBy(this.monthlyStats, 'sentCount');
  }

  getTotalClosedFromSent(): number {
    return _.sumBy(this.monthlyStats, 'closedFromSentCount');
  }

  getTotalClosedFromSentValue(): number {
    return _.sumBy(this.monthlyStats, 'closedFromSentValue');
  }

  getTotalApprovedAmount(): number {
    return _.sumBy(this.monthlyStats, 'approvedAmount');
  }

  getTotalApprovedCount(): number {
    return _.sumBy(this.monthlyStats, 'approvedCount');
  }
}
