import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-contact-picker',
  templateUrl: './contact-picker.component.html'
})
export class ContactPickerComponent implements AfterViewInit {
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Input() contact: Contact;
  openContact: Contact | null = null;
  editingContact: boolean = false;
  originalData: Partial<Contact> = {};
  contactChilds: Contact[] = [];
  addressChilds: Contact[] = [];

  placesOptions = {
    types: ['address'],
  };

  constructor(private odooEm: OdooEntityManager) {}

  async ngAfterViewInit(): Promise<void> {
    if (!this.contact?.child_ids?.values) {
      await this.loadChild();
    }
  }

  async loadChild(): Promise<void> {
    this.loading.next(true);
    try {
      await firstValueFrom(this.odooEm.resolve(this.contact.child_ids).pipe(first()));
      // create the 2 arrays of contacts based on isContactType
      this.contactChilds = this.contact?.child_ids?.values?.filter(c => this.isContactType(c)) || [];
      this.addressChilds = this.contact?.child_ids?.values?.filter(c => !this.isContactType(c)) || [];
    } finally {
      this.loading.next(false);
    }
  }

  isContactType(contact: Contact): boolean {
    return contact.type === 'contact';
  }

  getTypeLabel(type: string): string {
    const types = {
      'contact': 'Contatto',
      'invoice': 'Indirizzo Fatturazione',
      'delivery': 'Indirizzo Spedizione',
      'private': 'Indirizzo Privato',
      'other': 'Altro Indirizzo'
    };
    return types[type] || type;
  }

  getFullAddress(contact: Contact): string {
    const parts = [contact.street];
    if (contact.zip) parts.push(contact.zip);
    if (contact.city) parts.push(contact.city);
    return parts.filter(Boolean).join(' - ');
  }

  async startEdit(contact: Contact): Promise<void> {
    this.originalData = { 
      name: contact.name,
      type: contact.type,
      function: contact.function,
      email: contact.email,
      phone: contact.phone,
      mobile: contact.mobile,
      street: contact.street,
      city: contact.city,
      zip: contact.zip,
      comment: contact.comment
    };
    this.openContact = contact;
    this.editingContact = true;
  }

  async saveContact(contact: Contact): Promise<void> {
    this.loading.next(true);
    try {
      const updatedContact = await firstValueFrom(
        this.odooEm.search<Contact>(new Contact(), contact.id).pipe(first())
      );
      Object.assign(contact, updatedContact);
    } finally {
      this.openContact = null;
      this.editingContact = false;
      //reassign contacts and addresses array
      this.contactChilds = this.contact.child_ids.values.filter(c => this.isContactType(c));
      this.addressChilds = this.contact.child_ids.values.filter(c => !this.isContactType(c));

      this.loading.next(false);
    }
  }

  cancelEdit(): void {
    if (this.openContact && this.originalData) {
      Object.assign(this.openContact, this.originalData);
    }
    this.openContact = null;
    this.editingContact = false;
    this.originalData = {};
  }

  async handleAddressChange(event: any, contact: Contact): Promise<void> {
    const components = event.address_components;
    let streetParts: string[] = [];
    let streetNumber = '';
    
    for (const component of components) {
      const type = component.types[0];
      const value = component.long_name.replaceAll("'", " ");

      switch(type) {
        case 'route':
          streetParts.push(value);
          break;
        case 'street_number':
          streetNumber = value;
          break;
        case 'postal_code':
          contact.zip = value;
          await this.update(contact, 'zip');
          break;
        case 'locality':
        case 'administrative_area_level_3':
          contact.city = value;
          await this.update(contact, 'city');
          break;
      }
    }
    
    if (streetNumber) {
      streetParts.push(streetNumber);
    }
    
    if (streetParts.length > 0) {
      contact.street = streetParts.join(' ');
      await this.update(contact, 'street');
    }
  }

  newContact() {
    this.newLink('contact');
  }
  
  newAddress() {
    this.newLink('delivery');
  }

  private async newLink(type: string) {
    const fields = {
      name: "Nuovo",
      parent_id: this.contact.id,
      type: type
    };
    //create the new contact
    console.log("fields", fields);
     
    this.odooEm.create<Contact>(new Contact(), fields)
      .pipe(first())
      .subscribe(newContact => {
        this.contact.child_ids.values.push(newContact);
        //push into the right array
        if (this.isContactType(newContact)) {
          this.contactChilds.push(newContact);
        } else {
          this.addressChilds.push(newContact);
        }
        this.startEdit(newContact);
      });
  }

  updateComment(contact: Contact, htmlContent: string): void {
    contact.comment = htmlContent;
    this.update(contact, 'comment');
  }

  async update(contact: Contact, prop: keyof Contact): Promise<void> {
    this.loading.next(true);
    try {
      const updateData = { [prop]: contact[prop] };
      await firstValueFrom(this.odooEm.update(contact, updateData));
    } finally {
      this.loading.next(false);
    }
  }

  async onDelete(contact: Contact): Promise<void> {
    if (!confirm('Sei sicuro di volerlo eliminare?')) {
      return;
    }

    this.loading.next(true);
    try {
      await firstValueFrom(this.odooEm.delete(new Contact(), [contact.id]).pipe(first()));
      this.contact.child_ids.ids = this.contact.child_ids.ids.filter(id => id !== contact.id);
      this.contact.child_ids.values = this.contact.child_ids.values.filter(c => c !== contact);
      if (this.isContactType(contact)) {
        this.contactChilds = this.contactChilds.filter(c => c !== contact);
      } else {
        this.addressChilds = this.addressChilds.filter(c => c !== contact);
      }
    } finally {
      this.loading.next(false);
    }
  }
}