<app-navbar [loading]="loading" backroute="..">
    <a class="navbar-brand me-auto"> Da bollare </a>
  </app-navbar>

  <div class="container-fluid mt-3">
    <div class="row align-items-center mb-3 pb-3 border-bottom">
      <div class="col-md-6">
        <div class="input-group">
          <span class="input-group-text bg-light border-end-0">
            <i class="fas fa-search text-muted"></i>
          </span>
          <input
            type="text"
            class="form-control border-start-0"
            [(ngModel)]="searchString"
            (ngModelChange)="load()"
            placeholder="Cerca..."
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end align-items-center">
          <div
            class="btn-group me-3"
            role="group"
            aria-label="View selection"
          >
            <button
              type="button"
              class="btn"
              [class.btn-primary]="!filterListino"
              [class.btn-outline-primary]="filterListino"
              (click)="toggleFilterCommesse()"
            >
              Commesse
            </button>
            <button
              type="button"
              class="btn"
              [class.btn-primary]="filterListino"
              [class.btn-outline-primary]="!filterListino"
              (click)="toggleFilterListino()"
            >
              A listino
            </button>
          </div>
          <div class="form-check form-switch d-flex align-items-center">
            <input
              class="form-check-input me-2"
              type="checkbox"
              id="filterJustReady"
              [(ngModel)]="filterJustReady"
              (change)="load()"
            />
            <label class="form-check-label mb-0" for="filterJustReady"
              >Solo pronti</label
            >
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="showMultipleOpportunityAlert"
      class="alert alert-warning mt-3"
      role="alert"
    >
    Attenzione: Hai selezionato ordini di diverse commesse!
    </div>
    <!-- <div
      *ngIf="PEFCmoved"
      class="alert alert-danger mt-3"
      role="alert"
    >
      Attenzione: Nelle vendite selezionate è presente materiale PEFC!!!
    </div> -->
    <div
      *ngIf="certificationAlert"
      class="alert alert-danger mt-3"
      role="alert"
    >
      {{certificationAlert}}
    </div>
    <div
      *ngIf="certificationAlert"
      class="alert alert-danger mt-3"
      role="alert"
    >
      {{PEFCAlert}}
    </div>


    <div class="row mt-3">
      <div class="col-md-6 border-end">
        <h2 class="mb-4">Selezione ordini</h2>
        <ul class="list-group list-group-flush" *ngIf="!loading">
          <ng-container *ngIf="!filterListino">
            <table class="table table-hover">
              <tbody>
                <ng-container
                  *ngFor="let group of groupedPicks; let i = index"
                >
                  <tr [class.border-top]="i > 0" class="bg-light">
                    <td colspan="6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [id]="
                            group.opportunity
                              ? 'opportunity_' + group.opportunity.id
                              : 'no_opportunity'
                          "
                          [(ngModel)]="group.allSelected"
                          (change)="onOpportunitySelect(group)"
                        />
                        <label
                          class="form-check-label fw-bold"
                          [for]="
                            group.opportunity
                              ? 'opportunity_' + group.opportunity.id
                              : 'no_opportunity'
                          "
                        >
                          <a
                            href="#"
                            (click)="
                              $event.preventDefault();
                              toggleLeadDropdown(group)
                            "
                            class="text-decoration-none text-dark"
                          >
                            {{
                              group.opportunity
                                ? (group.opportunity.tracking_code || "N/A") +
                                  " - " +
                                  (group.opportunity.partner_id.name ||
                                    "N/A") +
                                  " - " +
                                  (group.opportunity.name || "N/A")
                                : "No Opportunity"
                            }}
                          </a>
                          <span
                            class="badge ms-2"
                            [ngClass]="getLeadBadgeClass(group.opportunity)"
                          >
                            {{ group.opportunity?.area || "N/A" }}
                          </span>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="group.showLeadDropdown">
                    <td colspan="6">
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <strong>Cliente:</strong>
                            {{
                              group.opportunity?.partner_id.value?.ga_arca
                            }}
                            - {{ group.opportunity?.partner_id.name }}
                          </div>
                          <div class="col-md-6">
                            <strong>Indirizzo:</strong>
                            {{ group.opportunity?.street }}
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6">
                            <strong>Dettagli:</strong>
                            {{ getLeadTags(group.opportunity) }}
                          </div>
                          <div class="col-md-6">
                            <strong>Responsabile:</strong>
                            {{ group.opportunity?.user_id.name }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <ng-container *ngFor="let pick of group.picks">
                    <tr>
                      <td style="width: 40px;">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [id]="pick.id"
                          [(ngModel)]="pick._selected"
                          (change)="onPickingSelect(pick, group)"
                        />
                      </td>
                      <td style="width: 30px;">
                        <i
                          class="fa fa-circle"
                          [ngClass]="
                            pick.state === 'assigned'
                              ? 'text-success'
                              : 'text-warning'
                          "
                        ></i>
                      </td>
                      <td colspan="2">                       
                          {{ pick.sale_id?.value.name || "N/A" }}                                         
                      
                        <a [routerLink]="['/immediate-sale/s', pick.sale_id?.value?.id]"
                        target="_blank"
                        class="text-decoration-none">
                       <i class="fas fa-external-link-alt me-1 ms-4"></i>
                     </a>
                      </td>
                      <td colspan="3">
                        {{ pick.sale_id?.value.ga_title || "N/A" }}
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </ng-container>
          <ng-container *ngIf="filterListino">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="width: 40px;"></th>
                  <th style="width: 30px;"></th>
                  <th>Ordine</th>
                  <th></th>
                  <th>Cliente</th>
                  <th>Titolo</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let pick of picks; let i = index">
                  <tr [class.border-top]="i > 0">
                    <td>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [id]="pick.id"
                        [(ngModel)]="pick._selected"
                        (change)="onPickingSelect(pick, null)"
                      />
                    </td>
                    <td>
                      <i
                        class="fa fa-circle"
                        [ngClass]="
                          pick.state === 'assigned'
                            ? 'text-success'
                            : 'text-warning'
                        "
                      ></i>
                    </td>
                    <td>
                      <a
                        href="#"
                        (click)="
                          $event.preventDefault(); toggleSaleDropdown(pick)
                        "
                        class="text-decoration-none text-dark"
                      >
                        {{ pick.sale_id?.value.name || "" }}
                      </a>                  
                    </td>
                    <td>
                      <a [routerLink]="['/immediate-sale/s', pick.sale_id?.value?.id]"
                      target="_blank"
                      class="text-decoration-none">
                     <i class="fas fa-external-link-alt me-1"></i>
                   </a>
                    </td>
                    <td>
                      {{ pick.sale_id?.value?.partner_id.name || "" }}
                    </td>
                    <td>
                      {{ pick.sale_id?.value?.ga_title || "" }}

                    </td>
                  </tr>
                  <tr *ngIf="pick._showSaleDropdown" class="bg-light">
                    <td colspan="7">
                      <div class="p-3">
                        <div class="row">                         
                          <div class="col-md-4">
                            <strong>Indirizzo:</strong> da sistemare!!
                          </div>
                          <div class="col-md-4">
                            <strong>Riferimento:</strong> {{ pick.sale_id?.value.client_order_ref || "" }}
                          </div>

                    
                          <div class="col-md-4">
                            <strong>Responsabile:</strong>
                            {{ pick.sale_id?.value.user_id.name }}
                          </div>
                          <div class="col-md-4">
                            <strong>Pagamento:</strong>                        
                              {{ pick.sale_id?.value?.payment_term_id.name || "" }}
                              </div>
                    
                          </div>  
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </ng-container>
        </ul>
      </div>

      <div class="col-md-6">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h2>Materiale preparato</h2>
          <button
            class="btn btn-primary text-nowrap"
            (click)="completePicks()"
            [disabled]="loading"
          >
            <span *ngIf="!loading">Bolla</span>
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
        <div *ngIf="updatingMoves" class="text-center mb-3">
          <div class="fa fa-spinner fa-spin" role="status">
            <span class="visually-hidden">Updating...</span>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Prodotto</th>
              <th>UdM</th>
              <th>Caricato / Richiesto</th>
              <th *ngIf="filterListino">Prezzo</th>
              <th *ngIf="filterListino">Sconto</th>
              <th *ngIf="filterListino">Totale</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let group of groupedMoves">
              <tr
                (click)="group.expanded = !group.expanded"
                style="cursor: pointer;"
              >
                <td class="fw-bold">
                  <i
                    [class]="
                      group.expanded
                        ? 'fa-solid fa-caret-down'
                        : 'fa-solid fa-caret-right'
                    "
                  ></i>
                  {{ group.category }}
                </td>
                <td>{{ group.uom }}</td>
                <td
                  *ngIf="!filterListino"
                  [ngClass]="
                    getQuantityClass(
                      getTotalPrepared(group),
                      getTotalQuantity(group)
                    )
                  "
                >
                  {{ getTotalPrepared(group) | number : "1.0-5" : "it-IT" }} /
                  {{ getTotalQuantity(group) | number : "1.0-5" : "it-IT" }}
                </td>
                <td *ngIf="filterListino" colspan="3"></td>
                <td *ngIf="filterListino" class="text-end">
                  <strong
                    >{{
                      calculateGroupTotal(group) | number : "1.2-2" : "it-IT"
                    }}
                    €</strong
                  >
                </td>
              </tr>
              <ng-container *ngIf="group.expanded">
                <tr *ngFor="let move of group.moves">
                  <td class="ps-4">
                    <div class="form-check">
                      <input class="form-check-input me-2" type="checkbox" 
                        [(ngModel)]="move.selected" 
                        (change)="onMoveSelectionChange(group)">
                      {{ move.product_id[1] }}
                    </div>
                  </td>
                  <td>{{ move.product_uom.name }}</td>
                  <td
                    [ngClass]="
                      getQuantityClass(
                        move.quantity_done,
                        move.product_uom_qty
                      )
                    "
                  >
                    {{ move.quantity_done | number : "1.0-5" : "it-IT" }} /
                    {{ move.product_uom_qty | number : "1.0-5" : "it-IT" }}
                  </td>
                  <td *ngIf="filterListino">
                    {{ move.price_unit | number : "1.2-2" : "it-IT" }} €
                  </td>
                  <td *ngIf="filterListino">
                    {{ move.discount | number : "1.2-2" : "it-IT" }}%
                  </td>
                  <td *ngIf="filterListino" class="text-end">
                    {{ calculateTotal(move) | number : "1.2-2" : "it-IT" }} €
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot *ngIf="filterListino">
            <tr>
              <td colspan="5" class="text-end">
                <strong>Totale Complessivo</strong>
              </td>
              <td class="text-end">
                <strong
                  >{{
                    calculateGrandTotal() | number : "1.2-2" : "it-IT"
                  }}
                  €</strong
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>