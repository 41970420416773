import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RestapiService, QuerySearchOptions, ApiEvent, EventType, QueryPostOptions, QueryCriteria } from 'src/app/shared/services/rest-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AREAS_CFG } from '../../models/deal'
import { ViewChild } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooRelationship } from 'src/app/models/odoo-relationship.model';
import { AccountMove } from 'src/app/models/account-move.model';
import { ODOO_IDS } from '../../models/deal';
import { MailFollower } from 'src/app/models/mail.followers';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Partner } from 'src/app/models/partner';
import { User } from 'src/app/models/user.model';
import { MailActivity } from 'src/app/models/mail.message';
import { table } from 'console';
import * as _ from 'lodash';

interface DealFilters { // Updated filter interface
  searchText?: string;
  selectedUser: Partner | null;
  startDate: string;
  endDate: string;
  justRecents: boolean;
  selectedStages: number[];
  area: string;
  sortField: string;
  sortAscending: boolean;
  tableView: boolean;
}

interface LeadWithInvoicing extends Lead {
  _contractTotal?: number;
  _contractsExist?: boolean;
  _invoicedTotal?: number;
  _leftToInvoice?: number;
}

@Component({
  selector: 'app-deals-dashboard',
  templateUrl: './deals-dashboard.component.html',
})
export class DealsDashboardComponent implements OnInit, AfterViewInit {
  stages: CrmStage[] = []
  cards: LeadWithInvoicing[] = []
  filteredCards: Lead[] = []
  @Input() loading: boolean = true
  newDeal:Lead
  filter: BehaviorSubject<string> 
  justRecents = true
  @Input() title = "Fascicoli"
  @ViewChild('searchInput', {static:true}) searchInput: ElementRef
  @Input() embedded:boolean = false
  @Output() onSelect:EventEmitter<boolean> = new EventEmitter()
  lastSearch: number;
  stageDropIds: string[] = [];
  cardsLoaded: boolean = false;
  megaBigTotal: number = 0;

  showInvoices: boolean = false;
  allInvoices: AccountMove[] = [];
  contractInvoices: AccountMove[] = [];
  salInvoices: AccountMove[] = [];

  //totals
  totalContract: number = 0;
  totalInvoiced: number = 0;
  totalLeftToInvoice: number = 0;

  portfolioPassword: string = '';

  
  // Updated filter variables
  followers: Partner[] = [];
  selectedUser: Partner | null = null;
  startDate: string = '';
  endDate: string = '';
  isTableView: boolean = false;
  selectedStages: Set<number> = new Set();
  searchDealsInput: string = ''
  areas: { name: string; src: string; dst: string; }[]
  sortField: string = 'tracking_code'; // default sort field
  sortAscending: boolean = false;
  userId: number;
  currentFilters: DealFilters = { //default filters
    selectedUser: null,
    justRecents: true,
    selectedStages: [6, 2, 12, 4, 3, 10, 9, 1],
    area: "",
    sortField: "tracking_code",
    searchText: '',
    endDate: '',
    startDate: '',
    sortAscending: false,
    tableView: false
  };


  constructor(
    public restapi : RestapiService,
    private router: Router,
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private odooEM: OdooEntityManager
  ) {
    // Cache filter value
    const i = localStorage.getItem('deals-dashboard-filter') || '';
    this.filter = new BehaviorSubject(i);
    this.filter.subscribe((f) => {
      // Sync new deal area default with active filter
      localStorage.setItem('deals-dashboard-filter', f);
      if (this.newDeal) this.newDeal.area = f;
      this.refresh(); // Call refresh to update the results
    });
  }

  // Initialize all stages as selected in ngOnInit
  async ngOnInit() {
    this.areas = AREAS_CFG;
    this.newDeal = new Lead();
    this.newDeal.area = this.filter.value;
    this.newDeal.partner_id = new OdooRelationship();
    this.stages = await firstValueFrom(this.odooEM.search<CrmStage>(new CrmStage(), []));
    
    // Initialize selection of stages: do not show "Persa" stage and "Terminata" stage
    this.stages.forEach(stage => {
      if (stage.id !== 9 && stage.id !== 4) {
        this.selectedStages.add(stage.id);
      }
    }); 

    //fetch possible followers
    // Search for partners with @galimberti.eu emails
    const partners = await firstValueFrom(
      this.odooEM.search<Partner>(new Partner(), [
        ['email', 'ilike', '@galimberti.eu']
      ])
    );
    this.followers = partners;
    console.log('followers:', this.followers);

    // Get the current user's name
      const result: any = await this.odooEM.odoorpcService.getSessionInfo();
      console.log('session info:', result);
      this.userId = result.result.partner_id;

    this.stageDropIds = this.stages.map(s => s.id.toString());

    this.route.queryParams.subscribe((params) => {
      this.searchDealsInput = params.search || '';
      this.loadFilters(); // this automatically calls refresh
    });
  }

  async toggleInvoices() {
    this.showInvoices = !this.showInvoices;
    if (this.showInvoices) {
    //if invoices array is empty, fetch them
    if (!this.allInvoices.length) {
     // 3. Fetch all invoices
     this.allInvoices = await firstValueFrom(
      this.odooEM.search<AccountMove>(
        new AccountMove(),
        [['move_type', '=', 'out_invoice']],
        5000
      )
    );

    //set stage filter only on confermato and toggle table view + not only recents
    
    this.selectedStages.clear();
    this.selectedStages.add(3);
    this.isTableView = true;
    this.justRecents = false;
    this.refresh();
  }
  }
}

assignInvoiceData() {
  this.contractInvoices = this.allInvoices.filter(
    inv => inv.journal_id[0] === ODOO_IDS.contratti_id && inv.state !== 'cancel'
  );
  this.salInvoices = this.allInvoices.filter(
    inv => inv.journal_id[0] === ODOO_IDS.sal_id && inv.state === 'posted'
  );

  // Group by invoice_origin
  const contractsByCode = _.groupBy(this.contractInvoices, 'invoice_origin');
  const invoicesByCode = _.groupBy(this.salInvoices, 'invoice_origin');

  // For each lead, compute sums
  this.cards.forEach(lead => {
    const contractTotal = _.sumBy(contractsByCode[lead.tracking_code] || [], 'amount_untaxed');
    const invoicedTotal = _.sumBy(invoicesByCode[lead.tracking_code] || [], 'amount_untaxed');

    if (contractTotal > 0) {
      lead._contractsExist = true;
      lead._contractTotal = contractTotal
    }
    else {
      lead._contractsExist = false;
      lead._contractTotal = lead.expected_revenue;
    }

    lead._invoicedTotal = invoicedTotal;
    lead._leftToInvoice = contractTotal - invoicedTotal;

  });

  //calculate grand totals
  this.totalContract = _.sumBy(this.cards, '_contractTotal');
  this.totalInvoiced = _.sumBy(this.cards, '_invoicedTotal');
  this.totalLeftToInvoice = _.sumBy(this.cards, '_leftToInvoice');

}



  // Save current filters
  saveFilters() {
    // Update currentFilters with all current values
    this.currentFilters = {

      selectedUser: this.selectedUser,
      startDate: this.startDate,
      endDate: this.endDate,
      justRecents: this.justRecents,
      selectedStages: Array.from(this.selectedStages),
      area: this.filter.getValue(),
      sortField: this.sortField,
      sortAscending: this.sortAscending,
      tableView: this.isTableView
    };
  
    localStorage.setItem('leadFilters', JSON.stringify({
      filters: this.currentFilters,
      lastUpdated: new Date().toISOString()
    }));
    alert('Filtri salvati');
    console.log('filters saved:', this.currentFilters);
  }
  

// Load saved filters
loadFilters() {
  const saved = localStorage.getItem('leadFilters');
  if (saved) {
    const parsed = JSON.parse(saved);
    this.currentFilters = parsed.filters;
    
    // Apply saved filters to component state

    this.selectedUser = this.currentFilters.selectedUser;
    this.startDate = this.currentFilters.startDate;
    this.endDate = this.currentFilters.endDate;
    this.justRecents = this.currentFilters.justRecents;
    this.selectedStages = new Set(this.currentFilters.selectedStages);
    this.filter.next(this.currentFilters.area);
    this.sortField = this.currentFilters.sortField;
    this.sortAscending = this.currentFilters.sortAscending;
    this.isTableView = this.currentFilters.tableView;

    // Refresh the view with loaded filters

  }
}
  

  ngAfterViewInit(): void {
    // Debounce search input changes
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(debounceTime(400))
      .subscribe((e) => {
        this.searchDealsInput = (e as any).target.value;
        this.router.navigate([], { queryParams: { search: this.searchDealsInput } });
        this.refresh();
      });
  }

  async refresh() {
    this.cardsLoaded = false;
    console.log('refreshing... with selected user:', this.selectedUser);
    this.loading = true;
    const x = Math.random();
    this.lastSearch = x;

    let conditions: any[] = [['company_id', '=', 1]];

    // Apply area filter
    if (this.filter.getValue()) {
      conditions.push(['area', '=', this.filter.getValue()]);
    }

    // Apply stage filter - only if specific stages are selected
    if (this.selectedStages.size < this.stages.length) {
      conditions.push(['stage_id', 'in', Array.from(this.selectedStages)]);
    }
    // Apply date filters
    if (this.startDate) {
      conditions.push(['create_date', '>=', this.startDate]);
    }
    if (this.endDate) {
      conditions.push(['create_date', '<=', this.endDate]);
    }
  
    // Apply "Solo Recenti" filter (based on write_date)
    if (this.justRecents) {
      const d = new Date();
      d.setMonth(d.getMonth() - 3);
      conditions.push(['write_date', '>=', d.toISOString()]);
    console.log("FILTERING RECENTS >=", d.toISOString())
    }
  
    // Check if the search input matches a Sale Order number or tracking code
    const saleOrderRegex = /^[vVfF]?\d{4,}$/;
    if (this.searchDealsInput && saleOrderRegex.test(this.searchDealsInput)) {
      // Remove leading V/v/F/f if present to get the numeric part
      const numberPart = this.searchDealsInput.replace(/^[vVfF]/, '');
  
      // Search for Sale Orders matching the number part
      const saleOrderConditions = [['name', 'ilike', numberPart]];
      const saleOrders = await firstValueFrom(
        this.odooEM.search<SaleOrder>(new SaleOrder(), saleOrderConditions)
      );
  
      if (saleOrders.length > 0) {
        const saleOrderIds = saleOrders.map((so) => so.id);
        // Combine conditions to find leads associated with these sale orders or with matching tracking code
        conditions = [
          ...conditions,
          '|',
          ['order_ids', 'in', saleOrderIds],
          ['tracking_code', 'ilike', numberPart],
        ];
      } else {
        // If no sale orders are found, search for leads with matching tracking code
        conditions.push(['tracking_code', 'ilike', numberPart]);
      }
    } else if (this.searchDealsInput) {
      // Apply search input to all leads
      conditions.push('|', '|', '|');
      conditions.push(['partner_id.name', 'ilike', this.searchDealsInput]);
      conditions.push(['name', 'ilike', this.searchDealsInput]);
      conditions.push(['tracking_code', 'ilike', this.searchDealsInput]);
      conditions.push(['street', 'ilike', this.searchDealsInput]);


    }
  
    try {
      conditions.push(['active', 'in', [true, false]]);
    const sortDirection = this.sortAscending ? 'asc' : 'desc';
    const r = await firstValueFrom(
      this.odooEM.search<Lead>(new Lead(), conditions, 500, null, `${this.sortField} ${sortDirection}`)
    );
  
      if (this.lastSearch !== x) {
        return;
      }
      this.cards = r;

      // resolve mail message followers
      await firstValueFrom(this.odooEM.resolveArray(new MailFollower, this.cards, 'message_follower_ids'))

      //filter for user: selectedUser.name has to be in the message_follower_ids.values.display_name
      if (this.selectedUser) {
        this.cards = this.cards.filter(c => c.message_follower_ids.values.find(f => f.display_name === this.selectedUser.name))
      }

      //solve activities to show bells
      await firstValueFrom(this.odooEM.resolveArray(new MailActivity, this.cards, 'activity_ids'))
      await this.checkOverdueActivities()

      if (this.showInvoices){
      this.assignInvoiceData();}

      console.log('cards after user filter and activities:', this.cards);

      this.loading = false;
      this.cardsLoaded = true;


    } catch (error) {
      console.error('Error loading deals:', error);
    } finally {
      this.loading = false;
      this._cdr.detectChanges(); // Force change detection
    }
  }

  async checkOverdueActivities(){
    // if (card.activity_ids.values.some(activity => activity.state === 'overdue')

    for (let c of this.cards) {
      c._isOverdue = c.activity_ids.values?.some(activity => activity.state === 'overdue')
    }
  }

  onContact(c) {
    if (c == null)
      this.newDeal.partner_id = new OdooRelationship()
    else {
      this.newDeal.partner_id = new OdooRelationship(c.id, c.name)
    }
    // this.newDeal.partner_id = [c.id,''];
  }
  
  openPortfolio() {
    console.log('portfolioPassword in openPortfolio() is:', this.portfolioPassword);
    if (this.portfolioPassword == 'BetaGennaio') {
    // Using relative navigation
    this.router.navigate(['portfolio'], { relativeTo: this.route });
    }
    else {
      alert('Password errata');
    }
  }

  onCreate() {
    this.createDeal()
  }

    // Calculate the total for each stage and the big total
  async calculateTotals()  {
    this.stages.forEach(stage => {
      stage._total_revenue = 0;
    });

    for (let card of this.cards) {
      const stage = this.stages.find(s => s.id === card.stage_id.id);
      if (stage) {
        stage._total_revenue += card.expected_revenue;
      }
    }

    this.megaBigTotal = this.stages.reduce((acc, stage) => acc + stage._total_revenue, 0);
  
  }
  
  pickContact() {
    var w = window.open('/contact?mode=embedded','_blank','height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes' );
    
    var h = event => {
      var d = JSON.parse(event.data)
      this.newDeal.partner_id = new OdooRelationship(d.id,d.name)
      w.close()
    }
    window.addEventListener("message", h, false);
    w.onbeforeunload = () => {
      window.removeEventListener("message", h)
    }
  }

  async createDeal() {
    this.loading = true
    // todo move in abstarct model
    var x = {

              "area": this.newDeal.area, 
              "partner_id": this.newDeal.partner_id.id,
              "name":"",
              "description":"",
              "city":"",
              "street":"",
              "contact_name":  this.newDeal.partner_id.name,
              "type": "opportunity"
            }
    
    await this.odooEM.create<Lead>(new Lead(),x).subscribe(async (res) => {

      this.loading = false
      // Add the new deal to the cards array
      // this.cards.unshift(JSON.parse(JSON.stringify(res)))
      this.redirectDeal(res.id)
    })

  }

  redirectDeal(id) {
    if (!this.embedded)

      this.router.navigate(['leads/'+id], { queryParams: { search: this.searchDealsInput }});
    else {
      this.onSelect.emit(id)
    }
  }

  resetFilters() {
    this.searchDealsInput = '';
    this.selectedUser = null;
    this.startDate = '';
    this.endDate = '';
    this.justRecents = true;
    this.filter.next('');
    this.sortField = 'tracking_code';
    this.sortAscending = false;
    this.isTableView = false;
    
    // Reset selectedStages to default (all except 'Persa' and 'Terminata')
    this.selectedStages.clear();
    this.stages.forEach(stage => {
      if (stage.id !== 9 && stage.id !== 4) {
        this.selectedStages.add(stage.id);
      }
    });
  
    // Clear saved filters
    localStorage.removeItem('leadFilters');
    this.currentFilters = {
      searchText: '',
      selectedUser: null,
      startDate: '',
      endDate: '',
      justRecents: true,
      selectedStages: Array.from(this.selectedStages),
      area: '',
      sortField: 'tracking_code',
      sortAscending: false,
      tableView: false
    };
  }

  filterCards(stageId: number) {
    // Only show cards for selected stages
    if (!this.selectedStages.has(stageId)) {
      return [];
    }
    else{
    this.calculateTotals()
    return this.cards.filter((c) => c.stage_id.id === stageId);
    }
  
  }

  isStageSelected(stageId: number): boolean {
    return this.selectedStages.has(stageId);
  }

  myDeals() {
    //selected user gets the current user
    this.selectedUser = this.followers.find(f => f.id === this.userId);
    this.refresh();
  }

  // Helper method to get stage name
getStageName(lead:Lead ): string {
  //if archived, stage is "Persa"
  if (lead.active == false) {
    return 'Persa';
  }
  const stage = this.stages.find(s => s.id === lead.stage_id?.id );
  return stage ? stage.name : '';
}

updateStageFilter(stageId: number) {
  if (this.selectedStages.has(stageId)) {
    this.selectedStages.delete(stageId);
  } else {
    this.selectedStages.add(stageId);
  }
  
  // If no stages are selected, show all stages
  if (this.selectedStages.size === 0) {
    this.stages.forEach(stage => this.selectedStages.add(stage.id));
  }
  
  this.refresh();
}

  toggleRecents() {
    this.justRecents = !this.justRecents
    this.refresh()
  }

  toggleSortDirection() {
    this.sortAscending = !this.sortAscending;
    this.refresh();
  }  

  toggleView() {
    this.isTableView = !this.isTableView;
  }

  getAreaBadgeClass(area: string): string {
    switch (area) {
      case 'Tetti':
        return 'bg-success text-white';
      case 'Case':
        return 'bg-danger text-white';
      case 'Facciate e Decking':
        return 'bg-secondary text-white';
      case 'Aziendale':
        return 'bg-muted text-white'; // 'bg-muted' is typically light gray; using 'bg-light' for clarity
      default:
        return 'bg-warning text-white';
    }
  }

  /* here all methos to manage drag and drop for cstage changes */
  async validateStageMove(lead: Lead, targetStage: CrmStage): Promise<boolean> {
    

    if (targetStage.sequence >= 1 && targetStage.id !== 9) {
      console.log("CHECKING ", lead.name, lead.partner_id, lead.street, lead.city);
      if (!lead.name || !lead.partner_id.id || !lead.street || !lead.city) {
        alert("Non hai compilato tutti i campi. Compila la descrizione, il cliente e l'indirizzo");
        return false;
      }
    }

    if (targetStage.sequence > 2 && targetStage.id !== 9) {
      if (!lead.tag_ids?.ids?.length) {
        alert("Non hai compilato tutti i campi. Compila la modalità di fornitura");
        return false;
      }
       //if parner name is BANCO, block it
       if (lead.partner_id.name == "BANCO") {
        alert("Non puoi spostare la commessa in produzione se il cliente è BANCO");
      return false;
    }
  }
  
    return confirm("Sei sicuro di spostare il fascicolo in " + targetStage.name + "?");
  }

  async onCardDrop(event: CdkDragDrop<Lead[]>) {
    if (event.previousContainer === event.container) {
      // Reorder within same stage - we might want to implement order preservation later
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const lead = event.previousContainer.data[event.previousIndex];
      const targetStageId = parseInt(event.container.id);
      const targetStage = this.stages.find(s => s.id === targetStageId);
  
      if (!targetStage) {
        console.error('Target stage not found');
        return;
      }
  
      // Validate the move
      const isValid = await this.validateStageMove(lead, targetStage);
      if (!isValid) {
        return;
      }
  
      this.loading = true;
      try {
        // Update the lead's stage
        const updatedLead = { ...lead };
        updatedLead.stage_id.id = targetStage.id;
        
        await firstValueFrom(this.odooEM.update(lead, { stage_id: targetStage.id }));
        //if stage 9 (persa) run odoo function 
        
        // Move the item in the UI
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
  
        // Refresh to ensure everything is in sync
        await this.refresh();
      } catch (error) {
        console.error('Error updating lead stage:', error);
        alert('Si è verificato un errore durante lo spostamento del fascicolo');
      } finally {
        this.loading = false;
      }
    }
  }

}
