<!-- Link for existing task -->
<a *ngIf="existingTask"
   class="btn btn-sm bg-primary text-white me-2"
   [href]="'https://o3.galimberti.eu/web#id=' + existingTask.id + '&cids=1&menu_id=422&action=635&active_id=7&model=project.task&view_type=form'"
   target="_blank">
    <i class="fa fa-shopping-cart"></i>
    RDA {{existingTask.date_deadline | Date4Humans}}
</a>

<!-- Button for creating new task -->
<div class="dropdown me-2" *ngIf="!existingTask">
    <button class="btn btn-sm bg-muted text-white dropdown-toggle" 
            data-bs-auto-close="outside" 
            type="button" 
            data-bs-toggle="dropdown">
        <i class="fa fa-shopping-cart"></i>
        Crea RDA
    </button>
    
    <div class="dropdown-menu px-3 py-3">
        <div class="mb-3">Entro il:</div>
        <input class="form-control mb-3" 
               type="date" 
               [(ngModel)]="deadline">
        <div class="mb-3">
            <label class="form-label">Note aggiuntive</label>
            <textarea class="form-control" 
                      rows="3"
                      [(ngModel)]="description"></textarea>
        </div>
        <button class="btn btn-primary text-white w-100" 
                [disabled]="loading"
                (click)="createPurchaseTask()">
            {{loading ? 'Creazione...' : 'Conferma'}}
        </button>
    </div>
</div>