<app-navbar [loading]="loading" backroute="..">
    <div class="d-flex justify-content-between align-items-center w-100">
        <a class="navbar-brand">Gestione Pacchi</a>
        <div>
            <button class="btn btn-outline-primary me-2" (click)="startScanning()" [disabled]="scanning">
                <i class="fa-solid fa-barcode"></i>
            </button>
            <button class="btn btn-primary" (click)="startDestinationScanning()" [disabled]="!isSomeQuantSelected()">
                Sposta
            </button>
        </div>
    </div>
</app-navbar>

<!-- Scanners and Editor (unchanged) -->
<app-scanner *ngIf="scanning" format="EAN-13" (onCode)="onScanPackage($event)"></app-scanner>
<app-scanner *ngIf="scanningDestination" format="EAN-13" (onCode)="onScanDestination($event)"></app-scanner>
<app-product-quantity-editor *ngIf="editingQuant" [product]="editingQuant.product_id.value"
    [maxQuantity]="editingQuant.quantity" (onSave)="onQuantitySelected($event)" (onCancel)="editingQuant = null">
</app-product-quantity-editor>

<!-- Main content -->
<div class="container-fluid">
    @if (selectedPackages.length === 0) {
    <div class="alert alert-info m-3">
        Nessun pacco selezionato. Premi il barcode per aggiungere un pacco.
    </div>
    }
    @for (pkg of selectedPackages; track pkg.id) {
    <div class="card m-2">
        <!-- Package Header (unchanged) -->
        <div class="card-header py-2">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center gap-2">
                    <input class="form-check-input" type="checkbox" [checked]="isWholePackageSelected(pkg)"
                        (change)="selectWholePackage(pkg, $event.target.checked)">
                    <div>{{ pkg.name }}<br>
                        <small class="text-muted">{{ pkg.location_id.name }}</small>
                    </div>
                </div>
                <button class="btn btn-sm btn-outline-danger" (click)="removePackage(pkg)">
                    <i class="fa-solid fa-times"></i>
                </button>
            </div>
        </div>

        <table class="table table-sm m-0 table-borderless">
            <thead>
                <tr>
                    <th style="width: 40px"></th>
                    <th class="text-center">Quantità</th>
                    <th class="text-center">Libera</th>
                    <th class="text-center">Selezionato</th>
                </tr>
            </thead>
            <tbody>
                @for (quant of getQuantsForPackage(pkg); track quant.id) {
                <!-- Product Name Row -->
                <tr class="bg-light">
                    <td colspan="4" class="border-0 py-2">
                        <h6 class="mb-0">{{ quant.product_id.value.display_name }}</h6>
                    </td>
                </tr>
                <!-- Quantities uom -->
                <tr class="text-nowrap">
                    <td>
                        <input class="form-check-input" type="checkbox" [checked]="quant._selected_qty > 0"
                            (change)="onCheckQuant(quant)">
                    </td>
                    <td class="text-center">
                        {{ quant.quantity.toFixed(2) }} {{ quant.product_id.value.uom_id.name }}
                    </td>
                    <td class="text-center">
                        {{ quant.available_quantity.toFixed(2) }} {{ quant.product_id.value.uom_id.name }}
                    </td>
                    <td class="text-center" (click)="editQuantity(quant)" style="cursor: pointer"
                        [ngClass]="getSelectionStyle(quant)">
                        {{ quant._selected_qty > 0 ? quant._selected_qty.toFixed(2) : '-' }} {{
                        quant.product_id.value.uom_id.name }}
                    </td>
                </tr>
                <!-- Quantities packaging -->
                <tr class="text-nowrap text-muted " *ngIf="quant.product_id.value.packaging_ids?.values?.length > 0">
                    <td></td>
                    <td class="text-center">{{ getDescriptiveQuantity(quant, quant.quantity) }}</td>
                    <td class="text-center">{{ getDescriptiveQuantity(quant, quant.available_quantity) }} </td>
                    <td class="text-center"> {{ getDescriptiveQuantity(quant, quant._selected_qty) || '-' }} </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    }
</div>