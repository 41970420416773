<nav class="navbar navbar-expand bg-dark navbar-dark">
  <div class="container-fluid">
    <div class="collapse navbar-collapse px-0" id="navbarSupportedContent">
      <button *ngIf="backroute" class="btn btn-link text-primary" [routerLink]="backroute ? backroute : '..'" queryParamsHandling="preserve">
        <i class="fas fa-chevron-left me-1"></i>
      </button>

      <!-- MERRY CHRISTMAS
      @if (!isMobileView){
      <i class="fa-solid fa-hat-santa me-1 cursor-pointer" 
        (mousedown)="startPressTimer()" 
        (mouseup)="clearPressTimer()"
        (mouseleave)="clearPressTimer()"
        [class.pressing]="isPressing"
        [ngClass]="isPressing ? 'fa-lg text-primary' : 'fa-lg text-white'"
        [style.opacity]="isPressing ? (0.3 + (pressProgress * 0.7)) : 1">
      </i>
      } -->
      <ng-content></ng-content>

      <!-- Added d-flex and ms-auto to push these items right -->
      <div class="d-flex ms-auto align-items-center">
        @if (!isMobileView && !hideCalendar){
          <app-calendar-widget class="d-none d-md-block" ></app-calendar-widget>
          <a href="https://trello.com/b/W3EJtffy/910-odoo-manuale" target="_blank" class="btn btn-link d-none d-md-block"
          title="28/01/2025">
            <i class="fa-solid fa-lg fa-circle-question fa-lg text-light"></i>
            
          </a>
        }
      </div>
    </div>
  </div>
</nav>

<bar-loader [loading]="loading"></bar-loader>

<!-- Snow animation container       MERRY CHRISTMAS
<div *ngIf="showSnowAnimation" class="snowfall-container">
  <div *ngFor="let config of snowflakeConfigs" class="snowflake"
       [style.left.%]="config.left"
       [style.animationDelay.s]="config.delay"
       [style.fontSize.em]="config.size">
    <i class="fa-solid fa-snowflake"></i>
  </div>
</div>
-->
