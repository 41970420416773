<div class="vh-100 d-flex flex-column overflow-hidden bg-light">
  <!-- Top navbar -->
  <app-navbar [loading]="loading" backroute=".." *ngIf="contact" class="sticky-top">
    <a href="" class="navbar-brand">{{contact?.name}}</a>
    <div class="d-flex justify-content-end align-items-center w-100 gap-2">
      <!-- Badge should come first in order but stay right-aligned -->
      <span *ngIf="!contact.active" class="badge bg-primary">In archivio</span>

      <!-- Message toggle button - visible on mobile -->
      <div class="dropdown d-md-none" *ngIf="contact?.id">
        <button class="btn btn-link text-white" type="button" (click)="viewMessage = !viewMessage">
          <i class="fa-solid fa-lg fa-comment text-light" [class.text-primary]="viewMessage"></i>
        </button>
      </div>

      <!-- Menu dropdown -->
      <div class="dropdown">
        <button class="btn btn-link text-white" type="button" data-bs-toggle="dropdown">
          <i class="fa fa-bars"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li *ngIf="contact.active"><a class="dropdown-item" (click)="onDelete()">Download vcard</a></li>
          <li *ngIf="contact.active"><a class="dropdown-item" (click)="onDelete()">Archivia</a></li>
          <li *ngIf="!contact.active"><a class="dropdown-item" (click)="restore()">Ripristina</a></li>
        </ul>
      </div>
    </div>
  </app-navbar>

  <!-- Main content wrapper -->
  <div class="d-flex gap-3 p-3" style="height: calc(100vh - 56px);">

  <!-- Main content -->
  <div class="flex-grow-1 bg-white rounded shadow-sm d-flex flex-column" 
    [class.d-none]="isMobileView && viewMessage">
    
    <!-- Fixed header - explicit height -->
    <h4 class="p-3 border-bottom m-0">Informazioni contatto</h4>

       <!-- Fixed height tabs -->
    <div class="border-bottom bg-light">
      <ul class="nav nav-tabs border-0 px-2 pt-2">
          <li class="nav-item">
            <button class="nav-link rounded-top border" [class.active]="activeTab === 'informazioni'"
              (click)="setActiveTab('informazioni')">
              Anagrafica
            </button>
          </li>
          <li class="nav-item d-flex align-items-center">
            <button class="nav-link" [class.active]="activeTab === 'recapiti'" (click)="setActiveTab('recapiti')"
              type="button">
              Recapiti
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" [class.active]="activeTab === 'commesse'" (click)="setActiveTab('commesse')"
              type="button">
              Commesse
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" [class.active]="activeTab === 'vendite'" (click)="setActiveTab('vendite')"
              type="button">
              Vendite su lista
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" [class.active]="activeTab === 'acquisti'" (click)="setActiveTab('acquisti')"
              type="button">
              Acquisti
            </button>
          </li>
        </ul>
      </div>

    <!-- Scrollable content - calculate remaining height -->
    <div class="tab-content m-3 overflow-auto" style="height: calc(100% - 116px);">
        <!-- Anagrafica tab -->
        <div class="tab-pane fade" *ngIf="contact" [class.show]="activeTab === 'informazioni'"
          [class.active]="activeTab === 'informazioni'">

          <!-- Edit button -->
          <div class="d-flex justify-content-end mb-3">
            <button class="btn btn-outline-primary" (click)="editMode = !editMode">
              <i class="fa fa-pencil me-1"></i>
            </button>
          </div>
          <div class="anagrafica-form">
            <div class="form-group">
              <!-- Name -->
              <div class="mb-3">
                <label class="form-label text-muted small mb-1">Nome</label>
                <input *ngIf="editMode" name="name" [(ngModel)]="contact.name" (change)="update('name')"
                  class="form-control">
                <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.name}}</div>
              </div>

              <!-- VAT -->
              <div class="mb-3">
                <label class="form-label text-muted small mb-1">Partita IVA</label>
                <div class="input-group" *ngIf="editMode">
                  <input class="form-control" name="vat" [(ngModel)]="contact.vat" (change)="update('vat')">
                  <button class="btn btn-outline-secondary" type="button" *ngIf="contact.vat"
                    (click)="onVAT(contact.vat)">
                    <i class="fa fa-check me-1"></i>Verifica
                  </button>
                </div>
                <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.vat}}</div>
              </div>

              <!-- ARCA Code -->
              <div class="mb-3">
                <label class="form-label text-muted small mb-1">Codice ARCA</label>
                <input *ngIf="editMode" name="arca" [(ngModel)]="contact.ga_arca" (change)="update('ga_arca')"
                  class="form-control">
                <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.ga_arca}}</div>
              </div>
            </div>

            <div class="col-md-6">
              <!-- Address -->
              <div class="mb-3">
                <label class="form-label text-muted small mb-1">Indirizzo</label>
                <input *ngIf="editMode" #placesRef="ngx-places" ngx-google-places-autocomplete [options]="placesOptions"
                  class="form-control" [value]="contact.street" (onAddressChange)="handleAddressChange($event)"
                  placeholder="Indirizzo">
                <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.street}}</div>
              </div>

              <div class="row">
                <div class="col-8 mb-3">
                  <label class="form-label text-muted small mb-1">Città</label>
                  <input *ngIf="editMode" name="city" [(ngModel)]="contact.city" (change)="update('city')"
                    class="form-control" placeholder="Città">
                  <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.city}}</div>
                </div>
                <div class="col-4 mb-3">
                  <label class="form-label text-muted small mb-1">CAP</label>
                  <input *ngIf="editMode" name="zip" [(ngModel)]="contact.zip" (change)="update('zip')"
                    class="form-control" placeholder="CAP">
                  <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.zip}}</div>
                </div>
              </div>

              <!-- Country -->
              <div class="mb-3">
                <label class="form-label text-muted small mb-1">Nazione</label>
                <select *ngIf="editMode" class="form-control" name="country_id" [ngModel]="contact.country_id.id"
                  (ngModelChange)="updateCountry($event)">
                  <option [ngValue]="null">Seleziona una nazione</option>
                  <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                </select>
                <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.country_id?.name}}</div>
              </div>
            </div>

            <!-- Contact Info -->
            <div class="mb-3">
              <label class="form-label text-muted small mb-1">Telefono</label>
              <input *ngIf="editMode" name="phone" [(ngModel)]="contact.phone" (change)="update('phone')"
                class="form-control">
              <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.phone}}</div>
            </div>

            <div class="mb-3">
              <label class="form-label text-muted small mb-1">Email</label>
              <input *ngIf="editMode" name="email" [(ngModel)]="contact.email" (change)="update('email')"
                class="form-control">
              <div *ngIf="!editMode" class="fw-medium fs-6 ms-2">{{contact.email}}</div>
            </div>

            <!-- Credit Limit -->
            <div class="mb-3">
              <label class="form-label text-muted small mb-1">Fido</label>
              <div class="d-flex align-items-center">
                <span class="fw-medium fs-6">
                  <i class="fa-solid"
                    [ngClass]="{'fa-check text-success': contact.use_partner_credit_limit, 'fa-xmark text-danger': !contact.use_partner_credit_limit}">

                  </i>
                </span>
                <span class="ms-3 fw-medium fs-6" *ngIf="contact.credit_limit">
                  {{contact.credit_limit | currency:'EUR'}}
                </span>
              </div>
            </div>
          </div>



          <!-- Notes -->
          <div class="mb-3">
            <label class="form-label text-muted small mb-1">Note</label>
            <div *ngIf="editMode" #commentEditor class="form-control h-auto" [innerHTML]="contact.comment"
              contenteditable="true" (blur)="updateComment(commentEditor.innerHTML)"
              style="min-height: 100px; overflow-y: auto;">
            </div>
            <div *ngIf="!editMode" class="fw-medium fs-6 ms-2" style="min-height: 100px; overflow-y: auto;"
              [innerHTML]="contact.comment"></div>
          </div>
        </div>

        <!-- Recapiti (Details) tab content -->
        <div class="tab-pane fade" [class.show]="activeTab === 'recapiti'" [class.active]="activeTab === 'recapiti'"
          id="tabcontatti" role="tabpanel">
          <app-contact-picker *ngIf="contact" #picker (loading)="loading = $event" [contact]="contact"
            [emptyForFalse]="true"></app-contact-picker>
        </div>

        <!-- Commesse (Leads) tab content -->
        <div class="tab-pane fade" [class.show]="activeTab === 'commesse'" [class.active]="activeTab === 'commesse'"
          id="tabcommesse" role="tabpanel">
          <!-- Table to display leads -->
          <div class="table-responsive mt-3">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Fascicolo</th>
                  <th>Città</th>
                  <th>Descrizione</th>
                  <th>Stato</th>
                  <th>Valore contratto</th>
                  <th>Responsabile</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let lead of leads" (click)="redirectDeal(lead.id)" style="cursor: pointer;">
                  <td>{{ lead.tracking_code }}</td>
                  <td>{{ lead.city }}</td>
                  <td>{{ lead.name }}</td>
                  <td>{{ lead.stage_id.name }}</td>
                  <td>{{ lead.expected_revenue | currency:'EUR':'symbol':'1.2-2' }}</td>
                  <td>{{ lead.user_id.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!leads || leads.length === 0" class="alert alert-info mt-3">
            Nessuna commessa trovata per questo contatto.
          </div>
        </div>

        <!-- Vendite su lista tab content -->
        <div class="tab-pane fade" [class.show]="activeTab === 'vendite'" [class.active]="activeTab === 'vendite'"
          id="tabvendite" role="tabpanel">
          <!-- Table to display sales -->
          <div class="table-responsive mt-3">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Vendita</th>
                  <th>Descrizione</th>
                  <th>Stato</th>
                  <th>Importo</th>
                  <th>Responsabile</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sale of sales" (click)="redirectSale(sale.id)" style="cursor: pointer;">
                  <td>{{ sale.name }}</td>
                  <td>{{ sale.ga_title }}</td>
                  <td>{{ sale._delivery_state }}</td>
                  <td>{{ sale.amount_untaxed | currency:'EUR':'symbol':'1.2-2' }}</td>
                  <td>{{ sale.user_id.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!sales || sales.length === 0" class="alert alert-info mt-3">
            Nessuna vendita trovata per questo contatto.
          </div>
        </div>

        <!-- New Acquisti tab content -->
        <div class="tab-pane fade" [class.show]="activeTab === 'acquisti'" [class.active]="activeTab === 'acquisti'"
          id="tabacquisti" role="tabpanel">
          <div class="table-responsive mt-3">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Numero</th>
                  <th>Data</th>
                  <th>Descrizione</th>
                  <th>Stato</th>
                  <th>Importo</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let purchase of purchases" (click)="redirectPurchase(purchase.id)" style="cursor: pointer;">
                  <td>{{ purchase.name }}</td>
                  <td>{{ purchase.date | date:'shortDate' }}</td>
                  <td>{{ purchase.description }}</td>
                  <td>{{ purchase.state }}</td>
                  <td>{{ purchase.amount | currency:'EUR':'symbol':'1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!purchases || purchases.length === 0" class="alert alert-info mt-3">
            Nessun acquisto trovato per questo contatto.
          </div>
        </div>
      </div>
    </div>
    <!-- Message sidebar -->
    <div [class.d-none]="!viewMessage" [ngClass]="isMobileView ? 'message-sidebar-mobile' : 'message-sidebar'"
      class="bg-white rounded shadow-sm overflow-hidden" [style.width]="isMobileView ? '100%' : '40%'">
      <!-- Changed this line -->
      <h4 class="p-3 border-bottom m-0">Note e attività</h4>
      <div style="height: calc(100% - 60px);">
        <app-message-widget [id]="contact.id" [action]="844"></app-message-widget>
      </div>
    </div>
  </div>
</div>