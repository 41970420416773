import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import Decimal from 'decimal.js';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { MrpProduction } from '../models/mrp-production';
import { Product } from '../models/product.model';
import { ProductPackaging } from '../models/product.packaging.model';
import { StockMove } from '../models/stock-move';
import { ProductTemplateAttributeValue } from '../models/product.template.attribute.value.model';
import { get } from 'http';
import { StockPicking } from '../models/stock-picking';

@Component({
  selector: 'app-production-editor',
  templateUrl: './production-editor.component.html',
  styleUrls: ['./production-editor.component.scss']
})
export class ProductionEditorComponent implements OnInit {
  id: number;
  loading = false;
  production: MrpProduction;
  selectablePackagings: ProductPackaging[];
  componentPickings: StockPicking[] = []

  constructor(
    private odooEm: OdooEntityManager,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    await this.load();

    this.odooEm.messages.subscribe(msg => {
      if (msg == 'reload') {
        this.load()
      }
    })
  }

  async load(): Promise<void> {
    this.route.params.subscribe(async params => {
      this.id = params['production_id'];
      this.loading = true;

      try {
        const productions = await firstValueFrom(this.odooEm.search<MrpProduction>(new MrpProduction(),[['id', '=', this.id]]));

        if (productions.length === 0) {
          throw new Error("Production not found");
        }

        this.production = productions[0];
        await this.resolveProductionData();

        console.log("Production loaded:", this.production);
        //also set the production to unlocked if it's confirmed and not in progress
        if (this.production.is_locked) {
          await this.odooEm.call2(new MrpProduction().ODOO_MODEL, "action_toggle_is_locked", [[this.production.id]]);
          this.production.is_locked = false;
        }
      } catch (error) {
        console.error("Error loading production:", error);
        alert(error.message);
      } finally {
        this.loading = false;
      }
    });

  }

  async resolveProductionData(): Promise<void> {
    await firstValueFrom(this.odooEm.resolve(this.production.move_raw_ids));
    const moves = this.production.move_raw_ids.values;
    
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), moves, 'product_id'));
    const products = moves.map(m => m.product_id.value);

    await firstValueFrom(this.odooEm.resolveArray(new ProductPackaging(), products, 'packaging_ids'));

    this.initializeMovesPackaging(moves);

    await this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise();
    console.log("aaaaaaaaaProduction loaded:", this.production);

    //fetch input pickings: operation_type = 13 and origin = production.name and state not draft or cancel
    let pickings= []
    pickings = await firstValueFrom(this.odooEm.search(new StockPicking(), [['picking_type_id', '=', 13], ['origin', '=', this.production.name], ['state', 'not in', ['draft', 'cancel']]]));
    this.componentPickings = pickings;

    //solve moves of this.pickings
    await firstValueFrom(this.odooEm.resolveArray(new StockMove(), pickings, 'move_ids'));
    console.log("xxxpickings", this.componentPickings);

  }

  initializeMovesPackaging(moves: StockMove[]): void {
    moves.forEach(m => {
      if (m.product_id.value.packaging_ids.ids.length > 0) {
        const firstPackaging = m.product_id.value.packaging_ids.values[0];
        m.product_packaging_id = {
          id: firstPackaging.id,
          name: firstPackaging.name,
          value: firstPackaging
        };
        m._product_packaging_qty = m.product_uom_qty / firstPackaging.qty;
        m._checked = false;
      }
    });
  }

  async confirmProduction(production: MrpProduction): Promise<void> {
    if (production.state === 'draft') {
      await this.odooEm.call2(new MrpProduction().ODOO_MODEL, "action_confirm", [[production.id]]);
      await this.load();
    }
  }

  getStateBadge(): { title: string, class: string } {
    const badges = {
      draft: { title: "Bozza", class: "badge bg-dark" },
      confirmed: { title: "Confermata", class: "badge bg-primary" },
      progress: { title: "In corso", class: "badge bg-warning" },
      done: { title: "Completata", class: "badge bg-success" }
    };
    return badges[this.production.state] || { title: "Sconosciuto", class: "badge bg-secondary" };
  }

  async deleteProduction(production: MrpProduction): Promise<void> {
    this.loading = true;

    try {
      if (this.isProductionInProgress()) {
        throw new Error("Impossibile eliminare la produzione. Il materiale è già stato consumato. Contattare il responsabile di produzione");
      }

      if (confirm("Questa produzione sarà eliminata. Per crearne una nuova annullare e riconfermare l'ordine")) {
        await this.odooEm.call2(new MrpProduction().ODOO_MODEL, "action_cancel", [[production.id]]);
        await this.odooEm.delete(new MrpProduction(), [production.id]);
      }
    } catch (error) {
      console.error("Error deleting production:", error);
      alert(error.message);
    } finally {
      this.loading = false;
    }
  }

  isProductionInProgress(): boolean {
    return this.production.move_raw_ids.values.some(m => m.quantity_done > 0);
  }

  async deleteMove(move: StockMove): Promise<void> {
    this.loading = true;
    try {
      // if production is confirmed we just set the quantity to 0
      if (this.production.state != 'draft') {
        await this.updateFromQuantity(move, 0);}
      else{
      await this.odooEm.delete(new StockMove(), [move.id]);}
      await this.load();
    } catch (error) {
      console.error("Error deleting move:", error);
      alert("Failed to delete move");
    } finally {
      this.loading = false;
    }
  }

  async updateSelectablePackaging(move: StockMove): Promise<void> {
    await firstValueFrom(this.odooEm.resolve(move.product_id.value.packaging_ids));
    this.selectablePackagings = move.product_id.value.packaging_ids.values;
  }

  isMovePrepared(move: StockMove): boolean {
   //here we check if som components are already prepared in their pickings
    if (this.componentPickings.length == 0)
      return false
    //search through all pickings moves the one with the same product_id and sum the qty_done
    let qty_done = 0
    this.componentPickings.forEach(p => {
      p.move_ids.values.forEach(m => {
        if (m.product_id.id == move.product_id.id)
          qty_done = qty_done + m.quantity_done
      })
    })   
   return qty_done > 0
  }

  async updateFromQuantity(move: StockMove, value: number): Promise<void> {
    this.loading = true;
    try {
      const oldPackaging = { ...move.product_packaging_id };
      await firstValueFrom(this.odooEm.update<StockMove>(move, { product_uom_qty: Number(value) }));
      
      const freshMove = (await this.odooEm.search<StockMove>(new StockMove(), [['id', '=', move.id]]).toPromise())[0];
      move.product_packaging_id = oldPackaging;
      move.product_uom_qty = freshMove.product_uom_qty;
      move._product_packaging_qty = freshMove.product_uom_qty / oldPackaging.value?.qty;

      if (this.production.state === 'confirmed') {
        await this.odooEm.call2(new MrpProduction().ODOO_MODEL, "action_assign", [[this.production.id]]);
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      alert("Failed to update quantity");
    } finally {
      this.loading = false;
    }
  }

  async UpdateFromPackageQuantity(move: StockMove, value: number): Promise<void> {
    const quantitySet = value * move.product_packaging_id.value?.qty;
    await this.updateFromQuantity(move, quantitySet);
  }

  async updateMovePackage(move: StockMove, value: ProductPackaging): Promise<void> {
    this.loading = true;
    try {
      move.product_packaging_id = {
        id: value.id,
        name: value.name,
        value: value
      };
      move._product_packaging_qty = move.product_uom_qty / value.qty;
    } catch (error) {
      console.error("Error updating package:", error);
      alert("Failed to update package");
    } finally {
      this.loading = false;
    }
  }

getDescriptive(move:StockMove) {
    if (!move.product_id.value) 
      return


    if (move.product_uom_qty == 0 || !move.product_id.value.packaging_ids?.values)    // dont want to show 
      return 
    
    var ps = move.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = move.product_uom_qty
    
    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    var totale = new Decimal(q)

    ps.forEach((p, i) => {

      if (!p.sales || p.name.includes("netti")) 
        return

      if (totale.toNumber() <= 0)
        return 

      let quo;
      //if last
      if (i == ps.length - 1)
        quo = totale.div(p.qty)
      else
        quo = totale.divToInt(p.qty)
      totale = totale.minus(quo.mul(p.qty))
      
      if (quo.toNumber() > 0)
        d = d + "" + Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name + "\n"

    })
    
    return d
  }

  getIconsForLine(move:StockMove) {

    let is = []
    // STATE != draft SALE
    if (this.production.state != 'draft' && this.production.state !="cancel") {

      //PRODUCTS:  if it's not a service log arrivals, prenotations and deliveries
  
      if (move.product_id.value.detailed_type != 'service' && move.product_uom_qty > 0) {
     
        // don't check quantity arrived cause it's not a purchase

        // check quantity reserved
        let r = 0
        r = r + move.forecast_availability //sommo la quantità riservata
        r = r + move.quantity_done //se ho la quantità consegnata la sommo (è prenotata...)

        if (Number((move.product_uom_qty).toFixed(5)) <= Number((r).toFixed(5))) {
          is.push('fa fa-lock text-success')
        } else if (Number((move.product_uom_qty).toFixed(5)) > Number((r).toFixed(5))) {
          is.push('fa fa-lock  text-warning')
        } else {
          is.push('fa fa-lock text-muted')
        }
      

        // check ships
        let delivered = Number((move.quantity_done).toFixed(5))
        let to_deliver = Number((move.product_uom_qty-move.quantity_done).toFixed(5))
        let qty = Number((move.product_uom_qty).toFixed(5))


          if (delivered == 0)
            is.push('fa fa-truck-fast text-danger')
          else if (delivered > 0 && delivered < qty)
            is.push('fa fa-truck-fast text-warning')
          else if (delivered >= qty || to_deliver == 0)
            is.push('fa fa-truck-fast text-success')
        
      }
    }
    //ORDER STATE = DRAFT
    if (this.production.state == 'draft') {
      // free qty seems to round badly floats
      // line.product_uom_qty <= Number((line.virtual_available_at_date).toFixed(4))
      let free_qty_today = this.getFree(move.product_id.value)
      if (Number((move.product_uom_qty).toFixed(5)) <= Number((free_qty_today).toFixed(5)) || move.product_id.value.detailed_type == 'consu') {
        is.push('fa fa-warehouse text-success')
      }
      else if (move.product_uom_qty > free_qty_today) {
        is.push('fa fa-warehouse text-warning')
      } else {
        is.push('fa fa-warehouse text-danger')
      }

    }
    return is
  }

  getFree(p: Product) {
    let available = 0
    // if available - outgoing >0 return available - outgoing, else 0
    if (p.detailed_type === 'consu')
      available = 9999

    else if (p.detailed_type === 'product') {
      available = p.qty_available - p.outgoing_qty
      if (available < 0)
        available = 0
    }

    return available
  }

  
  getVariantAttribute(move:StockMove, name:string) {
    if (!move.product_id)
      return

    if (!move.product_id.value || !move.product_id.value.product_template_attribute_value_ids)
    return ""
    
    if (move.product_id.value.product_template_attribute_value_ids.values) {
      var f = move.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name.startsWith(name)
      })
    }

    return (f && f.length > 0) ? f[0] : null
  }
}